import { memo, useEffect, useState } from "react"
// import { useScale } from '@/hooks/editor/scale'

export default memo(function Coord({
    scale,
    parent,
}) {

    const [x, setX] = useState('0')
    const [y, setY] = useState('0')

    const onMousemove =e=>{
        const { offsetX, offsetY } = e

        const top = document.querySelector('#layout-editor').scrollTop
        const left = document.querySelector('#layout-editor').scrollLeft

        // setX((left + offsetX) / scale + Number(parent.x))
        // setY((top + offsetY) / scale + Number(parent.y))
        setX(((left + offsetX) / scale).toFixed(2))
        setY(((top + offsetY) / scale).toFixed(2))
    }

    useEffect(() =>{
        document.querySelector('#layout-editor') && document.querySelector('#layout-editor').addEventListener('mousemove', onMousemove)
        return () => {
            document.querySelector('#layout-editor') && document.querySelector('#layout-editor').removeEventListener('mousemove', onMousemove)
        }
    })

    return (
        <>
            <div className="grid-section p-a-xr-yb m-r-50 m-b-20 b-r-4 c-fff f-s-14 p-tb-5 p-lr-10" style={{ backgroundColor: 'rgba(0,0,0,.4)' }}>
                <span>X: {x}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                <span>Y: {y}</span>
            </div>
        </>
    )
})