import { useEffect, useReducer, useState, useRef, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Button, Form, Modal, message } from 'antd'
import { throttle, debounce } from 'lodash'
import { useTranslation } from 'react-i18next'
// import Structor from './structor'
import Tags from '@/components/tags/'
import Header from './header'
import Editor from './editor'
import Footer from './footer'
// import Attrs from './attrs'
import Tree from './tree'
import Equips from './equips'
import reducer from '@/reducers/editor'
import { History } from '@/utils/editor/history'
import {
  CANVAS_MODE,
  CHANGE_SELECTED_IDS,
  CHANGE_PARENT_LAYER,
  HIS_ENTITIES,
  HIS_ARROWS,
  HIS_TOP,
  HIS_BTM,
  CHANGE_SELECTED_LAYER,
  HIS_EQUIPS,
  CHANGE_SELECTED_EQUIP,
  CHANGE_SELECTED_EQUIP_IDS,
  CHANGE_SELECTED_ARROW,
  CHANGE_SELECTED_ARROW_IDS,
} from '@/reducers/editor'
import { useXML } from '@/hooks/editor/xml'
// import { useAttrs } from '@/hooks/editor/attrs'
import { useEnums } from '@/hooks/editor/enums'
import { getLatestLayout, getIDLayout } from '@/axios/axios.scene'
import { useStorage } from '@/hooks/editor/storage'
import { MODE_BROWSE, MODE_COPY, MODE_SELECT } from '../../../utils/constant'


const history = new History()

window.hhh = history

export default function SceneEditor() {
  const { t } = useTranslation()

  // const account = useSelector(state => state.account)
  const isRecoryed = useRef(false)
  const common = useSelector(state => state.common)
  // const location = useLocation()
  const params = useParams()
  // const [layoutId, setLayoutId] = useState('')
  // const [layoutState, setLayoutState] = useState('') // 1 - working, 2 - submitted, 3 - released
  const [readonly, setReadonly] = useState(false)
  const [zoom, setZoom] = useState(1)
  const [isEquip, setIsquip] = useState(false)
  const [isArrow, setIsarrow] = useState(false)

  const [state, dispatch] = useReducer(reducer, {
    isShowStructor: false, // 是否展示结构
    isShowTags: false, // 是否展示属性框
    isShowEquips: false, // 是否展示equip
    isShowChildren: false, // 是否展示子图层
    isShowGrid: true, // 是否展示刻度尺
    isTop: true, // 是否到底
    isBtm: true, //是否到底 
    mode: MODE_BROWSE, // draw select browse copy

    copyTarId: '',
    copyType:'',

    editParentLayer: {}, // 正在编辑的父级图层

    selectedEntity: {}, // 选中的图层
    selectedIds: [], // 选中的所有图层ID
    showLevels: [],

    selectedEquip: {}, // 选中的设备
    selectedEquipids: [], // 选中的所有设备ID

    selectedArrow: {}, // 选中的箭头
    selectedArrowids: [], // 选中的所有箭头ID

    filters: {
      minLevel: '', // 最小显示级别
      cate: '', // 场景类别
      name: '', // 标题过滤
    },


    entities: {},
    equipments: {},
    arrows: {},

    readonly: false,
    scale: 1, // 缩放倍数
  })
  const { onCreateLayoutXML, onObjToXml } = useXML(history, state.editParentLayer)
  const { setLayout, cache, removeLayout } = useStorage(history, state.editParentLayer)
  const { onEquips, onFactors } = useEnums(history)
  const [isLine, setIsLine] = useState(false)
  const [tree, setTree] = useState([])
  const [currentLayout, setCurrentLayout] = useState({})

  const onRecory = throttle(() => {
    !readonly && setReadonly(params.type === 'read')

    if (params.type === 'read') {
      removeLayout()
      onGetLayout()
      return false
    }

    if (!isRecoryed.current && cache) {
      isRecoryed.current = true
      Modal.confirm({
        title: t('common.notice'),
        content: t('scene.editor.local-rec'),
        onOk: () => {
          const { entities, equipments } = onObjToXml(cache)
          history.init(entities, equipments)
          dispatch({ type: CHANGE_PARENT_LAYER, data: history.root })
          dispatch({ type: CANVAS_MODE, data: MODE_BROWSE })
          history.select([])
        },
        onCancel: () => {
          removeLayout()
          onGetLayout()
        }
      })
    } else {
      onGetLayout()
    }
  }, 500)

  const onGetLayout = async () => {
    const { id, version, plant, design } = params
    const res = version === 'latest' ?
      await getLatestLayout(id) :
      await getIDLayout(id, design)

    // setLayoutId(res.id)
    // setLayoutState(res.state)
    setCurrentLayout(res)
    if (Number(res.state) === 2) {
      setReadonly(true)
    }
    const { entities, equipments, arrows } = onObjToXml(res.layout)
    if (Object.keys(entities).length === 0) {
      message.error('非法数据')
    }

    history.init(entities, equipments, arrows)
    onCallback(history)

    dispatch({ type: CHANGE_PARENT_LAYER, data: history.root })
    dispatch({ type: CANVAS_MODE, data: MODE_BROWSE })
    history.select([])

    onEquips()
    onFactors()
  }

  const onChangeLayer =(newEntity)=>{
    history.replace(newEntity, null, 'edit-tags')
	if (newEntity.id === 'root') {
      dispatch({ type: CHANGE_PARENT_LAYER, data: history.root })
    }
  }

  const onSaveSorage = debounce(() => {
    const dom = onCreateLayoutXML()
    if (!dom) {
      removeLayout()
    } else {
      setLayout()
    }
  }, 2000)

  const onCallback = (his) => {
    const entity = his.selectedEntity
    dispatch({ type: HIS_ENTITIES, data: his.entities })
    dispatch({ type: HIS_TOP, data: his.isTop })
    dispatch({ type: HIS_BTM, data: his.isBottom })
    dispatch({ type: HIS_EQUIPS, data: his.equipments })
    dispatch({ type: HIS_ARROWS, data: his.arrows })
    dispatch({ type: CHANGE_SELECTED_LAYER, data: entity })
    dispatch({ type: CHANGE_SELECTED_IDS, data: his._selectedIds })
    dispatch({ type: CHANGE_SELECTED_EQUIP, data: his.equipSelectedEntity })
    dispatch({ type: CHANGE_SELECTED_EQUIP_IDS, data: his._equipSelectedIds })
    dispatch({ type: CHANGE_SELECTED_ARROW, data: his.arrowSelectedEntity })
    dispatch({ type: CHANGE_SELECTED_ARROW_IDS, data: his._arrowSelectedIds })

    if (his._selectedIds.length > 0 || his._equipSelectedIds.length > 0 || his._arrowSelectedIds.length > 0) {
      dispatch({ type: CANVAS_MODE, data: MODE_SELECT })
    }

    setIsquip(his._equipSelectedIds.length > 0)
    setIsarrow(his._arrowSelectedIds.length > 0)
  }

  useEffect(() => {
    history
      .callback(onCallback)
      .storage(onSaveSorage)
    onRecory()
  }, [])

  useEffect(()=>{
    if (!history.root) return
    setIsLine(Number(state.selectedEntity.level) === 5 || Number(state.editParentLayer.level) === 5)
    // if (Number(state.selectedEntity.level) === 5 && Number(state.editParentLayer.level) !== 5) {
    //   dispatch({ type: CHANGE_SHOW_EQUIPS, data: true})
    // }
  }, [state.editParentLayer, state.selectedEntity])


  useEffect(() => {
    setZoom(1)
  }, [state.editParentLayer])

  useEffect(() => {
    if (!history.root) return
    if (state.mode === MODE_COPY) return false
    history.select([])
    dispatch({ type: CANVAS_MODE, data: MODE_BROWSE })
  }, [zoom])

  if (!history.root) return null

  return (
    <article className='' >
      <Header
        history={history}
        dispatch={dispatch}
        filters={state.filters}
        mode={state.mode}
        isShowGrid={state.isShowGrid}
        isShowStructor={state.isShowStructor}
        isShowTags={state.isShowTags}
        isShowChildren={state.isShowChildren}
        isShowEquips={state.isShowEquips}
        isTop={state.isTop}
        isBtm={state.isBtm}
        isLine={isLine}
        readonly={readonly}
        parent={state.editParentLayer}
        showLevels={state.showLevels}
        selectedEquipids={state.selectedEquipids}
        selectedArrowids={state.selectedArrowids}
        selectedIds={state.selectedIds}
        selectedEntity={state.selectedEntity}
        zoom={zoom}
        setZoom={setZoom}
        copyType={state.copyType}
        tree={tree}
        currentLayout={currentLayout}
        reload={onRecory}
      ></Header>
      <main className={`f-1 w-per-100 bg-color-fff p-r d-f o-x-h ${state.isShowStructor ? "o-y-h" : "o-y-h"}`} style={{ height: common.height - 170 }}>
    
        <Editor
          isShowGrid={state.isShowGrid}
          isEquip={isEquip}
          isArrow={isArrow}
          isShowChildren={state.isShowChildren}
          zoom={zoom}
          dispatch={dispatch}
          history={history}
          readonly={readonly}
          mode={state.mode}
          parent={state.editParentLayer}
          arrows={state.arrows}
          entities={state.entities}
          equipments={state.equipments}
          selectedIds={state.selectedIds}
          selectedEntity={state.selectedEntity}
          selectedEquipids={state.selectedEquipids}
          selectedEquip={state.selectedEquip}
          selectedArrow={state.selectedArrow}
          copyType={state.copyType}
          copyTarId={state.copyTarId}
          showLevels={state.showLevels}
        />
        {
          <Equips 
            isShow={state.isShowEquips}
            entities={state.entities}
            history={history}
            dispatch={dispatch}
            parent={state.selectedEntity}
            equipments={state.equipments}
            selectedEquipids={state.selectedEquipids}
          />
        }
        <Tags
          history={history}
          readonly={readonly}
          dispatch={dispatch}
          isShow={state.isShowTags}
          isEquip={isEquip}
          isArrow={isArrow}
          width={400}
          layer={isArrow ? state.selectedArrow : isEquip ? state.selectedEquip : state.selectedEntity}
          parent={state.editParentLayer}
          onChangeLayer={onChangeLayer}
        >
          <Form.Item wrapperCol={{ offset: 10, span: 14 }}>
                  <Button type="primary" className='w-per-100'>
                      {t('common.confirm')}
                  </Button>
          </Form.Item>
        </Tags>
        {
          state.isShowStructor ?
          <Tree
            filters={state.filters}
            show={state.isShowStructor}
            history={history}
            entities={state.entities}
            dispatch={dispatch}
            parent={state.editParentLayer}
            changeTree={v=>setTree(v)}
          /> :
          null
        }
    
      </main>
      <Footer 
        parent={state.editParentLayer}
        history={history}
        dispatch={dispatch}
        isShowStructor={state.isShowStructor}
        selectedEntity={state.selectedEntity}
      />
    </article>
  )
}
