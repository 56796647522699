import { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next"
import { copyText, getVersion } from '@/utils/util'
import { deleteLayout, getVersions } from '@/axios/axios.scene'

export function useVersion(sceneId, isAutoFetch) {

    const [versioning, setVersioning] = useState(false)
    const [versions, setVersions] = useState([])

    const onVersions = async () => {
        setVersioning(true)
        try {
            const res = await getVersions(sceneId);
            const newVersions = ((res || []).map(o => ({
                ...o,
                version: getVersion(o),
                order: Number(`${o.version_major}.${o.version_minor}`),
                // update_desc: '第一个版本'
            })))
            newVersions.sort((a,b)=>b.order - a.order)
            setVersions(newVersions)
        } catch (error) {
            
        } finally {
            setVersioning(false)
        }
    }

    useEffect(() => {
        if (!versioning && isAutoFetch) {
            onVersions()
        }
    }, [])

    return {
        versions,
        versioning,
        onVersions,
    }
}
