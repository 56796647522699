import { Divider, Form, InputNumber, Tag, message } from "antd";
import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParent } from '@/hooks/editor/parent'
import { useGeo } from '@/hooks/editor/geo'
import { formatterNumber } from '@/utils/util'

export default memo(function TagsBasic({
    isEquip,
    isArrow,
    isNew,
    readonly,
    layer,
    parent,
    history,
    onChange
}) {
    const { t } = useTranslation()
    const { isBrotherCross, isPointInPolygon, isContained } = useGeo(history, parent)

    const { isCurrentAbleShapeAttr } = useParent(parent, layer)
    const [ basic, setBasic ] = useState({})

    const onChangeBasic = (k, v) => {
        const newBasic = {
            ...basic,
            [k]: v
        }
        console.log(k, v)
        if (!onTopo(newBasic)) return false        
        
        setBasic(newBasic)
    }

    const onChangeLayer = () => {
        if (!onTopo(basic)) return false
        let flag = false 
        let attrs = ['height', 'width', 'x', 'y']
        for (let i=0; i<attrs.length ; i++) {
            if (String(basic[attrs[i]]) !== String(layer[attrs[i]])) {
                flag = true
                break
            }
        }
        if (!flag) return false
        onChange(basic)
    }

    const onTopo =(newBasic)=>{
        if (isNew) return true
        let isTopoOk = true
        if (isEquip) {
            if (parent.level && parent.id && !isPointInPolygon([newBasic.x, newBasic.y], parent)) {
                message.error(t("scene.editor.draw-t-no-equip"))
                isTopoOk = false
            }
        } else {
            if (parent.id !== layer.id && !isContained(parent, newBasic)) {
                message.error(t("scene.editor.draw-t-no-parent"))
                return false
            }
            
            if (isBrotherCross(newBasic, parent)) {
                message.error(t('scene.editor.draw-t-no-cross'))
                return false
            } 
        }

        if (!isTopoOk) {
            setBasic({ ...layer })
            return false
        } else {
            return true
        }
    }

    useEffect(() => {
        setBasic({
            ...layer
        })
    }, [layer])

    return (
        <>
            <Divider orientation='left' orientationMargin='10%'>
                <Tag color='blue'>{t('scene.editor.size')}</Tag>
            </Divider>
            {
                (isEquip || isArrow) ? null :
                    <>
                        {/* {/ 图层高 /} */}
                        <Form.Item label={t('scene.editor.height')}>
                            <InputNumber
                                disabled={!basic.id || !isCurrentAbleShapeAttr || readonly}
                                value={basic.width || ""}
                                min={1}
                                max={10000000}
                                step={1}
                                formatter={formatterNumber}
                                addonAfter={t('scene.editor.meter')}
                                onChange={e => onChangeBasic("width", e)}
                                onBlur={onChangeLayer}
                            ></InputNumber>
                        </Form.Item>
                        {/* {/ 图层宽 /} */}
                        <Form.Item label={t('scene.editor.width')}>
                            <InputNumber
                                disabled={!basic.id || !isCurrentAbleShapeAttr || readonly}
                                value={basic.height || ""}
                                min={1}
                                max={10000000}
                                step={1}
                                formatter={formatterNumber}
                                addonAfter={t('scene.editor.meter')}
                                onChange={e => onChangeBasic("height", e)}
                                onBlur={onChangeLayer}
                            ></InputNumber>
                        </Form.Item>
                    </>
            }
            {
                (layer.level > 1 || isArrow) ?
                    <>
                        <Form.Item label={t('scene.editor.lon')}>
                            <InputNumber
                                disabled={!basic.id || !isCurrentAbleShapeAttr || readonly}
                                value={basic.x || ""}
                                min={1}
                                max={10000000}
                                step={1}
                                formatter={formatterNumber}
                                addonAfter={t('scene.editor.meter')}
                                onChange={e => onChangeBasic("x", e)}
                                onBlur={onChangeLayer}
                            ></InputNumber>
                        </Form.Item>
                        <Form.Item label={t('scene.editor.lat')}>
                            <InputNumber
                                disabled={!basic.id || !isCurrentAbleShapeAttr || readonly}
                                value={basic.y || ""}
                                min={1}
                                max={10000000}
                                step={1}
                                formatter={formatterNumber}
                                addonAfter={t('scene.editor.meter')}
                                onChange={e => onChangeBasic("y", e)}
                                onBlur={onChangeLayer}
                            ></InputNumber>
                        </Form.Item>
                    </> : null
            }
        </>
    )
})
