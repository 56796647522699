import * as redux from 'redux'
import common from './common'
import account from './account'
import dicts from './dicts'
import scene from './scene'

const { createStore, combineReducers } = redux

export default createStore(combineReducers({
  common,
  account,
  dicts,
  scene,
}))