import { CHANGE_PARENT_LAYER, CANVAS_MODE, CHANGE_SHOW_EQUIPS } from '@/reducers/editor'
import { MODE_BROWSE } from '../../utils/constant'


export function useSelection(history, dispatch, isMoving) {

  const onPositionEquip = (id) => {
    const time = document.querySelector(`#${id}`) ? 0 : 500
    setTimeout(() => {
      console.log(id)
      const top = document.querySelector(`#${id}`).offsetTop
      document.querySelector('.equip-container').scrollTo({top, behavior: 'smooth'})
    }, time)
  }

  const onSelect=(e)=>{
    try {

      if (!e || !e.target || !e.target.dataset.id) {
        history.select([])
        history.equipSelect([])
        dispatch({ type: CANVAS_MODE, data: MODE_BROWSE })
        return false
      }
      const target = e.target
      const { id, type } = target.dataset
      const isEquip = type === 'equip'
      const isArrow = type === 'arrow'
      
      // 首先获取到缓冲中的选中ID
      let ids = isArrow ? 
        [...history._arrowSelectedIds] : 
        isEquip ? 
        [...history._equipSelectedIds ] : 
        [...history._selectedIds]

      // 当安住ctrl多选/取消，进行更新ids，否则覆盖ids
      if (e.ctrlKey) {
        const idx = ids.indexOf(id)
        if (idx > -1) {
          ids.splice(idx, 1)
        } else {
          ids.push(id)
          isEquip && onPositionEquip(id)
        }
      } else {
        ids = [id]
        isEquip && onPositionEquip(id)
      }
      isArrow ?
      history.arrowSelect(ids) :
      isEquip ? 
      history.equipSelect(ids) :
      history.select(ids)
      if (isEquip) {
        dispatch({ type: CHANGE_SHOW_EQUIPS, data: true })
      }
    } catch (error) {
      console.error(error)
      history.select([])
      history.equipSelect([])
      history.arrowSelect([])
      dispatch({ type: CANVAS_MODE, data: MODE_BROWSE })
    }
  }

  const onSelectParent =e=>{
    try {
      if (isMoving) {
        console.error('正在移动，无法操作')
        return false
      }
      if (!e || !e.target || !e.target.dataset.id) {
        return false
      }

      e.stopPropagation()
      const target = e.target
      const id = target.dataset.id
      let entity = history.entity(id) || history.equip(id)

      if (!entity) {
        return false
      }
      if (entity.hasOwnProperty('asset_no')) {
        entity = history.entity(entity.pid)
      }
      dispatch({ type: CHANGE_PARENT_LAYER, data: entity })
      dispatch({ type: CANVAS_MODE, data: MODE_BROWSE })
      history.select([])
    } catch (error) {
      console.error(error)
    }
  }

  const onGetScale =e=>{

  }

  return {
    onSelect,
    onSelectParent,
  }
}
