import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Table, Button, Modal, Form, Input, Spin, Select, Popconfirm } from 'antd'
import { SearchOutlined, PlusOutlined, ReloadOutlined } from '@ant-design/icons'
import { useSceneDicts } from '@/hooks/scen_dicts'
import { addSceneCate, delSceneCate, editSceneCate } from '@/axios/axios.scene'

const layout = {
  labelCol: {
    span: 7,
  },
  wrapperCol: {
    span: 17,
  },
}

const validateMessages = {
  // eslint-disable-next-line no-template-curly-in-string
  required: '${label} is required!',
  types: {
    // eslint-disable-next-line no-template-curly-in-string
    email: '${label} is not a valid email!',
    // eslint-disable-next-line no-template-curly-in-string
    number: '${label} is not a valid number!',
  },
  number: {
    // eslint-disable-next-line no-template-curly-in-string
    range: '${label} must be between ${min} and ${max}',
  },
}

export default function Type() {
  const { t } = useTranslation()
  const { scenes, sceneCates, sceneQueryCates, onGetAllSceneCates, isFetchingSceneCates, setIsFetchingSceneCates } = useSceneDicts()

  const [type, setType] = useState('')
  const [saving, setSaving] = useState(false)
  const [form] = Form.useForm()
  const [query ,setQuery] = useState('')
  const [level, setLevel] = useState('')

  const columns = [
    { title: t('scene.type.col-level'), dataIndex: 'scen_level' },
    { title: t('scene.type.col-key'), dataIndex: 'scen_name' },
    { title: t('scene.type.col-desc'), dataIndex: 'category' },
    {
      title: t('common.action'), key: 'action', render(record) {
        return <section className='f-s-c'>
          <Button type='link' onClick={() => onModal(record, true)}>{t('common.edit')}</Button>
          <Popconfirm
            title={t('common.notice')}
            description={t('common.notice-del')}
            onConfirm={()=>onDelete(record)}
          >
            <Button type='link' danger>{t('common.delete')}</Button>
          </Popconfirm>   
        </section>
      }
    }
  ]

  const onGetListByQuery =()=>{
    onGetAllSceneCates({ query })
  }

  const onReset = ()=>{
    setQuery('')
    setLevel('')
    onGetAllSceneCates()
  }

  const onChangeLevel = (name) => {

    const levelItem = scenes.find(o=>o.name===name)
    form.setFieldsValue({
      scen_level: levelItem.level
    })
  }

  const onModal = (record, isEdit) => {

    window.requestIdleCallback(() => {
      form.setFieldsValue(isEdit ? record : {
        level: '',
        category: '',
        name: '',
      })
    })
    setType(isEdit ? 'edit' : 'add')
  }

  const onClose = () => {
    setType('')
  }

  const onDelete =record=>{
    setIsFetchingSceneCates(true)
    delSceneCate(record.id)
      .finally(()=>{
        setIsFetchingSceneCates(false)
      })
      .then(()=>{
        onGetAllSceneCates()
      })
  }

  const onSave = async (arg) => {
    setSaving(true)
    try {
      // await editSceneType({
      //   id: arg.id,
      //   category: arg.category
      // })
      // const code = arg.code
      const isEdit = type === 'edit'
      // if (isEdit) delete arg.code
      const res = isEdit ? await editSceneCate(arg.id, arg.category) : await addSceneCate(arg)
      setSaving(false)
      onClose()
      onGetAllSceneCates()
    } catch (error) {
      setSaving(false)
    }

  }

  return (
    <article>
      <header className='f-s-c p-b-10'>
        <Form layout='inline'>
          <Form.Item label={t('scene.type.col-level')}>
            <Select value={level} onChange={setLevel} style={{width: 150}} allowClear>
              {
                scenes.map(o=>(
                  <Select.Option key={o.level} value={o.level}>{o.name}</Select.Option>
                ))
              }
            </Select>
          </Form.Item>
          <Form.Item label={t('common.keyword')}>
            <Input value={query} placeholder={t('common.keyword')} onChange={e=>setQuery(e.target.value)} />
          </Form.Item>
        </Form>
        <Button type='primary' ghost icon={<SearchOutlined />} className='m-r-20' onClick={onGetListByQuery}>{t("common.search")}</Button>
        <Button type='primary' icon={<PlusOutlined />} onClick={onModal}>{t("common.add")}</Button>
        <Button type='primary' danger icon={<ReloadOutlined />} className='m-l-20' onClick={onReset}>{t("common.reset")}</Button>
      </header>
      <Table
        rowKey='id'
        loading={isFetchingSceneCates}
        dataSource={(sceneQueryCates || sceneCates).filter(o=>!level || String(o.scen_level) === String(level))}
        pagination={false}
        columns={columns}
      ></Table>
      <Modal
        title={t("setting.plant.title")}
        width={590}
        open={!!type}
        footer={null}
        onCancel={onClose}
      >
        <Spin spinning={saving}>
          <Form {...layout} name="nest-messages" className='p-t-20' form={form} onFinish={onSave} validateMessages={validateMessages}>
            <Form.Item
              name={['id']}
              label={t('scene.type.col-level')}
              hidden
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              name={['scen_level']}
              label={t('scene.type.col-level')}
              disabled
            >
              <Input disabled />
            </Form.Item>

            <Form.Item
              name={['scen_name']}
              label={t('scene.type.col-key')}
              rules={[
                { required: true },
              ]}
            >
              <Select disabled={type !== 'add'} onChange={onChangeLevel}>
                {
                  scenes.map(o => (
                    <Select.Option key={o.id} value={o.name}>{o.name}</Select.Option>
                  ))
                }
              </Select>
            </Form.Item>

            <Form.Item
              name={['category']}
              label={t('scene.type.col-desc')}
              rules={[
                { required: true },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                ...layout.wrapperCol,
                offset: 7,
              }}
            >
              <Button type="primary" htmlType="submit" loading={saving} className='m-r-20'>
                {t("common.save")}
              </Button>
              {/* <Button danger ghost htmlType="reset" className='m-lr-15'>
                {t("common.reset")}
              </Button> */}
              <Button type='primary' danger onClick={onClose}>
                {t("common.cancel")}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </article>
  )
}