import { message } from "antd"
import { useTranslation } from 'react-i18next'
import { useTags } from '@/hooks/editor/tags'
import { useGeo } from './geo'
import { CANVAS_MODE } from "../../reducers/editor"
import { MODE_SELECT } from "../../utils/constant"

export function useCopy({
    history,
    dispatch,
    selectedEntity,
    parent,
    tarId,
    scale,
    copyType,
}) {
    const { t } = useTranslation()
    const { tags, onGetNewCell, onGetNewLayoutWS, onGetNewLayoutEquip, onGetNewEquip } = useTags(selectedEntity)
    const { isBrotherCross, isPointInBrother, isPointInPolygon, isContained } = useGeo(history, parent)

    const onGetLoc =e=>{
        const event = e.nativeEvent
        const loc = [event.offsetX, event.offsetY]
        return onLevelLoc(loc)
    }

    const onLevelLoc=loc=>{
        const pX = Number(parent.x)
        const pY = Number(parent.y)

        return [(loc[0] / scale) + pX, (loc[1] / scale) + pY]
    }

    const onNewLayers =(loc)=>{
        const targetLayer = history.entity(tarId)
        const disX = Number(loc[0]) - Number(targetLayer.x)
        const disY = Number(loc[1]) - Number(targetLayer.y)

        const entities = []
        const equips = []
        const equipIdMap = {}
        const oldIds = []
        const mapLayer = (layer, pid)=>{
            const level = Number(layer.level)
            oldIds.push(layer.id)
            if (level < 6) {
                const startLoc = [Number(layer.x) + disX, Number(layer.y) + disY]
                const endLoc = [Number(layer.width) + startLoc[0], Number(layer.height) + startLoc[1]]
                const newCell = onGetNewCell(startLoc, endLoc, pid)
                ;['name', 'code', 'scene_id', 'cate_id', 'width', 'height', 'fill', 'text_x', 'text_y', 'text_size'].forEach(k =>{
                    newCell[k] = layer[k]
                })
                if (copyType === 'move') {
                    newCell.key = layer.key
                }

                entities.push(newCell)
                if (level === 5) {
                    const equipCs = history.equipChildren(layer.id)
                    equipCs.forEach(equip =>{
                        const newLoc = [Number(equip.x) + disX, Number(equip.y) + disY]
                        const newEquip = onGetNewEquip(newLoc, newCell.id)
                        ;['scene_id', 'cate_id', 'asset_no', 'factor_id'].forEach(k =>{
                            newEquip[k] = equip[k]
                        })
                        if (copyType === 'move') {
                            newEquip.key = equip.key
                        }

                        equips.push(newEquip)
                        equipIdMap[equip.id] = newEquip.id
                    })
                }
            } else if(level === 6) {

                const newCell = onGetNewLayoutWS(pid, layer.ws)
                ;['desc', 'ws'].forEach(k =>{
                    newCell[k] = layer[k]
                })
                if (copyType === 'move') {
                    newCell.key = layer.key
                }

                entities.push(newCell)
            } else if (level === 7) {
                const newCell = onGetNewLayoutEquip(pid, layer.equip)
                newCell['equip'] = equipIdMap[layer.equip]
                if (copyType === 'move') {
                    newCell.key = layer.key
                }

                entities.push(newCell)
            }
            const children = history.children(layer.id)
            const newpid = entities[entities.length - 1].id
            children.forEach(lay =>{
                mapLayer(lay, newpid)
            })
        }
        mapLayer(targetLayer, selectedEntity.id)


        return {
            entities,
            equips,
            equipIdMap,
            oldIds,
        }
    }
    
    const onCopy =({ entities, equips })=>{
        history.replace([...entities, ...equips], true, '复制图层')
        history.select([selectedEntity.id])
        history.equipSelect([])
        dispatch({ type: CANVAS_MODE, data: MODE_SELECT })

    }

    const onMove =({ entities, equips, oldIds })=>{

        history.remove(oldIds)
        history.replace([...entities, ...equips], true)
        history.select([selectedEntity.id])
        history.equipSelect([])
        dispatch({ type: CANVAS_MODE, data: MODE_SELECT })
    }

    const onCopyEvent =(e)=>{

        const id = e.target.dataset.id

        if (!id.startsWith(selectedEntity.id)) {
            message.error(t('scene.editor.copy-err-in'))
            return false
        }

        const loc = onGetLoc(e)
        const targetLayer = history.entity(tarId)
        const endLoc = [ loc[0] + Number(targetLayer.width), loc[1] + Number(targetLayer.height) ]

        const newCell = onGetNewCell(loc, endLoc, selectedEntity.id)
        if (copyType === 'move') {
            newCell.key = targetLayer.key
        }
        
        if (!isContained(selectedEntity, newCell)) {
            message.error(t("scene.editor.copy-err-contain"))
            return false
        }
        
        if (isBrotherCross(newCell, selectedEntity)) {
            message.error(t('scene.editor.copy-err-cross'))
            return false
        } 
        // ['name', 'code', 'scene_id', 'cate_id', 'width', 'height', 'fill', 'text_x', 'text_y', 'text_size'].forEach(k =>{
        //     newCell[k] = targetLayer[k]
        // })
        // history.replace(newCell, '复制/移动图层')

        const res = onNewLayers(loc)

        copyType === 'move' ? onMove(res) : onCopy(res)
    }

    return {
        onCopy,
        onMove,
        onCopyEvent,
    }
}
