import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, Tooltip } from 'antd'
import Drawer from '@/components/layout/drawer'
import { CHANGE_SHOW_EQUIPS } from '@/reducers/editor'
// import { useEnums } from '@/hooks/editor/enums'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useState } from 'react';
import { debounce } from 'lodash';
import { useStation } from '@/hooks/station'
import { Tag, Image } from 'antd';
import { CANVAS_MODE } from '../../../reducers/editor'
import { useTagWS } from '@/hooks/editor/station'
import { MODE_SELECT } from '../../../utils/constant'

export default memo(function Equips({
    isShow,
    width,
    dispatch,
    history,
    parent,
    equipments,
    entities,
    selectedEquipids,
}) {
    const { t } = useTranslation()
    const dicts = useSelector(state=>state.dicts)
    const [points, setPoints] = useState([])
    const { stationMap } = useStation(true)
    const { onAllStations, onStationsByEquipId } = useTagWS(history, parent, null)

    const onComputed =debounce(()=>{
        if (!parent) return
        const id = parent.id
        const equiplist = []
        const { factors, equips } = dicts
        const allStations = onAllStations()
        // console.log('start:', equipments)
        for (let i in equipments) {
            const equip = equipments[i]
            
            if (!id || i.startsWith(id)) {
                const { asset_no, factor_id } = equip
                const factor = factors[factor_id]
                const equipInfo = equips[asset_no]
                const stations = onStationsByEquipId(equip.id)

                equiplist.push({
                    ...equip,
                    // ws: String(ws_ids).split(',').filter(Boolean).map(id=>stationMap.get(id)).filter(Boolean),
                    ws: stations.map(o=>stationMap.get(String(o.ws))).filter(Boolean),

                    factorName: factor?.name,
                    factorImg: factor?.icon,

                    equipName: equipInfo?.equip_name,
                    equipNo: equipInfo?.equip_no,
                    equipPM: equipInfo?.equip_mgmt_id,
                })
            }
        }
        setPoints(equiplist)
    }, 400)

    const onSelect = (point)=>{
        history.select([])
        history.equipSelect([point.id])
        dispatch({ type: CANVAS_MODE, data: MODE_SELECT })
    }
    
    useEffect(()=>{
        onComputed()
    }, [equipments, parent, entities, stationMap, dicts.factors, dicts.equips])

    return (
        <Drawer
            title={t('scene.editor.equip')}
            placement="left"
            closable={true}
            open={isShow}
            width={width || 300}
            className='equip-container'
            onClose={() => dispatch && dispatch({ type: CHANGE_SHOW_EQUIPS, data: false })}
        >
            <article className='p-5 p-tb-15'>
                {
                    // equips
                    points.map(point => (
                        <div
                            key={point.id}
                            className={`p-5 b-r-4  b-eee m-b-10 shadow-green-hover ease-400 ${ selectedEquipids.includes(point.id) ? 'bg-color-80CBC4 shadow-green' : 'bg-color-fff shadow-gray' }`}
                            id={`${point.id}`}
                            onClick={()=>onSelect(point)}
                        >
                            <section className='f-s-c f-s-14 p-tb-5'>
                                <span className='w-s-nowrap'>{t('setting.equip.c-e-name')}：</span>
                                <span>{point.equipName || "NA"}</span>
                            </section>
                            <section className='f-s-c f-s-14 p-tb-5'>
                                <span className='w-s-nowrap'>{t('setting.equip.c-code')}：</span>
                                <span>{point.equipNo  || "NA"}</span>
                            </section>
                            <section className='f-s-c f-s-14 p-tb-5'>
                                <span className='w-s-nowrap'>{t('setting.equip.c-pm')}：</span>
                                <span>{point.equipPM   || "NA"}</span>
                            </section>
                            <section className='f-s-c f-s-14 p-tb-5'>
                                <span className='w-s-nowrap'>{t('scene.editor.ws-type')}：</span>
                                <div>
                                    {
                                        (point.ws||[]).map(o => (
                                            <Tag key={o.type} color='blue'>{o.type}</Tag>
                                        ))
                                    }
                                </div>
                            </section>
                            {/* <section className='f-s-c f-s-14 p-tb-5'>
                                <span>{t('scene.editor.ws-desc')}：</span>
                                <span>{point.ws_desc}</span>
                            </section> */}
                            <section className='f-s-c f-s-14 p-tb-5'>
                                <span className='w-s-nowrap'>{t('scene.editor.fac')}：</span>
                                {
                                    !point.factor_id ? null :
                                    <Tooltip title={point.factorName}>
                                        <Image src={point.factorImg} height={25} />
                                    </Tooltip>
                                }
                            </section>
                        </div>
                    ))
                }
            </article>
        </Drawer>
    )
})