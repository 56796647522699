import { useEffect, useState } from "react"

export function useScale(parent, zoom) {
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)
    const [scale, setScale] = useState(1)

    const onGetScale =()=>{
        const pWidth = parent.width
        if (!pWidth) return
        const newScale = width / Number(pWidth)
        setScale(newScale * zoom)

        setHeight(Number(parent.height) * newScale)
        // setWidth(Number(window.innerWidth - 60) * newScale)
    }

    useEffect(()=>{
        setWidth(Number(window.innerWidth - 60))
    })

    useEffect(()=>{
        onGetScale()
    }, [parent, width, zoom])

    return {
        width: width,
        height,
        scale
    }
}
