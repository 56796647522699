
import { useEffect, useState,  } from 'react'
// import { cloneDeep } from 'lodash'
import { useTags } from '@/hooks/editor/tags'
// import Attrs from './editor/attrs'
// import Tags from './editor/tags'
import Tags from '@/components/tags/'
import { useSelector } from 'react-redux'
import { Button, Form, message } from 'antd'
import { useTranslation } from 'react-i18next'
import { useXML } from '@/hooks/editor/xml'
import { addLayout, saveLayout } from '@/axios/axios.scene'
import { useNavigate } from 'react-router-dom'

export default function NewLayout() {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const { onCreateLayoutXML, onCreateEquipXML } = useXML({}, {})
    const [layer, setLayer] = useState({})
    const common = useSelector(state=>state.common)
    const { tags } = useTags(null)
    const [saving, setSaving] = useState(false)
    const [plant, setPlant ] = useState('')

    const onGetNewId =()=>{
        setLayer({
            ...tags,
            ...layer,
            id: 'root',
            level: 1,
            scene_id: '',
            width: common.width - 60,
            height: common.height - 100,
        })
    }

    const onCancel = () => {
        if (Number(layer.level) === 1) {
            window.history.back()
        }
    }

    const onConfirm = async () => {
        if (Number(layer.level) === 1) {
            if (!plant) {
                message.error(t('scene.editor.init-err-plant'))
                return false
            }
            if (!layer.name) {
                message.error(t('scene.editor.init-err-name'))
                return false
            }
            if (!layer.scene_id) {
                message.error(t('scene.editor.init-err-scene'))
                return false
            }
//            if (layer.width < 100 || layer.height < 100) {
//                message.error(t('scene.editor.init-err-size'))
//                return false
            // }
            layer.key = 'root'
            try {
                setSaving(true)
                const dom = onCreateLayoutXML([layer])
                const equips = onCreateEquipXML()
                const xml = document.createElement('xml')
                const design = document.createElement('design')
                xml.appendChild(design)
                design.appendChild(dom)
                design.appendChild(equips)

                const layout = xml.innerHTML
                // return
                const res = await addLayout({ name: layer.name, plant })
                await saveLayout(res.id, {
                    update_scope: '',
                    update_desc: '',
                    layout
                })
                navigate(`/scene/editor/${plant}/edit/${res.id}/${res.design_id}/latest`)
            } catch (error) {
                console.error(error)
            } finally {
                setSaving(false)
            }
        }
    }

    useEffect(()=>{
        onGetNewId()
    }, []);

    return (
        <article className='bg-color-fff w-per-100 h-per-100 o-a p-r'>
            {/* <Attrs 
                isNew={true}
                show={true}
                layer={layer}
                parent={{}}
                onSetChangeTag={onSetChangeTag}
            /> */}
            <Tags 
                isNew={true}
                isShow={true}
                layer={layer}
                parent={{}}
                width={common.width * 0.5}
                onChangeLayer={setLayer}
                onPlant={setPlant}

            >
                <Form.Item wrapperCol={{ offset: 9, span: 15 }}>
                    <div className='f-s-c p-tb-20'>
                        <Button type="primary" className='m-r-20' loading={saving} onClick={onConfirm}>
                        {t('common.submit')}
                        </Button>
                        <Button type="primary" danger ghost onClick={onCancel}>
                            {t('common.cancel')}
                        </Button>
                    </div>
                </Form.Item>
            </Tags>
        </article>
    )
}
