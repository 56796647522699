import { isArray } from "lodash"
import { memo } from "react"

export default memo(function Marker({
    color,
    d,
    size,
}) {    
    return (
        // <span
        //     style={{ display: 'inline-block', width: size, height: size }}
        // >   
            <svg 
                t="1691892132353" 
                viewBox="0 0 1024 1024" 
                version="1.1" 
                xmlns="http://www.w3.org/2000/svg" 
                p-id="4895" 
                width={size}
                height={size}
                fill='currentColor'
            >
                {
                    (isArray(d) ? d : [d]).map(pd => (
                        <path 
                            style={{color: 'inherit'}}
                            d={pd} 
                            key={pd}
                        ></path>
                    ))
                }
            </svg>
        // </span>
    )
})