import { useState, useEffect, useRef } from 'react'
import { getEquipments } from '@/axios/axios.dict'
import { useDispatch, useSelector } from 'react-redux'
import { DICT_EQUIP } from '@/store/dicts'

export function useEquipment(isDisFetch, history) {
  const dispatch = useDispatch()
  const dicts = useSelector(state=>state.dicts)

  const [equipments, setEquipments] = useState([]);
  const [loadingEqup, setLoadingEquip] = useState(false)
  const equipRef = useRef('');
  const [query, setQuery] = useState('')
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(20)
  const [end, setEnd] = useState(false)

  const onGetAllEquips=function (pageNo) {
    setLoadingEquip(true);
    getEquipments({
        query: arguments.length > 1 ? arguments[1] : query,
        page: pageNo || 1,
        size,
    })
    .then(res=>{
        setEquipments(Number(pageNo) <= 1 ? res : [...equipments, ...res])
        setPage(pageNo || 1)
        setEnd(res.length < size)
        const data = {}
        res.forEach(o=>{
          data[o.asset_id] = o
        })
        dispatch({
          type: DICT_EQUIP,
          data: data
        })
      })
      .catch((err)=>{
        console.error(err)
        setEquipments([])
      })
      .finally(()=>{
        setLoadingEquip(false)
      })
  }

  const onEquipName =(record) =>{
    const equip = history.equip(record.equip)
    if (!equip) return ''
    const { equips } = dicts
    const equipInfo = equips[equip.asset_no]
    if (equipInfo) {
        return equipInfo.equip_name
    }
    return ''
  }


  useEffect(()=>{
    if (!isDisFetch && !equipRef.current) {
      equipRef.current = true;
      onGetAllEquips(1)
    }
  }, []);

  return {
    page,
    end,
    query,
    setQuery,
    equipments,
    loadingEqup,
    onGetAllEquips,
    onEquipName,
  }
}
