import axios from './axios'

export const getScenes =(params)=>{
  return axios({
    url: '/layout',
    method: 'get',
    params
  })
}

// 获取最新的layout设计
export const getLatestLayout =(layoutId)=>{
  return axios({
    url: `/layout/${layoutId}/design`,
    method: 'get',
  })
}

export const getVersions =(layoutId)=>{
  return axios({
    // url: `/plant/${plantCode}/layoutdesign/version`,
    url: `/layout/${layoutId}/version`,
    method: 'get'
  })
}

// 删除layout
export const deleteLayout =(layoutId)=>{
  return axios({
    // url: `/plant/${plantCode}/layoutdesign`,
    url: `/layout/${layoutId}`,
    method: 'delete'
  })
}

// 获取某个ID的layout设计
export const getIDLayout = (layoutId, designId)=>{
  return axios({
    // url: `/plant/${plantCode}/layoutdesign/${layoutDesignId}`,
    url: `/layout/${layoutId}/design/${designId}`,
    method: 'get'
  })
}

export const addLayout =(params)=>{
  return axios({
    // url: `/plant/${plantCode}/layoutdesign`,
    url: `/layout`,
    method: 'post',
    params,
  })
}

export const saveLayout =(layoutId, data)=>{
  return axios({
    // url: `/plant/${plantCode}/layoutdesign`,
    url: `/layout/${layoutId}/design`,
    method: 'put',
    data,
  })
}

export const getPowerUsers =(layoutId)=>{
  return axios({
    // url: `/plant/${plantCode}/layouteditor`,
    url: `/layout/${layoutId}/layouteditor`,
    method: 'get',
  })
}

export const deletePowerUser =(layoutId, id)=>{
  return axios({
    // url: `/plant/${plantCode}/layouteditor/${id}`,
    url: `/layout/${layoutId}/layouteditor/${id}`,
    method: 'delete'
  })
}

export const addPowerUser =(layoutId, data)=>{
  return axios({
    // url: `/plant/${plantCode}/layouteditor`,
    url: `/layout/${layoutId}/layouteditor`,
    method: 'post',
    data
  })
}

 export const submitLayout =(layoutId, designId, reviewers)=>{ 
  return axios({
    // url: `/plant/${plantCode}/layoutdesign/${layoutDesignId}/submit`,
    // url: `/layout/${layoutId}/design/${designId}/submit`,
    url: `/layout/${layoutId}/design/submit`,
    method: 'post',
    data: {
      reviewers: reviewers
    }
  })
}

export const uploadAttach =(layoutId, file)=>{
  return axios({
    // url: `/plant/${plantCode}/attachmentfile`,
    url: `/layout/${layoutId}/attachmentfile`,
    data: file
  })
}

export const addAttach =(layoutId, data)=>{
  return axios({
    // url: `/plant/${plantCode}/attachment`,
    url: `/layout/${layoutId}/attachment`,
    method: 'post',
    data
  })
}

export const getAttachs =(layoutId)=>{
  return axios({
    // url: `/plant/${plantCode}/attachment`,
    url: `/layout/${layoutId}/attachment`,
    method: 'get'
  })
}

export const deleteAttach =(layoutId, id)=>{
  return axios({
    // url: `/plant/${plantCode}/attachment/${id}`,
    url: `/layout/${layoutId}/attachment/${id}`,
    method: 'delete',
  })
}

export const toggleVisible =(layoutId, visible)=>{
  return axios({
    // url: `/plant/${plantCode}/layoutdesign/visible`,
    url: `/layout/${layoutId}/visible`,
    params: {
      visible
    },
    method: 'put'
  })
}

export const getSceneCates =(params)=>{
  return axios({
    url: `/scencategory`,
    method: 'get',
    params,
  })
}

export const addSceneCate =(data)=>{
  return axios({
    url: '/scencategory',
    method: 'post',
    data
  })
}

export const delSceneCate =id=>{
  return axios({
    url: `/scencategory/${id}`,
    method: 'delete',
  })
}

export const editSceneCate =(id, category)=>{
  return axios({
    url: `/scencategory/${id}`,
    method: 'put',
    data: {
      category
    }
  })
}

// export const exportLayout = (data) => {
//   return axios({
//     url: '/layout/export',
//     method: 'post',
//     data,
//     responseType: 'blob'
//   })
// }
export const exportLayout = (layoutId, designId, pageSize, data) => {
  return axios({
    url: `/layout/${layoutId}/design/${designId}/export?pageSize=${pageSize}`,
    method: 'post',
    data,
    responseType: 'blob'
  })
}

// 导出结构
export const exportTree = (layoutId, designId, data) => {
  return axios({
    url: `/layout/${layoutId}/design/${designId}/treeexport`,
    method: 'post',
    data,
    responseType: 'blob'
  })
}
