import { useEffect, useState } from "react"

export function useParent(parent, selectedEntity) {

    const [isCurrentRoot, setCurrentIsRoot] = useState(false) // 是否跟元素
    const [isCurrentAableShape, setCurrentAbleShape] = useState(false) // 能否修形
    const [isCurrentAbleAttr, setCurrentAbleAttr] = useState(false) // 能否改属性
    const [isCurrentAbleShapeAttr, setCurrentAbleShapeAttr] = useState(false) // 能否通过属性修形

    const onUpdatelock =()=>{
        if (!selectedEntity || !selectedEntity.id) {
            setCurrentAbleShape(false)
            setCurrentAbleAttr(false)
            setCurrentAbleShapeAttr(false)
            return false
        }
        setCurrentIsRoot(selectedEntity.id === 'root')
        setCurrentAbleAttr(selectedEntity?.id && selectedEntity.level>parent?.level)
        setCurrentAbleShape(
            !selectedEntity || !selectedEntity.id ? false :
            Number(selectedEntity.level) === 7 ?
            Number(parent.level) === 5 :
            (Number(selectedEntity.level) === Number(parent?.level) + 1)
        )
        // setCurrentAbleAttr(selectedEntity?.id)
        if (selectedEntity.id === 'root') {
            setCurrentAbleShapeAttr(true)
        } else {
            setCurrentAbleShapeAttr(selectedEntity.datatype === 'arrow' || (selectedEntity?.id && selectedEntity.level>parent?.level))
        }
    }

    useEffect(()=>{
        onUpdatelock()        
    }, [parent, selectedEntity])

    return {
        isCurrentRoot,
        isCurrentAableShape,
        isCurrentAbleAttr,
        isCurrentAbleShapeAttr,
    }
}
