import { ClearOutlined, SettingOutlined } from "@ant-design/icons"
import { Card, Divider, Form, Image, Modal, Row, Tag } from "antd"
import { memo, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useElement } from '@/hooks/element'
import { useSelector } from "react-redux"

import Factor from '@/components/dicts/factor'

export default memo(function TagFactor({
    factorId,
    plant,
    onChange,
}) {
    const { t } = useTranslation()
    const { categorys } = useElement()
    const dicts = useSelector(state=>state.dicts)

    const [name, setName] = useState('fsa')
    const [img, setImg] = useState('')
    const [visible, setVisible] = useState(false)

    const onSelect =(record)=>{
        onChange(record ? record.id : '')
        setVisible(false)
    }

    const onComputed =()=>{
        if (!factorId) {
            setName('')
            setImg('')
            return false
        }
        const { factors } = dicts
        const factor = factors[factorId]
        if (factor) {
            setName(factor.name)
            setImg(factor.icon)
        }
    }

    useEffect(()=>{
        onComputed()
    }, [factorId, dicts.factors])

    return (
        <>
            <Divider orientation='left' orientationMargin='10%'>
                <Tag color='blue'>{t('scene.editor.fac')}</Tag>
            </Divider>
            <Form.Item label={t('scene.editor.fac-lab')}>
                <Card 
                    title={name} 
                    extra={<div className='f-s-c'>
                        <ClearOutlined className='ease-300 c-p c-hover-app m-r-5' onClick={()=>onSelect()} />
                        <SettingOutlined className="ease-300 c-p c-hover-app"  onClick={()=>setVisible(true)} />
                    </div>} 
                    className='w-per-100'>
                    <Image
                        width={125}
                        src={img}
                    />
                </Card>
            </Form.Item>
            <Modal
                title={t('scene.editor.fac-lab')}
                open={visible}
                width={750}
                onCancel={()=>setVisible(false)}
                footer={null}
            >
                <Row>
                    <Factor 
                        modal={true}
                        span={24}
                        factorId
                        comPlant={plant}
                        categorys={categorys}
                        onChange={onSelect}
                    />
                </Row>
            </Modal>
        </>
    )
})
