import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ConfigProvider, Skeleton } from 'antd'
// import { useTranslation } from 'react-i18next'
import zhCN from 'antd/locale/zh_CN'
import enUS from 'antd/locale/en_US'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { USER_TOKEN } from '@/utils/storage'
import Routers from '@/route'
import Nav from '@/components/layout/nav'
import Header from '@/components/layout/header'
// import Menu from '@/components/layout/menu'
import Login from '@/views/login'

import useLang from '@/hooks/lang'
import { useLogin } from '@/hooks/login'
import { useSceneDicts } from '@/hooks/scen_dicts'
import { useStation } from '@/hooks/station'

import { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { COMMON_HEIGHT } from '@/store/common'

const isLogined = !!localStorage.getItem(USER_TOKEN)

function App() {
  // const { t } = useTranslation()
  const { isZh } = useLang()
  const account = useSelector(state => state.account)
  const state = useSelector(state => state)
  const dispatch = useDispatch()
  const location = useLocation()
  const { onRefresh, logining } = useLogin(true)
  const { onGetAllScenes, onGetAllSceneCates, onGetAllSceneCateChildren } = useSceneDicts()
  const { onGetAllstations } = useStation(true)
  // const [langConfig, setLangConfig] = useState('') 

  const cache = useRef(null)
  const onResize = () => {
    dispatch({
      type: COMMON_HEIGHT
    })
  }

  useEffect(() => {
    if (isLogined && !cache.current) {
      cache.current = true
      onRefresh()
      onGetAllScenes()
      onGetAllSceneCates()
      onGetAllSceneCateChildren()
      onGetAllstations()
      window.addEventListener('resize', onResize)
      window.$state = state
    }
    return ()=>{
      window.removeEventListener('resize', onResize)
    }
  }, [])

  // useEffect(() => {
  //   setLangConfig( isZh ? zhCN : enUS )
  // }, [isZh])

  return (
    <ConfigProvider locale={isZh ? zhCN : enUS} theme={{
      token: {
          colorPrimary: '#5D7598',
        },
      }}
    >
      {!isLogined ?
        <Login /> :
        <article className="app w-per-100 h-per-100 d-f f-column">
          <Header menus={Routers} />
          {/* <Menu menus={Routers} /> */}
          <div className='f-1 d-f p-r m-h-0'>
            <Nav />
            {!logining ?
              <main className={`app-content bg-color-f2f2f2 f-1 ${location.pathname.startsWith("/scene/editor") ? " " : "p-20"} h-per-100`}>
                <Routes>
                  {
                    Routers.map(o => (
                      o.parts.includes(account.role) ?
                        <Route key={o.path} className='w-per-100 h-per-100' path={o.path} element={o.element} /> :
                        null
                    ))
                  }
                  {
                    account.role ?
                    <Route path='*' element={<Navigate to="/scene/list" />} />:
                    null
                  }
                </Routes>
              </main> :
              <div className='h-per-100 w-per-100 p-20'>
                <div className='bg-color-f2f2f2 h-per-100 w-per-100 p-30 animated fadeInUp'>
                  <Skeleton active />
                </div>
              </div>
            }</div>
        </article>
      }</ConfigProvider>
  )
} export default App