import { useRef, useEffect, useState } from 'react'
// import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Table, AutoComplete, Button, Modal, Form, Input, Spin, Select, Tag, Image, Menu } from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { useRole } from '@/hooks/role'
import { getUsers, editUser, getAdminList } from '@/axios/axios.user'
import { debounce } from 'lodash'
import { USER_SUPER_ADMIN } from '@/utils/constant'

const layout = {
  labelCol: {
    span: 7,
  },
  wrapperCol: {
    span: 17,
  },
}

const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
}

let timeout = null
let currenValue = ''

export default function User() {
  const { t } = useTranslation()
  const userRef = useRef(false)
  // const account = useSelector(state => state.account)
  const { roles, roleMap } = useRole()
  const [users, setUsers] = useState([])
  const [loadingUser, setLoadingUser] = useState(false)

  const [type, setType] = useState('')
  const [saving, setSaving] = useState(false)
  const [form] = Form.useForm()
  const [isAdd, setIsAdd] = useState(false)
  const [options, setOptions] = useState([])
  const [user, setUser] = useState([])

  const columns = [
    { title: t('setting.user.label-id'), dataIndex: 'userId' },
    {
      title: t('setting.user.label-role'), render(record) {
        return <Tag color='blue'>{roleMap.get(record.role)}</Tag>
      }
    },
    { title: t('setting.user.label-name'), dataIndex: 'userName' },
    { title: t('common.email'), dataIndex: 'email' },
    { title: t('setting.user.label-depart'), dataIndex: 'deptName' },
    {
      title: t('common.avatar'), render(record) {
        return (
          record.avatar ?
            <Image src={record.avatar} height={30} /> : null
        )
      }
    },
    {
      title: t('common.action'), key: 'action', render(record) {
        return <section className='f-s-c'>
          {
            Number(record.role) === USER_SUPER_ADMIN ? null :
            <Button type='link' onClick={() => onModal(record, true)}>{t('common.edit')}</Button>
          }
        </section>
      }
    }
  ]

  const onAdd = () => {
    onModal('', false)
  }

  const onSelect = (v, o) => {
    form.setFieldsValue(o)
  }

  const onGetUsers = () => {
    setLoadingUser(true)
    getAdminList({
    })
      .then(res => {
        setUsers(res)
      })
      .finally(() => {
        setLoadingUser(false)
      })
  }

  const onUsers = (text, cb) => {
    try {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      currenValue = text;
      if (!text) return
      
      const fake = async () => {
        const res = await getUsers({ query: text })
        console.log(res)
        if (text !== currenValue) return
        cb(res.map(o => ({ ...o, label: o.userName, value: o.userId })))
      }

      timeout = setTimeout(fake, 300)

    } catch (error) {
      
    }
  }

  const onSearch = (text) => {
    onUsers(text, setOptions)
  }

  const onModal = (record, isEdit) => {
    setIsAdd(!isEdit)
    setUser('')
    window.requestIdleCallback(() => {
      form.setFieldsValue(isEdit ? record : {
        role: '',
        userId: '',
        userName: '',
      })
    })
    setType(isEdit ? 'edit' : 'add')
  }

  const onClose = () => {
    setType('')
  }

  const onSave = async (arg) => {
    console.log(arg)
    setSaving(true)
    try {
      // const code = arg.code
      const isEdit = type === 'edit'
      if (isEdit) delete arg.code
      await editUser({ ...arg, })
      setSaving(false)
      onClose()
      onGetUsers()
    } catch (error) {
      setSaving(false)
    }
  }

  useEffect(() => {
    if (!userRef.current) {
      onGetUsers()
      userRef.current = true
    }
  })

  return (
    <article>
      <header className='f-s-c p-b-10'>
        <Button type='primary' ghost icon={<SearchOutlined />} className='m-r-20' onClick={onGetUsers}>{t("common.search")}</Button>
        <Button type='primary' icon={<PlusOutlined />} className='m-l-10' onClick={onAdd}>{t("common.add")}</Button>

      </header>
      <Table
        rowKey='userId'
        loading={loadingUser}
        dataSource={users}
        columns={columns}
      ></Table>
      <Modal
        title={t("setting.user.title")}
        width={590}
        open={!!type}
        footer={null}
        onCancel={onClose}
      >
        <Spin spinning={saving}>
          <Form {...layout} name="nest-messages" className='p-t-20' form={form} onFinish={onSave} validateMessages={validateMessages}>

            <Form.Item
              name={['userId']}
              label={t('setting.user.label-id')}
              rules={[
                { required: true },
              ]}
              hidden
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={['userName']}
              label={t('setting.user.label-name')}
              rules={[
                { required: true },
              ]}
            >
              {
                isAdd ?
                <Select
                  className='w-per-80'
                  showSearch
                  value={user}
                  defaultActiveFirstOption={false}
                  suffixIcon={null}
                  filterOption={false}
                  onSearch={onSearch}
                  onChange={onSelect}
                  options={options}
                />:
                <Input disabled />
              }
            </Form.Item>
            <Form.Item
              name={['role']}
              label={t('setting.user.label-role')}
              rules={[
                { required: true },
              ]}
            >
              <Select
                allowClear
                placeholder={t('setting.user.label-role')}
                options={roles}></Select>
            </Form.Item>

            <Form.Item
              wrapperCol={{
                ...layout.wrapperCol,
                offset: 7,
              }}
            >
              <Button type="primary" htmlType="submit" loading={saving} className='m-r-15'>
                {t("common.save")}
              </Button>
              {/* <Button danger ghost htmlType="reset" className='m-lr-15'>
                {t("common.reset")}
              </Button> */}
              <Button type='primary' danger onClick={onClose}>
                {t("common.cancel")}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </article>
  )
}