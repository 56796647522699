import { debounce } from 'lodash'
import { getElements, getEquipments } from '@/axios/axios.dict'
import { useDispatch } from 'react-redux'
import { DICT_EQUIP, DICT_FACTORS } from '@/store/dicts'


export function useEnums(history) {
    const dispatch = useDispatch()

    const onEquips =debounce(()=>{
        const equips = history.equipments
        const assetIds = []
        for (let i in equips) {
            if (equips[i].asset_no) {
                assetIds.push(equips[i].asset_no)
            }
        }

        if(assetIds.length === 0) return false
        getEquipments({
            assetIds: assetIds.join(','),
        })
        .then(res=>{

            const data = {}
            res.forEach(o=>{
                data[o.asset_id] = o
            })
            dispatch({
                type: DICT_EQUIP,
                data: data
            })
        })
    }, 1000)

    const onFactors =debounce(()=>{
        const equips = history.equipments
        const ids = []
        for (let i in equips) {
            if (equips[i].factor_id) {
                ids.push(equips[i].factor_id)
            }
        }
        if (ids.length===0) return false
        getElements({
            ids: ids.join(','),
        })
        .then(res=>{

            const data = {}
            res.forEach(o=>{
                data[o.id] = o
            })
            dispatch({
                type: DICT_FACTORS,
                data
            }) 
        })
    }, 800)

    return {
        onEquips,
        onFactors,
    }
}
