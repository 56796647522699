import { Modal, Tag, Tree, message } from "antd"
import { memo, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSceneDicts } from "@/hooks/scen_dicts"
import { useSelector } from "react-redux"
import { useEquipment } from '@/hooks/equipment'
import { useTags } from '@/hooks/editor/tags'
import { CANVAS_MODE, CHANGE_TAR_ID } from "../../../reducers/editor"
import { getUuidByNum } from "@/utils/util"
import { useGeo } from '@/hooks/editor/geo'
import { MODE_COPY } from "../../../utils/constant"

export default memo(function SelectEntity({
    type,
    open,
    cancel,
    parent,
    selectedEntity,
    history,
    dispatch,
}) {
    const { t } = useTranslation()
    const common = useSelector(state => state.common)
    const { isContained, isBrotherCross } = useGeo(history)
    const { onEquipName } = useEquipment(true, history)

    const { sceneLevelMap, sceneCateChildrenNameMap, sceneCateNameMap } = useSceneDicts()
    
    const [tree, setTree] = useState([])
    const [keys, setKeys] = useState([])
    const [sKeys, setSkeys] = useState([])

    const onConfirm =()=>{

        const selectedKey = sKeys[0]

        if (!selectedKey || selectedKey.toLowerCase() === 'root') {
            message.error(t('scene.editor.release-err-copy'))
            return false
        }
        if (type === 'replace') {
            const targetEntity = history.entity(selectedKey)
            const disX = Number(selectedEntity.x) - Number(targetEntity.x)
            const disY = Number(selectedEntity.y) - Number(targetEntity.y)
            const newParent = {
                ...targetEntity,
                id: selectedEntity.pid +'-' + getUuidByNum(4),
                pid: selectedEntity.pid,
                children: [],
                x: Number(targetEntity.x) + disX,
                y: Number(targetEntity.y) + disY,
            }
            const selectedParent = history.entity(selectedEntity.pid)
            if (!isContained(selectedParent, newParent)) {
                message.error(t('scene.editor.rep-not-contain'))
                return
            }
            if (isBrotherCross(newParent, selectedParent, [selectedEntity.id])) {
                message.error(t('scene.editor.rep-not-cross'))
                return
            }

            const deletedIds = [selectedEntity.id, targetEntity.id]
            // const deletedEquipIds = []
            const list = [newParent]
            const equipIdMap = {}
            
            const deepEquips = (arr, pid) => {
                console.log(pid, arr)
                arr.forEach(o => {
                    deletedIds.push(o.id)
                    const equip = {
                        ...o,
                        x: Number(o.x) + disX,
                        y: Number(o.y) + disY,
                        pid,
                        id: pid +'-' + getUuidByNum(4),
                    }
                    list.push(equip)
                    equipIdMap[o.id] = equip.id
                })   
            }
            const deepEntitys = (arr, pid) => {
                arr.forEach(o => {
                    const entity = {
                        ...o,
                        children: [],
                        pid,
                        id: pid +'-' + getUuidByNum(4),
                    }
                    if ((o.level) < 6) {
                        entity.x = Number(o.x) + disX
                        entity.y = Number(o.y) + disY
                    } else {
                        deepEquips(history.equipChildren(o.id), entity.id)
                    }
                    if (Number(o.level) === 7) {
                        entity.equip = equipIdMap[o.equip]
                    }
                    list.push(entity)
                    if (o.children) {
                        deepEntitys(o.children, entity.id)
                    }
                })
            }
            deepEquips(history.equipChildren(targetEntity.id), newParent.id)
            deepEntitys(history.tree(targetEntity)[0].children, newParent.id)
            console.log(list)
            console.log(4545, history.tree(targetEntity))
            history.remove(deletedIds, true)
            history.replace(list, true,)
            
        } else {
            message.success(t('scene.editor.release-sus-copy'))
            dispatch({ type: CANVAS_MODE, data: MODE_COPY })
            dispatch({ type: CHANGE_TAR_ID, data: selectedKey })
        }
        cancel()
    }

    const onGetTree = ()=>{
        const newTree = JSON.parse(JSON.stringify(history.tree()))
        const minLevel = selectedEntity.level

        const arr = []
        const mapTree = o=>{

            if (type === 'replace') {
                if (o.level < minLevel) {
                    arr.push(o.id)
                }
            } else {

                if (o.level <= minLevel) {
                    arr.push(o.id)
                }
            }
            if (o.level < 6) {
                o.children.sort((a, b)=>a.scene_id - b.scene_id)
            }
            if (type === 'replace') {
                if (Number(o.level) !== Number(minLevel) || o.id === selectedEntity.id) {
                    o.disabled = true
                }
            } else {
                
                if (Number(o.level) !== Number(minLevel) + 1) {
                    o.disabled = true
                } 
            }
            if (o.level >= 7) {
                o.isLeaf = true
            }
            (o.children || []).forEach(c=>{
                mapTree(c)
            })
        }
        if (newTree && newTree[0]) {
            mapTree(newTree[0])
        }
        // console.log(newTree, tree)
        setKeys(arr)
        setTree(()=>newTree)

    }

    const onExpand =keys=>{
        setKeys(keys)
    }

    const onSelect =keys=>{
        setSkeys(keys)
    }

    useEffect(()=>{
        if (open) {
            onGetTree()
        } else {
            setSkeys([])
            setKeys([])
            setTree([])
        }
    }, [open, type])

    return (
        <Modal
            open={open}
            title={t(`common.${type}`)}
            onOk={onConfirm}
            onCancel={cancel}
        >
            <div className="o-a" style={{ maxHeight: common.height - 350 }}>
                <Tree 
                    fieldNames={{ key: 'id' }}
                    selectedKeys={sKeys}
                    expandedKeys={keys}
                    treeData={tree}
                    titleRender={nodeData=>{
                        const levelName = sceneLevelMap.get(String(nodeData.level))
                        let name = nodeData.name
                
                        if (Number(nodeData.level) === 7) {
                            name = onEquipName(nodeData)
                        } else if (Number(nodeData.level) === 6) {
                            name = nodeData.desc
                        }

                        const text = `${levelName}:${name}`

                        if (nodeData.id === selectedEntity.id) {
                            return <h3>
                                <span>{text}</span>
                                <Tag color="red">NOW</Tag>
                            </h3>
                        }

                        return text
                    }}
                    onExpand={onExpand}
                    onSelect={onSelect}
                />
            </div>
        </Modal>
    )
})
