export const USER_TOKEN = 'USER_TOKEN'

export const USER_NAME = 'USER_NAME'
export const USER_PASSWORD = 'USER_PASSWORD'
export const USER_REMEMBER = 'USER_REMEMBER'

export const IL8N_LANG = 'IL8N_LANG'

export const TREE_DOM = 'TREE_DOM'

export const TAG_BASIC_FILL = 'TAG_BASIC_FILL'
export const TAG_TEXT_FILL = 'TAG_TEXT_FILL'
export const TAG_TEXT_ATTR = 'TAG_TEXT_ATTR'