import { memo, useState, useMemo, useRef } from "react"
import { debounce } from "lodash"
import { UserSwitchOutlined } from "@ant-design/icons"
import { Modal, Table, Tooltip, message, Button, Spin, Select } from "antd"
import { useTranslation } from "react-i18next"
import { addPowerUser, getPowerUsers, deletePowerUser } from '@/axios/axios.scene'
import { getUsers } from '@/axios/axios.user'

export default memo(function({
    onReload,
    scene,
}) {
    const { t } = useTranslation()

    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [adding, setAdding] = useState(false)
    const [list, setList] = useState([])
    const fetchRef = useRef(0);
    const [fetching, setFetching] = useState(false)
    const [options, setOptions] = useState([])
    const [user, setUser] = useState('')

    const columns = [
        { title: 'user', dataIndex: 'user_name' },
        { title: 'id', dataIndex: 'id' },
        { title: 'action', render(text, record) {
            return  <Button type='link' danger onClick={()=>onDelete(record)}>{t('common.delete')}</Button>
        } },
    ]

    const onDebounceSearch = useMemo(()=>{
        const search = value=>{
            if (!value.trim()) return
            fetchRef.current += 1
            const fetchId = fetchRef.current
            setOptions([])
            setFetching(true)
            getUsers({query: value})
                .then(res=>{
                    if (fetchId !== fetchRef.current) return
                    setOptions(res.map(o=>({
                        ...o,
                        label: o.userName,
                        value: o.userId
                    })))
                })
                .finally(()=>{
                    setFetching(false)
         
                })
        }
        return debounce(search, 800)
    }, [])

    const onAddUser =()=>{
        setAdding(true)
        addPowerUser(scene.id, {
            plant: scene.plant,
            editor: user,
        })
        .then(res=>{
            onGetUsers()
        })
        .finally(()=>{
            setAdding(false)
        })
    }

    const onDelete=record=>{
        setLoading(true)
        deletePowerUser(scene.id, record.id)
            .then(()=>{
                onGetUsers()
            })
            .catch(()=>{
                setLoading(false)
            })
    }

    const onGetUsers=()=>{
        setLoading(true)
        getPowerUsers(scene.id)
            .then(res=>{
                setList(res)
            })
            .catch(()=>{
                setList([])
            })
            .finally(()=>{
                setLoading(false)
            })
    }

    const onClose =()=>{
        setVisible(false)
    }

    const onShowUsers =()=>{
        setVisible(true)
        onGetUsers()
    }

    return (
        <>
            <Tooltip title={t('common.power')}>
                <UserSwitchOutlined  
                    className="c-p c-hover-app c-666 ease-400  m-r-10" 
                    onClick={onShowUsers}/>
            </Tooltip>
            <Modal
                title={t('scene.layout.power-title')}
                open={visible}
                onCancel={onClose}
                onOk={onClose}
            >
                <header className="f-e-c p-b-15">
                    <Select
                        showSearch
                        className='w-200 m-r-10'
                        abelInValue
                        value={user}
                        filterOption={false}
                        onChange={setUser}
                        notFoundContent={fetching ? <Spin size="small" /> : null}
                        onSearch={onDebounceSearch}
                        options={options}
                    ></Select>
                    <Button 
                        loading={adding}
                        type='primary' 
                        className='w-80'
                        disabled={!user} 
                        onClick={onAddUser}>{t('common.add')}</Button>
                </header>
                <Table
                    size='small'
                    loading={loading}
                    dataSource={list}
                    columns={columns}
                    rowKey={'id'}
                ></Table>
            </Modal>
        </>
    )
})
