
import { memo, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default memo(function({menus}) {
  const { t } = useTranslation();
  const location = useLocation();
  const [list, setList] = useState([]);

  // const { pathname } = location;

  useEffect(() => {
    let newList = menus.filter(o=>o.showMenu);
    newList.sort((a,b)=>a.sort-b.sort);
    setList(newList);
  }, [menus]);

  return (
    <menu className='h-35 w-per-100'>
      <div className='h-per-100 f-s-c f-s-16'>
        {
          list.map(o=>(
            <Link
              key={o.path}
              to={o.path}
              className={`app-menu-link h-per-100 f-c p-lr-20 p-r ease-300 ${location.pathname.startsWith(o.parent)?"active":""}`}
            >
              {t('routes.'+o.path.slice(1).replaceAll('/', '-'))}
            </Link>
          ))
        }
      </div>
    </menu>
  )

});