import { Breadcrumb } from "antd"
import { memo, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { CANVAS_MODE, CHANGE_PARENT_LAYER } from "@/reducers/editor"
import { Link } from "react-router-dom"
import { useSceneDicts } from '@/hooks/scen_dicts'
import { MODE_BROWSE } from "../../../utils/constant"

export default memo(function Footer({
    parent,
    history,
    dispatch,
    isShowStructor,
    selectedEntity,
}) {
    const { t } = useTranslation()
    const [levels, setLevels] = useState([{ title: 'Home' }])
    const { sceneLevelMap } = useSceneDicts()

    const onTitle = (entity, isCurrent) => {
        if (!entity) return ''
        const content = `${sceneLevelMap.get(String(entity.level))}: ${entity.name}`
        if (isCurrent) {
            return <span className="c-333" >{content}</span>
        }
        return <a style={{  }}>{content}</a>
    }

    const onGetLevels = () => {
        if (!parent || !parent.id) return
        const items = []
        const mapParents = (id) => {

            if (id) {
                const entity = history.entity(id)
                if (!entity) return false
                items.unshift({
                    //   title: `${t('scene.editor.menu')}${entity.level}`,
                    title: onTitle(entity),
                    className: 'c-p',
                    onClick: () => {
                        dispatch({ type: CHANGE_PARENT_LAYER, data: entity })
                        history.select([])
                        dispatch({ type: CANVAS_MODE, data: MODE_BROWSE })
                    },
                })
                mapParents(entity.pid)
            }
        }
        mapParents(parent.pid)
        items.unshift({
            title: <Link to='/scene/list'>Home</Link>,
            className: 'c-p',
        })
        if (parent) {
            items.push({
                title: onTitle(history.entity(parent.id), true),
            })
        }
        setLevels(items)
    }

    useEffect(() => {
        onGetLevels()
    }, [parent, selectedEntity])

    if (isShowStructor) return null

    return (
        <footer
            className="w-per-100 h-40 f-s-c shadow-gray  p-lr-30 o-h p-a-xl-yb bg-color-F9F9F9 b- b-no-lr b-no-b"
        >
            <div className="w-per-100 h-per-100 o-a">
                <Breadcrumb items={levels} />
            </div>

        </footer>
    )
})
