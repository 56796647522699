import { Button, Divider, Form, Input, Modal, Table, Tag, Tooltip, message } from "antd"
import { memo, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useStation } from '@/hooks/station'
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons"
import Station from '@/components/dicts/station'
import { useTagWS } from '@/hooks/editor/station'
import { useTags } from '@/hooks/editor/tags'
import { cloneDeep } from "lodash"

export default memo(function TagWS({
    history,
    parent,
    layer,
    // wsIds,
    wsDesc,
    onChange,
    onDesc
}) {
    const { t } = useTranslation()
    const [ visible, setVisible ] = useState(false)
    const { stationMap } = useStation(true)
    // const [ tags, setTags ] = useState([])
    // const [ desc, setDesc ] = useState('')
    const [entity, setEntity] = useState({}) 
    const { onGetNewLayoutWS, onGetNewLayoutEquip } = useTags(parent)
    const { stations, onGetStationsByEquip, onAllStations } = useTagWS(history, parent, entity)
    const [wss, setWss] = useState([])

    const columns = [
        { title: t('scene.editor.ws-type'), dataIndex: 'ws', width: 100, render(text) {

            if (stationMap.has(String(text))) {
                return stationMap.get(String(text)).type
            }
            return 'NA'
        }},
        { title: t('scene.editor.ws-desc'), dataIndex: 'desc', render(text, record, index) {
            return <Input value={text} onChange={e=>onChangeDesc(e, record, index)} onBlur={()=>onSyncDesc(record)}></Input>
        }},
        { title: t('common.action'), dataIndex: 'action', width: 60, render(text, record){
            return (
                <Tooltip title={t('common.delete')}>
                    <Button
                        type='link'
                        danger 
                        icon={<DeleteOutlined />}
                        onClick={()=>onDelete(record)}></Button>
                </Tooltip>
            )
        }}
    ]

    const onSyncDesc =record=>{
        const entity = history.entity(record.id)
        history.replace({
            ...entity,
            desc: record.desc
        }, true, '修改工位备注描述')
    }

    const onChangeDesc =(e, record, index)=>{
        // record.desc = e.target.value
        const newWss = cloneDeep(wss)
        newWss[index].desc = e.target.value
        setWss(newWss)
    }
    const onDelete =record=>{
        const quips = history.children(record.id)
        const currentEquipLayout = quips.find(o => o.equip === layer.id)
        if (currentEquipLayout) {
            history.remove([currentEquipLayout.id, record.id])
            setTimeout(()=>{
                onGetStationsByEquip()
            }, 0)
        }
    }



    // const onGetTags =()=>{
    //     const newTags = []
    //     wsIds.split(',').forEach(id=>{
    //         if (stationMap.has(id)) {
    //             const station = stationMap.get(id)
    //             newTags.push(station)
    //         }
    //     })
    //     setTags(newTags)
    // }

    // const onClose =(idx)=>{
    //     let newTags = [...tags]
    //     newTags.splice(idx, 1)
    //     onChange(newTags.map(o=>o.id).join(','))
    // }

    const onSelect =(record)=>{
        // const ids = wsIds.split(',')
        // if (ids.includes(String(record.id))) {
        //     message.error(t('scene.editor.ws-repeat'))
        //     return false 
        // }
        // ids.push(String(record.id))
        // onChange(ids.filter(Boolean).join(','))
        // setVisible(false)
        let ws = stations.find(o=>o.ws === record.id)
        if (ws) {
            return false
        }
        const allStations = onAllStations()
        ws = allStations.find(o=>o.ws === record.id)

        if (!ws) {
            ws = onGetNewLayoutWS(entity.pid, record.id)
        }

        const newLayoutEquip = onGetNewLayoutEquip(ws.id, entity.id)

        history.replace([ws, newLayoutEquip], true, '新增layout-(ws+equip)')
        setTimeout(()=>{
            onGetStationsByEquip()
            setEntity({
                ...layer
            })
        }, 0)
        setVisible(false)
    }
    

    // useEffect(()=>{
    //     onGetTags()
    // }, [wsIds, stationMap])

    // useEffect(()=>{
    //     setDesc(wsDesc)
    // }, [wsDesc])

    useEffect(()=>{
        setEntity({
            ...layer
        })
    }, [layer])

    useEffect(()=>{
        const newWss = cloneDeep(stations).map(o=>{
            return {
                ...o,
                children: null
            }
        })
        setWss(newWss)

    }, [stations])

    return (
        <>
            <Divider orientation='left' orientationMargin='10%'>
                <Tag color='blue'>{t('scene.editor.ws')}</Tag>
            </Divider>
            {/* <Form.Item label={t('scene.editor.ws-type')}>                
                <div className='f-s-c f-w-w '>
                    {
                        tags.map((o, idx)=>(
                            <Tag
                                key={o.id} 
                                color='green'
                                closable
                                onClose={(e) => onClose(idx)} 
                            >{o.type}</Tag>
                        ))
                    }
                    <Tag onClick={()=>setVisible(true)} color='red' className={'c-p'}>
                        <PlusOutlined />{t("scene.editor.ws-new")}
                    </Tag>
                </div>
            </Form.Item>
            <Form.Item label={t('scene.editor.ws-desc')}>
                <Input.TextArea 
                    value={desc} 
                    onChange={e=>setDesc(e.target.value)}
                    onBlur={()=>onDesc(desc)}
                ></Input.TextArea>
            </Form.Item> */}

            <header className="f-e-c">
                <Button size='small' type='primary' onClick={()=>setVisible(true)}>{t('common.add')}</Button>
            </header>

            <Table
                size='small'
                pagination={false}
                columns={columns}
                dataSource={wss}
            ></Table>

            <Modal
                title={t('scene.editor.ws-title')}
                open={visible}
                onCancel={()=>setVisible(false)}
            >
                <Station 
                    modal={true}
                    onSelect={onSelect}
                />
            </Modal>
        </>
    )
})
