import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Table, Button, Modal, Form, Input, Spin } from 'antd'
import { SearchOutlined, PlusOutlined } from '@ant-design/icons'
import { useStation } from '@/hooks/station'
import { addStation, deleteStation, editStation } from '@/axios/axios.station'

const layout = {
  labelCol: {
    span: 7,
  },
  wrapperCol: {
    span: 17,
  },
}

const validateMessages = {
  // eslint-disable-next-line no-template-curly-in-string
  required: '${label} is required!',
}

export default memo(function Station({
    modal,
    onSelect,
}) {
  const { t } = useTranslation()
  const { stations, onGetAllstations, loadingStation } = useStation()
  const [type, setType] = useState('')
  const [saving, setSaving] = useState(false)
  const [form] = Form.useForm()

  const columns = [
    { title: t("setting.station.no"), dataIndex: 'no' },
    { title: t("setting.station.type"), dataIndex: 'type' },
    { title: t("setting.station.comment"), dataIndex: 'comment' },

    { title: t('common.action'), key: 'action', render(record) {
      return <section className='f-s-c'>
          <Button type='link' onClick={() => onModal(record, true)}>{t('common.edit')}</Button>

        {
            !modal ? null :
            <Button type='link' onClick={()=>onSelect(record)}>{t('common.select')}</Button>
        }
        <Button type='link' danger onClick={()=>onDelete(record)}>{t('common.delete')}</Button>
      </section>
    }}
  ]

  const onDelete =(record)=>{
    Modal.confirm({
      title: t('common.notice'),
      content: t('common.notice-del'),
      onOk: ()=>{
        return new Promise(async (resolve, reject)=>{
          try {
            await deleteStation(record.id)
            onGetAllstations()
            resolve()
          } catch (error) {
            reject()
          }
        })
      }
    })
  }

  const onModal =(record, isEdit)=>{
    window.requestIdleCallback(()=>{
      form.setFieldsValue(isEdit ? record : { 
        no: '',
        type: '',
        comment: ''
      })
    })
    setType(isEdit ? 'edit' : 'add')
  }

  const onClose =()=>{
    setType('')
  }

  const onSave =async (arg)=>{
    setSaving(true)
    try {
      console.log(arg)
      const code = arg.id
      const isEdit = type === 'edit'
      if (isEdit) delete arg.id
      isEdit ? await editStation(code, arg) : await addStation(arg)
      setSaving(false)
      onClose()
      onGetAllstations()
    } catch (error) {
      setSaving(false) 
    }
    
  }
  
  return (
    <article>
      <header className='f-s-c p-b-10'>
        <Button type='primary' ghost icon={<SearchOutlined />} className='m-r-20' onClick={onGetAllstations}>{t("common.search")}</Button>
        <Button type='primary'  icon={<PlusOutlined />} onClick={onModal}>{t("common.add")}</Button>
      </header>
      <Table
        rowKey='id'
        loading={loadingStation}
        dataSource={stations}
        columns={columns}
      ></Table>
      <Modal
        title={t("setting.station.title")}
        width={590}
        open={!!type}
        footer={null}
        onCancel={onClose}
      >
        <Spin spinning={saving}>
          <Form {...layout} name="nest-messages" className='p-t-20' form={form} onFinish={onSave} validateMessages={validateMessages}>
            
  
              <Form.Item
              name={['id']}
              label={t('scene.type.col-level')}
              hidden
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              name={['type']}
              label={t("setting.station.type")}
              rules={[
                { required: true },
              ]}
            >
              <Input disabled={type==='edit'} />
            </Form.Item>

            <Form.Item
              name={['no']}
              label={t("setting.station.no")}
              rules={[
                { required: true },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name={['comment']}
              label={t("setting.station.comment")}
              rules={[
                { required: false },
              ]}
            >
              <Input />
            </Form.Item>
            
            <Form.Item
              wrapperCol={{
                ...layout.wrapperCol,
                offset: 7,
              }}
            >
              <Button type="primary" htmlType="submit" loading={saving}>
                {t("common.save")}
              </Button>
              <Button danger ghost htmlType="reset" className='m-lr-15'>
                {t("common.reset")}
              </Button>
              <Button type='primary' danger onClick={onClose}>
                {t("common.cancel")}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </article>
  )
})