import { memo, useEffect, useState } from 'react'
import { useRef } from 'react'
import { cloneDeep } from 'lodash'
import { useTranslation } from 'react-i18next'
import { select } from 'd3-selection'
import { message } from 'antd'
import { useGeo } from '@/hooks/editor/geo'
import { CANVAS_MODE } from '@/reducers/editor'
import { useParent } from '@/hooks/editor/parent'
import { MODE_SELECT } from '../../../utils/constant'

const rectHeight = 10
const rectWidth = 10

export default memo(function ({
    selectedEntity,
    isMoving,
    history,
    dispatch,
    parent,
    scale,
    readonly,
}) {
    const { t } = useTranslation()

    const { getXYArea, isBrotherCross, isContained, isContainChildren } = useGeo(history)
    const { isCurrentAableShape } = useParent(parent, selectedEntity)
    const entity = useRef({})
    const rectType = useRef('')

    const startLoc = useRef([0, 0])
    const endLoc = useRef([0, 0])

    const [rectLoc, setRectLoc] = useState({
        sx: 0,
        sy: 0,
        ex: 0,
        ey: 0,
    })
    const [area, setArea] = useState({
        minX: 0,
        minY: 0,
        maxX: Infinity,
        maxY: Infinity,
    })

    const onCalPosition =()=>{
        if (!entity.current || !entity.current.id) return false
        // const { x, y, width, height } = entity.current
        const x = Number(entity.current.x) * scale - Number(parent.x) * scale
        const y = Number(entity.current.y) * scale - Number(parent.y) * scale
        const width = Number(entity.current.width) * scale
        const height = Number(entity.current.height) * scale
        const ex = Number(x) + Number(width)
        const ey = Number(y) + Number(height)
        const rectSx = x - rectWidth/2
        const rectEx = ex - rectWidth/2
        const rectSy = y - rectHeight/2
        const rectEy = ey - rectHeight/2

        const newRectLoc = {
            sx: rectSx,
            sy: rectSy,
            ex: rectEx,
            ey: rectEy
        }
        setRectLoc(newRectLoc)
        return newRectLoc          
    }

    const onCalArea =()=>{
        const area = getXYArea(selectedEntity)
        setArea(area)
    }

    const onGetLoc =loc=>{
        const [x, y] = loc
        // const parent = history.entity(selectedEntity.pid)
        const newX = Number(x) / scale + Number(parent.x)
        const newY = Number(y) / scale + Number(parent.y)
        return [newX, newY]
    }

    const onMouseDown =e=>{
        onDestroy()
        try {
            e.stopPropagation()
            const type = e.target.dataset.type
            if (!type) return false
            // setRectType(type)
            rectType.current = type
            startLoc.current = onGetLoc([e.clientX, e.clientY])
            window.addEventListener('mousemove', onMouseMove)
            window.addEventListener('mouseup', onMouseUp)
        } catch (error) {
            console.error(error)   
        }
    }

    const onMouseMove =e=>{
        e.stopPropagation()
        
        const newEntity = cloneDeep(entity.current)
        const { clientX, clientY } = e
        endLoc.current  = onGetLoc([clientX, clientY])
        
        let { x, y, width, height } = history.entity(newEntity.id)
        let [ex, ey] = [Number(x) + Number(width), Number(y) + Number(height)]
        if (rectType.current.includes('l')) {
            x = Number(x) + (endLoc.current[0] - Number(startLoc.current[0]))
        }
        if (rectType.current.includes('r')) {
            ex = Number(ex) + (endLoc.current[0] - Number(startLoc.current[0]))
        }
        if (rectType.current.includes('t')) {
            y = Number(y) + (endLoc.current[1] - Number(startLoc.current[1]))
        }
        if (rectType.current.includes('b')) {
            ey = Number(ey) + (endLoc.current[1] - Number(startLoc.current[1]))
        }
        const startX = Math.max(Math.min(x, ex), area.minX)
        const startY = Math.max(Math.min(y, ey), area.minY)
        const endX = Math.min(Math.max(x, ex), area.maxX)
        const endY = Math.min(Math.max(y, ey), area.maxY)
        
        newEntity.x = startX
        newEntity.y = startY
        newEntity.width = parseInt(endX - startX)
        newEntity.height = parseInt(endY - startY)
        // dispatch({ type: CHANGE_SELECTED_LAYER, data: newEntity })
        entity.current = newEntity
        onCalPosition()
        onForceRepaint(newEntity)
    }

    const onMouseUp =e=>{
        onDestroy()
        const [ex, ey] = startLoc.current
        const [sx, sy] = endLoc.current
        const id = entity.current.id
        window.requestIdleCallback(()=>{
            history.select([id])
            dispatch({ type: CANVAS_MODE, data: MODE_SELECT })
        })
        if (Math.abs(ex - sx)<1 && Math.abs(ey - sy)<1) {
            return false
        }
        const parent = history.entity(entity.current.pid)
        if (parent && parent.id) {
            if (!isContained(parent, entity.current)) {
                message.error(t('scene.editor.draw-t-no-parent-contain'))
                onForceRepaint(history.entity(entity.current.id))
                return 
            }
            if (isBrotherCross(entity.current, parent)) {
                message.error(t('scene.editor.draw-t-no-cross'))
                onForceRepaint(history.entity(entity.current.id))
                return 
            }
        }
        if (!isContainChildren(entity.current)) {
            message.error(t('scene.editor.draw-t-no-children'))
            onForceRepaint(history.entity(entity.current.id))
            return 
        }
        // const { x, y, width, height } = entity.current
        // entity.current.x = x / scale + parent.x
        // entity.current.y = y / scale + parent.y
        // entity.current.width = width / scale
        // entity.current.height = height / scale
        history.replace(entity.current, true, 'edit-size')
    }

    const onForceRepaint =(entity)=>{
        const { x, y, width, height, text_x, text_y } = entity
        const actualX = (Number(x) - Number(parent.x)) * scale
        const actualY = (Number(y) - Number(parent.y)) * scale

        try {
            select('.editor-container .selected rect')
                .attr('x', actualX )
                .attr('y', actualY)
                .attr('width', Number(width) * scale)
                .attr('height', Number(height) * scale)
            // select('.editor-container .selected text')
            //     .attr('x', Number(text_x) * scale + actualX)
            //     .attr('y', Number(text_y) * scale + actualY)
        } catch (error) {
            
        }
    }

    const onDestroy =()=>{
        window.removeEventListener('mousemove', onMouseMove)
        window.removeEventListener('mouseup', onMouseUp)
    }

    useEffect(()=>{
        entity.current = cloneDeep(selectedEntity)
        onCalPosition()
        onCalArea()
        return ()=>{
            onDestroy()
        }
    }, [selectedEntity])

    if (!entity.current.id) return null
    if (!isCurrentAableShape) return null
    if (readonly) return null

    return (
        <g 
            className={
            `editor-size animated ${entity.current.id&&!isMoving ? 'fadeIn' : 'd-n'}`}
            onMouseDown={onMouseDown}
        >
            <rect 
                data-type='l'
                className=' size-l'
                x={rectLoc.sx}
                y={Number(rectLoc.sy) + rectHeight}
                width={rectWidth/2}
                height={Number(rectLoc.ey) - Number(rectLoc.sy) - rectHeight}
                stroke='transparent'
                fill='transparent'
                style={{cursor:'col-resize'}}
            ></rect>
            <rect 
                data-type='r'
                className='size-r'
                x={rectLoc.sx + Number(entity.current.width) + rectWidth/2}
                y={rectLoc.sy + rectHeight}
                width={rectWidth/2}
                height={rectLoc.ey - rectLoc.sy - rectHeight}
                stroke='transparent'
                fill='transparent'
                style={{cursor:'col-resize'}}
            ></rect>
             <rect 
                data-type='t'
                className='size-t'
                x={rectLoc.sx + rectWidth}
                y={rectLoc.sy}
                width={rectLoc.ex - rectLoc.sx - rectWidth}
                height={rectHeight/2}
                stroke='transparent'
                fill='transparent'
                style={{cursor:'row-resize'}}
            ></rect>
            <rect 
                data-type='b'
                className='size-b'
                x={rectLoc.sx + rectWidth}
                y={rectLoc.sy + Number(entity.current.height) + rectHeight / 2}
                width={rectLoc.ex - rectLoc.sx - rectWidth}
                height={rectHeight/2}
                stroke='transparent'
                fill='transparent'
                style={{cursor:'row-resize'}}
            ></rect>
            <rect 
                data-type='tl'
                className='size-tl'
                x={rectLoc.sx}
                y={rectLoc.sy}
                width={rectWidth}
                height={rectHeight}
                stroke='#0f0'
                fill='#52c41a87'
                style={{cursor:'nwse-resize'}}
            ></rect>
            <rect 
                data-type='tr'
                className='size-tr'
                x={rectLoc.ex}
                y={rectLoc.sy}
                width={rectWidth}
                height={rectHeight}
                stroke='#0f0'
                fill='#52c41a87'
                style={{cursor:'nesw-resize'}}
            ></rect>
            <rect 
                data-type='bl'
                className='size-bl'
                x={rectLoc.sx}
                y={rectLoc.ey}
                width={rectWidth}
                height={rectHeight}
                stroke='#0f0'
                fill='#52c41a87'
                style={{cursor:'nesw-resize'}}
            ></rect>
            <rect 
                data-type='br'
                className='size-br'
                x={rectLoc.ex}
                y={rectLoc.ey}
                width={rectWidth}
                height={rectHeight}
                stroke='#0f0'
                fill='#52c41a87'
                style={{cursor:'nwse-resize'}}
            ></rect>
        </g>
    )
})