import { memo, useEffect, useState } from "react"
import { Button, Col, Divider, Form, Input, Select, Spin, Image, Tooltip, Popconfirm, Pagination } from "antd"
import { useTranslation } from "react-i18next"
import { usePlant } from '@/hooks/plant'

import { getEleCount, getElements, delElement } from '@/axios/axios.dict'
import AddElement from '@/components/modals/addElement'
import { DeleteOutlined } from "@ant-design/icons"
import { useDispatch } from "react-redux"
import { DICT_FACTORS } from "@/store/dicts"

export default memo(function Factor({
    comPlant,
    modal,
    span,
    factorId,
    categorys,
    onChange,
}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { plants, onGetAllPlants } = usePlant()

    const [loadingImg, setLoadingImg] = useState(false)

    const [plant, setPlant] = useState('')
    const [name, setName] = useState('')
    const [category, setCategory] = useState('')

    const [total, setTotal] = useState(0)
    const [imgs, setImgs] = useState([])
    const [current, setCurrent] = useState(1)
    const [size, setSize] = useState(20)

    const onSearch =()=>{
        onGetEles(1)
    }

    const onParams=()=>{
        return {
          plant,
          name,
          category
        }
      }

    const onGetElementCount =async ()=>{
        const params = onParams()
        // if (!params.category) {
        //   setTotal(0)
        //   return false
        // }
        getEleCount(params)
          .then(res=>{
            setTotal(res)
          })
    }

    const onGetEles =(page, cSize)=>{
        setLoadingImg(true)
        onGetElementCount()
        const params = onParams()
        // if (!params.category) {
        //   setImgs([])
        //   setLoadingImg(false)
        //   return false
        // }
    
        const newSize = cSize || size
    
        getElements({
          ...params,
          page,
          size: newSize,
        })
        .then(res=>{
          setImgs(res||[])
          setCurrent(page)
          setSize(newSize)
          const data = {}
          res.forEach(o=>{
            data[o.id] = o
          })
          dispatch({
            type: DICT_FACTORS,
            data
          }) 
        })
        .finally(()=>{
          setLoadingImg(false)
        })
    }

    const onDeleteEle=(row)=>{
        return new Promise(async (resolve, reject)=>{
          try {
            await delElement(row.id)
            resolve()
            onGetEles(1)
          } catch (error) {
            reject()
          }
        })
    }

    useEffect(()=>{
        if (modal) {
            setPlant(comPlant)
            onSearch(1)
        } else {
            onSearch(1)
        }
    }, [comPlant])

    return (
        <Col span={span || 19} className='h-per-100 b-eee b-no-tb b-no-l o-h f-column'>
            {
                modal ? null :
                <Divider orientation="left">{t('scene.element.ele-lib')}</Divider>
            }
            <header className='f-s-c'>
                <Form layout='inline'>
                    <Form.Item label='Plantff' required>
                        <Select
                            value={plant || undefined}
                            style={{ minWidth: 140 }}
                            allowClear
                            disabled={modal}
                            placeholder={t('common.place-select')}
                            onDropdownVisibleChange={open => open && onGetAllPlants()}
                            onChange={e => setPlant(e)}>
                            {
                                plants.map(o => (
                                    <Select.Option key={o.code} value={o.code}>{o.plant}</Select.Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item label={t('scene.element.cate-label')} required>
                        <Select
                            value={category || undefined}
                            style={{ minWidth: 210 }}
                            allowClear
                            placeholder={t('common.place-select')}
                            onChange={e => setCategory(e)}>
                            {
                                categorys.map(o => (
                                    <Select.Option key={o.id} value={o.id}>{o.name}</Select.Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item label={t("scene.element.ele-name")}>
                        <Input 
                            value={name || undefined} 
                            allowClear 
                            className='w-130'
                            placeholder={t('common.place-input')} 
                            onChange={e => setName(e.target.value)} ></Input>
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type='primary' 
                            loading={loadingImg} onClick={onSearch}>{t('common.search')}</Button>
                    </Form.Item>
                </Form>
                {
                    modal ? null :
                    <AddElement add={onSearch} />
                }
            </header>
            <section className='f-1 o-a'>
                <Spin spinning={loadingImg}>
                    <ul className='clearfix' style={{ minHeight: 100 }}>
                        {
                            imgs.map(o => (
                                <li 
                                    key={o.id} 
                                    className='f-column f-l b-eee p-10 m-r-10 m-tb-5'
                                    style={{borderColor: String(factorId) === String(o.id) ? "#f00" : "#eee"}}
                                >
                                    <section className='w-100 h-100 f-c o-h'>
                                        <Image
                                            width={100}
                                            src={o.icon}
                                            style={{ maxWidth: '100%', maxHeight: '100%', width: '100%' }}
                                        />
                                    </section>
                                    <footer className='f-b-c h-25'>
                                        <Tooltip title={o.name}>
                                            <h3 className='white-1 f-s-12 c-999' style={{ maxWidth: 84 }}>{o.name}</h3>
                                        </Tooltip>
                                        {
                                            modal ?
                                            <Button 
                                                type='link' 
                                                size='small'
                                                disabled={String(factorId) === String(o.id)}
                                                onClick={()=>onChange(o)}
                                            >{t('common.select')}</Button> :
                                            <Popconfirm
                                                title={t('common.notice')}
                                                description={t('scene.element.ele-del-ct')}
                                                onConfirm={() => onDeleteEle(o)}
                                            >
                                                <DeleteOutlined className='c-p c-red' />
                                            </Popconfirm>
                                        }
                                    </footer>
                                </li>
                            ))
                        }
                    </ul>
                </Spin>
            </section>
            <Pagination
                size="small"
                total={total}
                current={current}
                pageSize={size}
                showSizeChanger
                showQuickJumper
                onChange={onGetEles} />
        </Col>
    )
})
