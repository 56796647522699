import { memo, useState } from 'react'
import { Tooltip, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { selectAll } from 'd3-selection'
import { ExportOutlined } from '@ant-design/icons'
import PDF from '@wbiokr/html2pdf'
import canvg from 'canvg'
import { CHANGE_GRID } from '@/reducers/editor'
import { exportLayout } from '@/axios/axios.scene'
import { downBlob } from '@/utils/file'
import { Modal } from 'antd'
import { Select } from 'antd'
import { useParams } from 'react-router-dom'

const papers = [
    { label: 'A0', value: 'A0' },
    { label: 'A1', value: 'A1' },
    { label: 'A3', value: 'A3' },
    { label: 'A4', value: 'A4' },
]

export default memo(function Export({
    dispatch
}) {
    const params = useParams()
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const [pageSize, setPageSize] = useState('A1')

    const onSVGToPng = () => {
        var svg = document.querySelector('#arrow-wrapper').outerHTML;//获取svg所有元素
        var img = document.createElement('img'); //新建img
        img.src = 'data:image/svg+xml;base64,'+window.btoa(unescape(encodeURIComponent(svg)));//svg base64串显示图片
        var canvas = document.createElement('canvas'); //新建画布
        var ctx = canvas.getContext('2d');
        img.onload = function(){
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img,0,0); //把svg base64串图片绘制到画图中
            console.log(canvas.toDataURL('image/jpeg')); //获取jpg格式的base64串
            console.log(canvas.toDataURL('image/png')); //获取png格式的base64串
            const div = document.createElement('aside')
            div.appendChild(canvas)
            div.className = 'p-f-xl-yt w-per-50 h-per-50'
            div.style.zIndex = '10000'
            div.style.border = '3px solid #f00'
            div.style.background = '#fff'
            document.body.appendChild(div)
        }
    }

    const onExport = async () => {
        if (loading) return
        setLoading(true)
        try {
            selectAll('.grid-section').attr('style', 'opacity: 0')
            dispatch({ type: CHANGE_GRID, data: false })

            const tempDom = document.querySelector('#layout-editor div');
            const svgDom = tempDom.querySelector('svg');
            const svgHtml = svgDom.querySelector('#arrow-wrapper').outerHTML.trim();
            
            // const tempDom = document.querySelector('aside')
            // tempDom.className = 'p-f-xr-yb'
            // tempDom.style.right = '-5000px'
            // tempDom.innerHTML = svgHtml

            const canvas = document.createElement('canvas');
            canvas.width =  svgDom.getBoundingClientRect().width;
            canvas.height =  svgDom.getBoundingClientRect().height;
            var ctx=canvas.getContext("2d");
                ctx.fillStyle = 'rgba(255, 255, 255, 0)';
            // canvas.className = 'p-a-xl-yt z-100'
            canvas.style.left = '0';
            canvas.style.top = '0';
            canvas.style.position = 'absolut';
            canvas.style.zIndex = '100';

            canvg(canvas, svgHtml)
            const dataURL = canvas.toDataURL("image/png")
            const imgDom = svgDom.querySelector('.svg-image')
            imgDom.setAttribute('href', dataURL)
            imgDom.style.width = `${canvas.width}px`
            imgDom.style.height = `${canvas.height}px`
            imgDom.style.display = 'block';
            
            const tempImg = document.createElement('img');
            tempImg.src = dataURL;
            tempImg.style.left = '0px';
            tempImg.style.top = '0px';
            tempImg.style.position = 'absolute';
            tempImg.style.zIndex = '100';

            // const svgDD = tempDom.querySelector('svg')
            // svgDom.style.backgroundImage = `url(${dataURL})`;
            // svgDom.style.backgroundRepeat = 'no-repeat';
            // svgDom.style.backgroundSize = '100% 100%';
            // svgDom.style.backgroundPosition = 'left top';

            // tempDom.appendChild(tempImg)
            setTimeout(() => {
                // tempImg.remove();
                imgDom.style.display = 'none';
                imgDom.setAttribute('href', '');
            }, 300);

            tempImg.onload = async function() {
                const pdf = new PDF()
                const fileSource = await pdf.html2png('#layout-editor div', { page: 'A0', scale: 5 })
                const file = pdf.base64ToFile(fileSource)
    
                // document.querySelector('.fffffffffffffffffff') && document.querySelector('.fffffffffffffffffff').remove()
                // const img = document.createElement('img')
                // img.src = fileSource
                // img.class='fffffffffffffffffff p-f-xl-yt z-1000'
                // document.body.append(img)
                // onSVGToPng()
                // return
                const fd = new FormData()
                fd.append('file', file)
                // return
                const { id, design } = params

                const downFile = await exportLayout(id, design, pageSize, fd)
                downBlob(`${Date.now()}.pdf`, downFile)
                setLoading(false)
            }

            tempImg.onerror = function(err) {
                setLoading(false)
            }

            

        } catch (error) {
            console.log(error)
            setLoading(false)
        } finally {
            setTimeout(() => {
                selectAll('.grid-section').attr('style', 'opacity: 1')
                dispatch({ type: CHANGE_GRID, data: true })
            }, 600)
        }
    }

    return (
        <>
            <Tooltip title={t("common.export")}>
                <Button
                    className='animated fadeInLeft m-r-10'
                    type='link'
                    loading={loading}
                    icon={<ExportOutlined />}
                    onClick={() => {setPageSize('A1') ; setVisible(true)}}
                ></Button>
            </Tooltip>
            <Modal
                title='Export PDF'
                open={visible}
                onCancel={() => setVisible(false)}
                onOk={onExport}
                confirmLoading={loading}
            >
                <h3>{t('scene.layout.paper-size')}</h3>
                <Select
                    className={'w-200 m-t-10'}
                    value={pageSize}
                    onChange={setPageSize}
                    options={papers}
                />
            </Modal>
        </>
    )
})
