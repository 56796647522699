import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translation_en from '@/data/en.json';
import translation_zh from '@/data/zh.json';
import { IL8N_LANG } from '@/utils/storage';

const resources = {
  en: {
      translation: translation_en,
  },
  zh: {
      translation: translation_zh,
  },
};

const lang = localStorage.getItem(IL8N_LANG)||"zh";
i18n
  .use(initReactI18next)
  .init({
    fallbackLng: lang,
    lng: lang,
    // debug: true,
    resources: resources,
    interpolation: {
      escapeValue: false,
    }
  });

export default i18n;