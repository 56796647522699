import { USER_NAME } from '@/utils/storage'
import { ROLE_ADMIN } from '@/utils/constant'

const initState = {
  username: '',
  token: '',
  userId: '',
  // role: '',
  role: ROLE_ADMIN,
}

export const ACCOUNT_USERNAME = Symbol()
export const ACCOUNT_TOKEN = Symbol()
export const ACCOUNT_ROLE = Symbol()
export const ACCOUNT_USERID = Symbol()

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initState, { type, data }) {
  switch (type) {
    case ACCOUNT_USERNAME:
      return Object.assign({}, state, { username: data })
    case ACCOUNT_TOKEN:
      return Object.assign({}, state, { token: data })
    case ACCOUNT_USERID:
      return Object.assign({}, state, { userId: data })
    case ACCOUNT_ROLE:
      console.log(345, data)
      return Object.assign({}, state, { role: data })
    default:
      return state
  }
}