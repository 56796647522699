export const ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN'
export const ROLE_ADMIN = 'ROLE_ADMIN'
export const ROLE_USER = 'ROLE_USER'

export const USER_SUPER_ADMIN = 127
export const USER_ADMIN = 100
export const USER_NOEMAL = 1

export const STATE_WORKING = 1
export const STATE_RELASING = 2
export const STATE_RELASED = 3

export const MODE_BROWSE = Symbol()
export const MODE_COPY = Symbol()
export const MODE_DRAW = Symbol()
export const MODE_SELECT = Symbol()
export const MODE_ARROW = Symbol()
