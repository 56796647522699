import { memo } from 'react';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

export default memo(function Drawer(props) {

  const isLeft = props.placement === "left";

  return (
    <aside
      className={` 
        ${isLeft ? " p-a-xl-yt shadow-right " : " p-a-xr-yt shadow-left "}
        h-per-100 b-eee ease-300 p-t-35 p-r o-a ${props.className}
        `}
      style={{
        "background": "rgba(255, 255, 255, 0.89)",
        "width": props.width || 150,
        "transform": props.open ? "translateX(0)" : (isLeft ? "translateX(-100%)" : "translateX(100%)"),
        "opacity": props.open ? 1 : 0
      }}
    >
      <header className='f-b-c p-a-xl-yt w-per-100 p-lr-10 b-eee b-no-lr b-no-t h-35'>
        <span className='c-app f-s-14'>{props.title}</span>
        {
          props.closable ?
            <Button
              type='link'
              icon={<CloseOutlined />}
              onClick={props.onClose}
            ></Button> : null
        }
      </header>
      {props.children}
    </aside>
  )
})
