import { memo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
// import i18n from 'i18next'
import { Tag, Dropdown, Button } from 'antd'
import useLang from '@/hooks/lang'
import { useLogin } from '@/hooks/login'
import { useRole } from '@/hooks/role'
import Menu from './menu'
import Marker from '@/components/marker'

export default memo(function ({ menus }) {
  const { t } = useTranslation();
  const { name, nameZh, nameEn, onChangeLang } = useLang();
  const { onLogout } = useLogin();
  const { roleName } = useRole();
  const account = useSelector(state => state.account);

  const langs = [
    {
      key: 'zh',
      label: <Button type='link' onClick={() => onChangeLang('zh')}>{nameZh}</Button>
    },
    {
      key: 'en',
      label: <Button type='link' onClick={() => onChangeLang('en')}>{nameEn}</Button>
    }
  ];

  const users = [
    {
      key: 'out',
      label: <Button type='link' onClick={onLogout}>{t('login.logout')}</Button>
    }
  ];

  return (
    <header className='bg-color-142342 h-60 f-b-c p-lr-12'>
      {/* <h3 className='f-s-16 f-w-600 m-no c-fff'>{t('app.title')}</h3> */}
      <div className="f-s-c h-per-100 c-fff f-s-c">
        <Marker
          size={45}
          d={["M929.792 514.048l-409.6 190.464-423.936-204.8c-18.432-10.24-40.96-2.048-51.2 16.384s-2.048 40.96 16.384 51.2L501.76 778.24c6.144 2.048 12.288 4.096 16.384 4.096s12.288-2.048 14.336-4.096l425.984-196.608c18.432-10.24 26.624-32.768 18.432-51.2-4.096-16.384-26.624-24.576-47.104-16.384z", "M63.488 344.064l440.32 210.944c6.144 2.048 12.288 4.096 16.384 4.096s12.288-2.048 14.336-4.096l425.984-196.608c12.288-6.144 20.48-18.432 22.528-34.816 0-14.336-8.192-28.672-20.48-34.816L534.528 73.728c-10.24-6.144-22.528-6.144-34.816 0L63.488 276.48c-12.288 6.144-20.48 18.432-22.528 34.816 2.048 12.288 10.24 24.576 22.528 32.768z m450.56-196.608L860.16 323.584l-337.92 155.648-356.352-169.984 348.16-161.792zM929.792 686.08l-409.6 190.464-423.936-202.752c-18.432-10.24-40.96-2.048-51.2 16.384s-2.048 40.96 16.384 51.2l440.32 210.944c6.144 2.048 12.288 4.096 16.384 4.096s12.288-2.048 14.336-4.096l425.984-196.608c18.432-10.24 26.624-32.768 18.432-51.2-4.096-18.432-26.624-26.624-47.104-18.432z"]} />
        <section className='f-column justify-c h-per-100 w-195 p-l-10'>
          <h3 className='m-no-b f-1 f-s-c c-eee f-s-14 f-w-500 p-t-10'>Layout planning cockpit 1.0</h3>
          <h4 className='h-35 f-s-c m-no-b c-fff f-w-600 f-s-20 p-b-5'>LPC</h4>
        </section>
        <section className='p-t-25'>
          <Menu menus={menus} />
        </section>
      </div>
      <div className='f-s-c p-t-20'>
        <Tag color='orange'>{roleName}</Tag>
        <Dropdown menu={{ items: langs }} placement="bottomRight" arrow>
          <Button type='link'>{t("app.lang")}({name})</Button>
        </Dropdown>
        <Dropdown menu={{ items: users }} placement="bottomRight" arrow>
          <Button type='link'>{account.username}</Button>
        </Dropdown>
      </div>
    </header>
  )
});