const initState = {
  height: window.innerHeight,
  width: window.innerWidth,
  lang: 'en',
};

export const COMMON_HEIGHT = Symbol();
export const COMMON_LANG = Symbol();

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initState, action) {
  switch (action.type) {
    case COMMON_HEIGHT:
      return Object.assign({}, state, { height: window.innerHeight });
    case COMMON_LANG:
      return Object.assign({}, state, { lang: action.data });
    default:
      return state;
  }
};