
import { memo, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Table, Button, Form, Input, message } from 'antd'
import { ExportOutlined, ReloadOutlined, SearchOutlined } from '@ant-design/icons'

import { useEquipment } from '@/hooks/equipment'
import More from '@/components/more/'
import { useSelector } from 'react-redux'
import { exportEquips } from '@/axios/axios.dict'
import { downBlob } from '@/utils/file'

export default memo(function Equip({
  modal,
  onSelect,
}) {
  const { t } = useTranslation()
  const common = useSelector(state=>state.common)

  const { query, setQuery, equipments, loadingEqup, onGetAllEquips, page, end} = useEquipment()
  const [exporting, setExporting] = useState(false)
  
  const columns = [
    { title: t('setting.equip.c-idx'), dataIndex: 'id', width: 60 },
    { title: t('setting.equip.c-code'), dataIndex: 'asset_id'},
    { title: t('setting.equip.c-type'), dataIndex: 'asset_type'},
    { title: t('setting.equip.c-name'), dataIndex: 'asset_name'},
    { title: t('setting.equip.c-model'), dataIndex: 'model'},
    { title: t('setting.equip.c-supplier'), dataIndex: 'vendor_cn'},
    { title: t('setting.equip.c-e-no'), dataIndex: 'equip_no'},
    { title: t('setting.equip.c-pm'), dataIndex: 'equip_mgmt_id'},
    { title: t('setting.equip.c-e-name'), dataIndex: 'equip_name'},
    { title: t('setting.equip.c-loc'), dataIndex: 'location'},
    { title: t('setting.equip.c-dep'), dataIndex: 'service_dept'},
    { title: t('setting.equip.c-status'), dataIndex: 'status'},
    !modal ? null :
    {
      title: t('common.action'),
      render(text, record) {
        return (
          <Button type="primary" onClick={()=>onSelect(record)}>{t('common.select')}</Button>
        )
      }
    }
  ].filter(Boolean)

  const onReset = ()=>{
    new Promise((resolve) => {
      setQuery('')
      resolve()
    })
    .then(()=>{
      onGetAllEquips(1, '')
    })
  }

  const onExport = async () => {
    try {
        setExporting(true)
        const downFile = await exportEquips()
        downBlob(`${Date.now()}.xlsx`, downFile)
    } catch (error) {
        message.error(error.message)
    } finally {
        setExporting(false)
    }
}

  return (
    <article>
      <header className='f-s-c p-b-10'>
        <Form layout='inline'>
          <Form.Item label={t('common.keyword')}>
            <Input value={query} placeholder={t('common.keyword')} onChange={e=>setQuery(e.target.value)} />
          </Form.Item>
        </Form>
        <Button type='primary' ghost icon={<SearchOutlined />} className='m-r-20' onClick={()=>onGetAllEquips(1)}>{t("common.search")}</Button>
        <Button type='primary' danger icon={<ReloadOutlined />} className='m-l-20' onClick={onReset}>{t("common.reset")}</Button>
        <Button
            type='primary'
            className='animated fadeInLeft m-l-10'
            loading={exporting}
            icon={<ExportOutlined />}
            onClick={onExport}
        ></Button>
      </header>
      <Table
        rowKey='id'
        size='small'
        loading={loadingEqup}
        dataSource={equipments}
        columns={columns}
        pagination={false}
        scroll={modal ? {y: common.height - 400} : null}
        summary={() => (
          <Table.Summary>
            <Table.Summary.Row>
              <Table.Summary.Cell index={1} colSpan={columns.length}>
                <More 
                  more={onGetAllEquips}
                  page={page}
                  end={end}
                />
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      ></Table>

      
      
    </article>
  )
})