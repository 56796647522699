import { polygon as tPolygon, point as tPoint, intersect } from 'turf'
import booleanPointInPolygon from '@turf/boolean-point-in-polygon'
import booleanContains from '@turf/boolean-contains'

export function useGeo(history) {
  
  const getChildren =parent=>{
    const pid = parent.id
    const entities = history.entities
    const list = []
    for (let id in entities) {
      const entity = entities[id]
      if (!pid) {
        if (entity.level === 2) {
          list.push(entity)
        }
      } else {
        if (entity.pid === pid) {
          list.push(entity)
        }
      }
    }
    return list
  }

  const getPolyByEntity = (entity)=>{
    // const { x, y, width, height } = entity
    
    let x = Number(entity.x) || 0
    let y = Number(entity.y) || 0
    let width = Number(entity.width) || 0
    let height = Number(entity.height) || 0
    let ex = x + width
    let ey = y + height

    let equipId

    if (Number(entity.level) === 6) {
        const equipEntity = history.children(entity.id)[0]
        if (equipEntity) {
          // const equip = history.equip(equipEntity.equip)
          // if (equip) {

          // }
          equipId = equipEntity.equip
        }
    } else if (Number(entity.level) === 7) {
      equipId = entity.equip
    }

    if (equipId) {
      const equip = history.equip(equipId)
      if (equip) {
        x = Number(equip.x) || 0
        y = Number(equip.y) || 0
        width = 1
        height = 1
        ex = x + width
        ey = y + height
      }
    }
    const polygon = tPolygon([[
      [x, y],
      [ex, y],
      [ex, ey],
      [x, ey],
      [x, y]
    ]])

    return polygon
  }

  const getBoxLoc =(entity, loc)=>{
    const parent = history.entity(entity.pid)
    if (parent && parent.id) {
      const { x, y, width, height } = parent
      let [ex, ey] = [Number(x) + Number(width), Number(y) + Number(height)]
      let [tarX, tarY] = loc

      tarX = Math.max(Math.min(ex, tarX), x)
      tarY = Math.max(Math.min(ey, tarY), y)
      return [tarX, tarY]
    } else {
      return loc
    }
  }

  const getEquipLayLoc = (entity, isEquip) => {
    let equipId

    if (isEquip) {
      equipId = entity.id
    } else {
      if (Number(entity.level) === 6) {
          const equipEntity = history.children(entity.id)[0]
          if (equipEntity) {
            equipId = equipEntity.equip
          }
      } else if (Number(entity.level) === 7) {
        equipId = entity.equip
      }
    }

    if (equipId) {
      const equip = history.equip(equipId)
      if (equip) {
        const x = Number(equip.x) || 0
        const y = Number(equip.y) || 0
        return [x, y]
      }
    }

    return false
  }
  

  const getXYArea =(entity) =>{
    const parent = history.entity(entity.pid)
    if (!parent || !parent.id) {
      return {
        minX: 0,
        minY: 0,
        maxX: Infinity,
        maxY: Infinity,
      }
    }
    const { x, y, width, height } = parent
    const [ex, ey] = [Number(x) + Number(width), Number(y) + Number(height)]
    const childW = entity.width
    const childH = entity.height
    return {
      minX: x,
      minY: y,
      maxX: ex,
      maxY: ey,
      maxEntityX: ex -childW,
      maxEntityY: ey - childH,
    }
  }

  const isCross =(aEntity, bEntity)=> {
    const aPolygon = getPolyByEntity(aEntity)
    const bPolgyon = getPolyByEntity(bEntity)

    return intersect(aPolygon, bPolgyon)
  }
  
  const isBrotherCross =(cell, parent, excludes=[])=> {
    const children = getChildren(parent)

    for (let i=0; i<children.length; i++) {
      const entity = children[i]
      if (entity.id !== cell.id && !excludes.includes(entity.id) && isCross(entity, cell)) {
        // console.log(entity, cell)
        return true
      }
    }
    return false
  }

  const isPointInPolygon=(loc, parent)=> {
    const poly = getPolyByEntity(parent)
    const pt = tPoint(loc)
    return booleanPointInPolygon(pt, poly)
  }

  const isPointInBrother =(loc, parent)=> {
    const children = getChildren(parent)
    const pt = tPoint(loc)
    for (let i=0; i<children.length; i++) {
      if (isPointInPolygon(loc, children[i])) return true
    }
    return false
  }

  const isContained =(parent, entity)=>{
    const pPoly = getPolyByEntity(parent)
    const cPoly = getPolyByEntity(entity)
    return booleanContains(pPoly, cPoly)
  }

  const isContainChildren =parent=>{
    const isEquipLay = Number(parent.level) >= 5
    const children = isEquipLay ? 
      history.equipChildren(parent.id) : 
      history.children(parent.id)
    
    for (let i=0; i<children.length; i++) {
      const entity = children[i]
      if (isEquipLay) {
        const loc = getEquipLayLoc(entity, true)
        if (loc && !isPointInPolygon(loc, parent)) return false
      } else {
        if (!isContained(parent, entity)) return false
      }
    }
    return true
  }


  return {
    getXYArea,
    getBoxLoc,
    isBrotherCross,
    isPointInPolygon,
    isContained,
    isPointInBrother,
    isContainChildren,
  }
}