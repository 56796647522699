import { memo, useEffect, useRef, useState } from "react"
import { useSceneDicts } from '@/hooks/scen_dicts'
import { Button, Checkbox, Dropdown, Select, Tooltip } from "antd"
import { CANVAS_LEVELS } from "@/reducers/editor"
import { useTranslation } from 'react-i18next'
import Marker from '@/components/marker/' 

export default memo(function ShowLevel({
    showLevels,
    dispatch,
}) {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const { scenes, sceneCates } = useSceneDicts()
    const inited = useRef(false)

    const items = [
        ...scenes.map((o) => ({
            key: o.level,
            value: o.level,
            label: <Checkbox 
                value={String(o.level)} 
                checked={showLevels.includes(String(o.level))}
                onChange={(e)=>onChange(e, o.level)}
            >{o.name}</Checkbox>,
        })),
        {
            key: 'arrow',
            value: 'arrow',
            label: <Checkbox 
                value={'arrow'} 
                checked={showLevels.includes('arrow')}
                onChange={(e)=>onChange(e, 'arrow')}
            >{t('common.arrow')}</Checkbox>
        }
    ]

    const onInit = ()=> {
        if (scenes.length > 0 && !inited.value) {
            dispatch({ type: CANVAS_LEVELS, data: [...scenes.map(o => String(o.level)), 'arrow'] })
            inited.value = true
        }
    }

    const onClick = e => {
        e.preventDefault()
        e.stopPropagation()
        setOpen(bl=>!bl)
    }

    const onChange = (e, levle) => {
        e.preventDefault()
        e.stopPropagation()
        const newLevels = [...showLevels]
        const index = newLevels.indexOf(String(levle))

        if (index > -1) {
            newLevels.splice(index, 1)
        } else {
            newLevels.push(levle)
        }
        dispatch({ type: CANVAS_LEVELS, data: newLevels.map(String) })
    }

    useEffect(() => {
        onInit()
    }, [scenes])

    return (
        <Dropdown open={open} menu={{ items }} trigger={['click']}>
            <Tooltip title={t('common.layer')}>
                <Button type='link' className='p-lr-5' onClick={onClick}>
                  <Marker 
                    size={20}
                    d={
                        "M892.361598 416.054399V131.936a46.6944 46.6944 0 0 0-12.7072-32.224 42.256 42.256 0 0 0-30.704-13.2544H545.193599c-11.552 0-22.496 4.7424-30.704 13.2544a46.6944 46.6944 0 0 0-12.7072 32.224v284.179199c0 25.1104 19.456 45.5392 43.4112 45.5392h303.756799c11.552 0 22.5568-4.7424 30.704-13.376a46.6944 46.6944 0 0 0 12.768-32.1632zM457.398399 562.399999H153.6416c-23.8944 0-43.3504 20.4288-43.3504 45.5392v284.118399c0 25.1712 19.456 45.6 43.4112 45.6h303.695999c24.0768 0 43.4112-20.4288 43.4112-45.5392V607.999999c0-25.1104-19.456-45.5392-43.3504-45.5392zM204.896 479.955199l118.499199-145.1904H243.382399c0.5472-81.776 70.6496-168.416 145.7984-168.415999a34.048 34.048 0 0 0 33.6224-34.048v-13.0112a32.224 32.224 0 0 0-32.224-32.224c-124.8832 0-226.0544 110.4128-226.054399 246.604799v1.0944H86.336L204.896 479.955199z m732.700798 209.881599l-118.56-145.068799-118.4384 145.068799h81.2288c-0.608 81.8368-73.264 165.2544-148.351999 165.2544a32.224 32.224 0 0 0-32.2848 32.224v18.0576c0 17.8144 14.4096 32.224 32.224 32.224 124.8832 0 226.0544-110.4128 226.054399-246.6048v-1.1552h78.128z"
                    }
                  />
                </Button>
              </Tooltip>
        </Dropdown>
    )
})