import { ColorPicker, Divider, Form, InputNumber, Tag } from "antd"
import { memo, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { TAG_TEXT_FILL, TAG_TEXT_ATTR } from '@/utils/storage'
import { formatterNumber } from "@/utils/util"

export default memo(function TagsBasic({
    readonly,
    layer,
    onChange
}) {
    const { t } = useTranslation()
    const [basic, setBasic] = useState({})
    const [colors, setColors] = useState([])
    const fill = useRef('')

    const onChangeBasic = (k, v, isSelect) => {
        const newBasic = {
            ...basic,
            [k]: v
        }
        setBasic(newBasic)
        if (isSelect) {
            onChange(newBasic)
        }
    }

    const onChangeLayer = () => {
        let flag = false 
        let attrs = ['text_x', 'text_y', 'text_size', 'text_fill', 'text_rotate']
        for (let i=0; i<attrs.length; i++) {

            if (attrs[i] === 'text_rotate') {

            }
            if (String(basic[attrs[i]]) !== String(layer[attrs[i]])) {
                flag = true
                break
            }
        }

        if (!flag) return false
        onChange(basic)
        sessionStorage.setItem(TAG_TEXT_ATTR, JSON.stringify({
            text_fill: basic.text_fill,
            text_size: basic.text_size,
            text_x: basic.text_x,
            text_y: basic.text_y,
            text_rotate: basic.text_rotate
        }))
    }

    const onChangeColor =(e, v)=>{
        onChangeBasic('text_fill', v, true)
        // let flag = false 

        // fill.current = v
        // let attrs = ['text_x', 'text_y', 'text_size', 'text_fill']
        // for (let i=0; i<attrs.length; i++) {
        //     if (String(basic[attrs[i]]) !== String(layer[attrs[i]])) {
        //         flag = true
        //         break
        //     }
        // }
        // if (!flag) return false

        sessionStorage.setItem(TAG_TEXT_ATTR, JSON.stringify({
            text_fill: v,
            text_size: basic.text_size,
            text_x: basic.text_x,
            text_y: basic.text_y,
            text_rotate: basic.text_rotate || 0
        }))
    }
    
    const onOpenChange =(v)=>{
        if (v) {
            fill.current = ''
            
        } else {
            if (colors.includes(fill.current)) return false
            setColors(colors =>[ fill.current, ...colors ].filter(Boolean).slice(0, 20))

        }
    }

    useEffect(() => {
        setBasic({
            ...layer
        })
    }, [layer])

    useEffect(()=>{
        try {
            setColors(JSON.parse(localStorage.getItem(TAG_TEXT_FILL)).slice(0, 20))
        } catch (error) {
            setColors([])
        }
    }, [])

    useEffect(()=>{
        localStorage.setItem(TAG_TEXT_FILL, JSON.stringify(colors))
    }, [colors])

    return (
        <>
            <Divider orientation='left' orientationMargin='10%'>
                <Tag color='blue'>{t('scene.editor.title')}</Tag>
            </Divider>
            <Form.Item label={t('scene.editor.title-x')}>
                <InputNumber
                    disabled={!basic.id || readonly}
                    value={basic.text_x}
                    min={1}
                    max={10000000}
                    step={1}
                    formatter={formatterNumber}
                    addonAfter={t('scene.editor.meter')}
                    onChange={e => onChangeBasic("text_x", e)}
                    onBlur={onChangeLayer}
                ></InputNumber>
            </Form.Item>
            <Form.Item label={t('scene.editor.title-y')}>
                <InputNumber
                    disabled={!basic.id || readonly}
                    value={basic.text_y}
                    min={1}
                    max={10000000}
                    step={1}
                    formatter={formatterNumber}
                    addonAfter={t('scene.editor.meter')}
                    onChange={e => onChangeBasic("text_y", e)}
                    onBlur={onChangeLayer}
                ></InputNumber>
            </Form.Item>
            <Form.Item label={t('scene.editor.title-size')}>
                <InputNumber
                    disabled={!basic.id || readonly}
                    value={basic.text_size || ""}
                    min={10}
                    max={10000000}
                    step={1}
                    addonAfter='px'
                    onChange={e => onChangeBasic("text_size", e)}
                    onBlur={onChangeLayer}
                ></InputNumber>
            </Form.Item>
            <Form.Item label={t('scene.editor.title-rotate')}>
                <InputNumber
                    disabled={!basic.id || readonly}
                    value={basic.text_rotate || ""}
                    min={0}
                    max={360}
                    step={1}
                    onChange={e => onChangeBasic("text_rotate", e)}
                    onBlur={onChangeLayer}
                ></InputNumber>
            </Form.Item>
            <Form.Item label={t('scene.editor.title-color')}>
                <ColorPicker
                    disabled={!layer.id || readonly}
                    value={layer.text_fill || ""}
                    presets={[{
                        label: 'Recent',
                        colors: colors,
                    }]}
                    onOpenChange={onOpenChange}
                    onChange={onChangeColor} />
            </Form.Item>
            
        </>
    )
})
