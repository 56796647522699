import { Button, Form, Input, Select } from 'antd'
import { debounce } from 'lodash'
import { memo, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { SCENE_NAME } from '@/store/scene'
import { SCENE_LIST, SCENE_LOADING } from '../../store/scene'
import { getScenes } from '@/axios/axios.scene'
import { PlusOutlined, ReloadOutlined } from '@ant-design/icons'
import { useLocation } from 'react-router-dom'
import { usePlant } from '@/hooks/plant'
import { ROLE_SUPER_ADMIN } from '@/utils/constant'

export default memo(function({ label }) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { name, flag, loading, stamp } = useSelector(state=>state.scene)
    const { plants } = usePlant()

    const [plant, setPlant] = useState(undefined)
    const account = useSelector(state => state.account)
    const location = useLocation()
    const showAside = useRef(false)

    const onChangeName = e => {
        dispatch({type: SCENE_NAME, data: e.target.value})
    }

    const onGetScenes =debounce(()=>{
        dispatch({ type: SCENE_LOADING, data: true })
        getScenes({
            name,
            plant,
        })
        .then(res=>{
            console.log(account.userId, account)
            dispatch({
                type: SCENE_LIST,
                data: res.map(o=>{
                    return {
                        ...o,
                        isSelf: String(o.creator) === String(account.userId) || account.role === ROLE_SUPER_ADMIN,
                    }
                })
            })
        })
        .finally(()=>{
            dispatch({ type: SCENE_LOADING, data: false, })
        })
    }, 400)

    const onReset = () => {
        dispatch({ type: SCENE_NAME, data: '' })
    }

    const onFilter = (input, option) => {
        return String(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
    }

    useEffect(() => {
        if (location.pathname === '/scene/list') {
            onGetScenes()
        }
        showAside.current = location.pathname === '/scene/list'
    }, [location.pathname])

    useEffect(() => {
        if (stamp > 0) {
            onGetScenes()
        }
    }, [stamp])

    useEffect(() => {
        onGetScenes()
    }, [account.userId])

    return <>
        { label }
        <aside className={`p-a-xl-yt w-per-100 m-t-50 animated ${showAside.current ? 'fadeIn' : ''}`}>
            <Link to={'/scene/editor/new'} className='w-per-100'>
                <Button type='primary' ghost icon={<PlusOutlined />} className='p-lr-25 w-per-100'>{t('scene.layout.add')}</Button>
            </Link>
            <div className='w-per-100'>
                <Select 
                    className='w-per-100'
                    placeholder="Select"
                    value={plant} 
                    allowClear
                    showSearch
                    options={plants.map(o => ({ label: `${o.plant}(${o.name})`, value: o.code }))}
                    filterOption={onFilter}
                    onChange={e => setPlant(e)}>
                </Select>
            </div>
            <div className='f-b-c p-tb-10'>
                <Input.Search className='f-1' value={name} placeholder={t('scene.layout.name')} enterButton onChange={onChangeName} onSearch={onGetScenes} />
                <Button type='primary' danger icon={<ReloadOutlined />} className='m-l-20' onClick={onReset}></Button>
            </div>
        </aside>
    </>
})
