const initState = {
    scenes: [],
    sceneCates: [],
    sceneCateChildren: [],

    equips: {},
    stations: [],
    factors: {},
}

export const DICT_SCENES = Symbol()
export const DICT_SCENE_CATES = Symbol()
export const DICT_SCENE_CATE_CHILDREN = Symbol()
export const DICT_EQUIP = Symbol()
export const DICT_STATIONS = Symbol()
export const DICT_FACTORS = Symbol()

export default function Didcts(state=initState, action) {
    switch (action.type) {
        case DICT_SCENES:
            return { ...state, scenes: action.data }
        case DICT_SCENE_CATES:
            return { ...state, sceneCates: action.data }
        case DICT_SCENE_CATE_CHILDREN:
            return { ...state, sceneCateChildren: action.data }
        case DICT_EQUIP:
            return { ...state, equips: {
                ...state.equips,
                ...action.data,
            }}
        case DICT_STATIONS:
            return { ...state, stations: action.data }
        case DICT_FACTORS:
            return {
                ...state,
                factors: {
                    ...state.factors,
                    ...action.data
                }
            }
        default:
            return state
    }
}