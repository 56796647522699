export const CHANGE_SELECTED_LAYER = Symbol()
export const CHANGE_SELECTED_IDS = Symbol()
export const CHANGE_PARENT_LAYER = Symbol()
export const CHANGE_SELECTED_EQUIP = Symbol()
export const CHANGE_SELECTED_EQUIP_IDS = Symbol()
export const CHANGE_SELECTED_ARROW = Symbol()
export const CHANGE_SELECTED_ARROW_IDS = Symbol()
export const CHANGE_FILTERS = Symbol()

export const CHANGE_SHOW_STRUCTOR = Symbol()
export const CHANGE_SHOW_TAGS = Symbol()
export const CHANGE_SHOW_EQUIPS = Symbol()
export const CHANGE_SHOW_CHILDREN = Symbol()
export const CHANGE_TAR_ID = Symbol()
export const CHANGE_COPYTYPE = Symbol()
export const CHANGE_GRID = Symbol()
export const CHANGE_COPY_TYPE = Symbol()

export const CANVAS_MODE = Symbol()
export const CANVAS_READ_ONLY = Symbol()
export const CANVAS_SCALE = Symbol()
export const CANVAS_LEVELS = Symbol()

export const HIS_ENTITIES = Symbol()
export const HIS_TOP = Symbol()
export const HIS_BTM = Symbol()
export const HIS_EQUIPS = Symbol()
export const HIS_ARROWS = Symbol()

function reducer(state, action) {
  switch (action.type) {
      case CHANGE_SELECTED_LAYER:
        return {...state, selectedEntity: action.data}
      case CHANGE_SELECTED_EQUIP:
        return {...state, selectedEquip: action.data}
      case CHANGE_SELECTED_ARROW:
        return {...state, selectedArrow: action.data}
      case CHANGE_PARENT_LAYER:
        return {...state, editParentLayer: action.data}
      case CHANGE_SHOW_STRUCTOR:
        return {...state, isShowStructor: action.data}

      // 选中ID更新
      case CHANGE_SELECTED_IDS:
        return {...state, selectedIds: action.data}
      case CHANGE_SELECTED_EQUIP_IDS:
        return {...state, selectedEquipids: action.data} 

      case CHANGE_SELECTED_ARROW_IDS:
        return {...state, selectedArrowids: action.data}
      
      case CHANGE_SHOW_TAGS:
        return {...state, isShowTags: action.data}
      case CHANGE_SHOW_EQUIPS:
        return {...state, isShowEquips: action.data}
      case CHANGE_SHOW_CHILDREN:
        return {...state, isShowChildren: action.data}
      case CHANGE_TAR_ID:
        return {...state, copyTarId: action.data }
      case CHANGE_COPYTYPE:
        return {...state, copyType: action.data }
      case CHANGE_FILTERS:
        return {...state, filters: action.data}

      case CANVAS_MODE:
        return {...state, mode: action.data}
      case CANVAS_READ_ONLY:
        return {...state, readonly: action.data }
      case CANVAS_SCALE:
        return { ...state, scale: action.data }
      case CHANGE_GRID:
        return { ...state, isShowGrid: action.data }
      case CANVAS_LEVELS:
        return { ...state, showLevels: action.data }
      case HIS_ENTITIES:
        return {...state, entities: action.data}
      case HIS_EQUIPS:
        return {...state, equipments: action.data}
      case HIS_ARROWS:
        return {...state, arrows: action.data}
      case HIS_TOP:
        return { ...state, isTop: action.data}
      case HIS_BTM:
        return { ...state, isBtm: action.data}
      default:
        return state
  }
}

export default reducer
