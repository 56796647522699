import { cloneDeep } from 'lodash'

export class History {
  _stack = []
  _titles = []
  _index = 0
  _callback
  _storage
  _selectedIds = [] // 图层选中
  _equipSelectedIds = [] // 设备选中
  _arrowSelectedIds = [] // 箭头选中

  constructor(cb) {
    this._callback = cb
  }

  get entities() {
    try {
      return this._stack[this._index].entities
    } catch (error) {
      return {}
    }
  }

  get equipments() {
    try {
      return this._stack[this._index].equipments
    } catch (error) {
      return {}
    }
  }

  get arrows() {
    try {
      return this._stack[this._index].arrows
    } catch (error) {
      return {} 
    }
  }

  get root() {
    return this.entity('root') || null
  }

  get selectedEntity() {
    if (this._selectedIds.length === 1) {
      return this.entity(this._selectedIds[0]) || {}
    } else {
      return {}
    }
  }

  get equipSelectedEntity() {
    if (this._equipSelectedIds.length === 1) {
      return this.equip(this._equipSelectedIds[0]) || {}
    } else {
      return {}
    }
  }

  get arrowSelectedEntity() {
    if (this._arrowSelectedIds.length === 1) {
      return this.arrow(this._arrowSelectedIds[0]) || {}
    } else {
      return {}
    }
  }

  get title() {
    return this._titles[this._index]
  }

  get isTop() {
    return this._index + 1 >= this._stack.length
  }

  get isBottom() {
    return this._index <= 0
  }


  // 初始化
  init(entities, equipments, arrows) {
    this._stack = [{
      entities,
      equipments,
      arrows,
    }]
    this._index = 0
    // this._callback(this)
    return this
  }

  reset() {
    this._stack = [{
      entities: this.entities,
      equipments: this.equipments,
    }]
    this._index = 0
    this._callback(this)
    return this
  }

  callback(fun) {
    this._callback = fun
    return this
  }

  storage(fun) {
    this._storage = fun
    return this
  }

  entity(id) {
    try {
      return !id ? null : this.entities[id]
    } catch (error) {
      return null
    }
  }

  equip(id) {
    try {
      return !id ? null : this.equipments[id]
    } catch (error) {
      return null
    }
  }

  arrow(id) {
    try {
      return !id ? null : this.arrows[id]
    } catch (error) {
      return null
    }
  }

  select(ids) {
    this._equipSelectedIds = []
    this._arrowSelectedIds = []
    this._selectedIds = ids
    this._callback(this)
    return this
  }

  equipSelect(ids) {
    this._selectedIds = []
    this._arrowSelectedIds = []
    this._equipSelectedIds = ids
    this._callback(this)
    return this    
  }

  arrowSelect(ids) {
    this._selectedIds = []
    this._equipSelectedIds = []
    this._arrowSelectedIds = ids
    this._callback(this)
    return this
  }

  replace(entity, isEntity, title) {

    const newEntities = {
      ...this.entities
    }
    const newEquipments = {
      ...this.equipments
    }
    const newArrows = {
      ...this.arrows
    }
    const entities = Array.isArray(entity) ? entity : [entity]
    entities.forEach(e => {
      const isEquip = e.hasOwnProperty('asset_no')
      const isArrow = e.hasOwnProperty('ex') && e.hasOwnProperty('ey')
      isEquip ?
      newEquipments[e.id] = cloneDeep(e) :
      isArrow ?
      newArrows[e.id] = cloneDeep(e) :
      newEntities[e.id] = cloneDeep(e)
    })
    if (title) {
      this._stack.splice(this._index + 1, 10000, {
        entities: newEntities,
        equipments: newEquipments,
        arrows: newArrows,
      })
      this._index = this._stack.length - 1
      this._titles.splice(this._index + 1, 10000, title)
    } else {
      this._stack.splice(this._index, 1, {
        entities: newEntities,
        equipments: newEquipments,
        arrows: newArrows,
      })
      this._index = this._stack.length - 1
    }
    this._callback(this)
    this._storage(this)
    return this
  }

  remove(ids, notDeleteChildEquip) {
    const newEntities = {
      ...this.entities
    }
    const newEquipments = {
      ...this.equipments
    }
    const newArrows = {
      ...this.arrows
    }
    // const newObj = isEntity ? newEntities : newEquipments
    const self = this
    const mapList = (ids) => {
      for (let i = 0; i < ids.length; i++) {
          const id = ids[i]
          if (newEquipments[id]) {
              delete newEquipments[id]
          } else if (newEntities[id]) {
              const children = self.children(id, newEntities)
              mapList(children.map(o => o.id))

              const equips = self.equipChildren(id)
              if (equips && !notDeleteChildEquip) {
                equips.forEach(e=>{
                  delete newEquipments[e.id]
                })
              }

              const arrows = self.equipChildren(id)
              if (arrows && !notDeleteChildEquip) {
                arrows.forEach(e=>{
                  delete newArrows[e.id]
                })
              }

              delete newEntities[id]
        } else if (newArrows[id]) {
          delete newArrows[id]
        }
      }
    }
    mapList(ids)
    this._stack.splice(this._index + 1, 10000, {
      entities: newEntities,
      equipments: newEquipments,
      arrows: newArrows,
    })
    this._index = this._stack.length - 1
    this._titles.splice(this._index + 1, 10000, '删除ids')
    this._callback(this)
    this._storage(this)
    return this
  }

  undo() {
    if (this.isBottom) {
      throw new Error('到底啦')
    }
    this._index--
    this._callback(this)
    this._storage(this)
    return this
  }

  redo() {
    if (this.isTop) {
      throw new Error('到顶啦')
    }
    this._index++
    this._callback(this)
    this._storage(this)
    return this
  }

  tree(root = this.root, hasEquips) {
    if (!root || !root.id) return []
    const rootObj = cloneDeep(root)
    const newEntities = cloneDeep(this.entities)
    const self = this

    const mapChildren = (obj) => {
      const children = self.children(obj.id, newEntities)
      obj.children = children
      obj.children.forEach(c => {
        mapChildren(c)
      })
      // if (Number(obj.level) === 5 && hasEquips) {
      //   obj.children = [
      //     ...obj.children,
      //     ...self.equipChildren(obj.id)
      //   ]
      // }
      delete newEntities[obj.id]
    }
    mapChildren(rootObj)
    return [rootObj]
  }

  equipChildren(id) {
    if (!id) return []
    const equips = this.equipments
    const list = []
    for (let i in equips) {
      const entity = equips[i]
      if (entity && entity.pid === id) {
        list.push(entity)
      }
    }
    return list
  }

  arrowChildren(id) {
    if (!id) return []
    const arr = this.arrows
    const list = []
    for (let i in arr) {
      const arrow = arr[i]
      if (arrow && arrow.pid === id) {
        list.push(arrow)
      }
    }
    return list
  }

  children(id, entities) {
    if (!id) return []
    entities = entities || this.entities
    const list = []
    for (let i in entities) {
      const entity = entities[i]
      if (entity && entity.pid === id) {
        list.push(entity)
      }
    }
    return list
  }
}
