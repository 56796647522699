/* 
const channel = {
        1  : 'Working',
        2  : 'For Release',
        3  : 'Released',
    }
*/

import { useTranslation } from "react-i18next"
import { STATE_RELASING, STATE_WORKING } from './../utils/constant';
import { STATE_RELASED } from './../utils/constant';

export function useStatus() {
    const { t } = useTranslation()

    const options = [
        { label: 'Working', value: STATE_WORKING },
        { label: 'For Release', value: STATE_RELASING },
        { label: 'Released', value: STATE_RELASED },
    ]
    const map = new Map(options.map(o=>[o.value, o.label]))

    const approveOptions = [
        { label: t('common.res-approved'), value: STATE_RELASING },
        { label: t('common.res-rejected'), value: STATE_RELASED },
    ]

    return {
        options,
        map,
        approveOptions,
    }
}
