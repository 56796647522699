import { useEffect, useState } from "react"

export function useTagWS(history, parent, equip) {

    const [stations, setStations] = useState([])

    const onAllStations =()=>{
        const allEntities = history.entities
        return Object.values(allEntities).filter(o => String(o.level) === '6')
    }

    const onChildrenEquips =id=>{
        const station = history.entity(id)
        const children = history.children(id)

    }

    const onGetStationsByEquip =()=>{
        // const stations = onAllStations()
        if (!equip) return 
        const list = onStationsByEquipId(equip.id)
        // stations.forEach(o=>{
        //     const children = history.children(o.id)
        //     console.log(children)

        //     if (children.find(c=>c.equip===equip.id)) {
        //         list.push(o)
        //     }
        // })
        // console.log(list)
        setStations(list)
    }

    const onStationsByEquipId = id=>{
        const stations = onAllStations()
        const list = []
        stations.forEach(o=>{
            const children = history.children(o.id)

            if (children.find(c=>c.equip===id)) {
                list.push(o)
            }
        })
        return list
    }

    useEffect(()=>{
        onGetStationsByEquip()
    }, [parent, equip])

    return {
        stations,
        onAllStations,
        onGetStationsByEquip,
        onStationsByEquipId,
    }
}