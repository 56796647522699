import React, { useState, memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Divider, Tag, Form } from 'antd'
import Drawer from '@/components/layout/drawer'
import { CHANGE_SHOW_TAGS } from '@/reducers/editor'
import { useParams } from 'react-router-dom'
import TagsEquip from './equip'
import TagsWS from './ws'
import TagsBasic from './basic'
import TagsSize from './size'
import TagsText from './text'
import TagsFactor from './factor'

const layout = {
    labelCol: {
        span: 10,
    },
    wrapperCol: {
        span: 14,
    },
}

export default memo(function ({
    isShow,
    isEquip,
    isArrow,
    isNew,
    dispatch,
    layer,
    parent,
    history,
    width,
    readonly,
    onChangeLayer,
    onPlant,
    children,
}) {
    const params = useParams()

    const { t } = useTranslation()

    const [plant, setPlant] = useState('')

    const onInitPlant = () => {
        if (!isNew) {
            setPlant(params.plant)
        }
    }

    const onChangeTag =(k, v)=>{
        const newLayer = {
            ...layer,
            [k]: v
        }
        if (k === 'scene_id') {
            newLayer.cate_id = ''
        }
        onChangeLayer(newLayer)
    }

    useEffect(() => {
        onInitPlant()
    }, [params.plant])

    useEffect(()=>{
        if (isNew) {
            onPlant(plant)
        }
    }, [plant])

    return (
        <Drawer
            title={t('scene.editor.attr')}
            placement="right"
            closable={!isNew}
            open={isShow}
            width={width || 300}
            onClose={() => dispatch && dispatch({ type: CHANGE_SHOW_TAGS, data: false })}
        >
            <Form {...layout} size='small' className='editor-tags'>
                
                <TagsBasic 
                    isEquip={isEquip}
                    isArrow={isArrow}
                    isNew={isNew}
                    readonly={readonly}
                    layer={layer}
                    plant={plant}
                    setPlant={setPlant}
                    onChange={onChangeLayer}
                />

                {
                    !isEquip ? null :
                    <>
                        <TagsEquip 
                            assetNo={layer.asset_no} 
                            onChange={(v)=>onChangeTag('asset_no', v)}
                        />

                        <TagsWS 
                            history={history}
                            parent={parent}
                            layer={layer}
                            // wsIds={layer.ws_ids}
                            // wsDesc={layer.ws_desc}
                            // onChange={v=>onChangeTag('ws_ids', v)}
                            onDesc={v=>onChangeTag('ws_desc', v)}
                        />
                        
                        <TagsFactor
                            factorId={layer.factor_id}
                            plant={plant}
                            onChange={(v)=>onChangeTag('factor_id', v)}
                        />
                    </>
                }
                <TagsSize 
                    isNew={isNew}
                    isEquip={isEquip}
                    isArrow={isArrow}
                    readonly={readonly}
                    layer={layer}
                    parent={parent}
                    history={history}
                    onChange={onChangeLayer}
                />
                
                {
                    isEquip || isArrow ? null :
                    <>
                        <TagsText
                            readonly={readonly}
                            layer={layer}
                            onChange={onChangeLayer}
                        />
                    </>
                }
                {
                    children
                }
            </Form>
        </Drawer>
    )
})
