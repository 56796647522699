import { memo, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { SettingOutlined, ClearOutlined } from "@ant-design/icons"
import { Divider, Form, Modal, Tag } from "antd"
import Equip from '@/components/dicts/equip'
import { useSelector } from "react-redux"

export default memo(function TagsEquip({
    assetNo,
    onChange
}) {
    const { t } = useTranslation()
    const dicts = useSelector(state=>state.dicts)

    const [name, setName] = useState('')
    const [equipNo, setEquipNo] = useState('')
    const [pm, setPm] = useState('')

    const [visible, setVisible] = useState(false)

    const onComputed =()=>{
        if (!assetNo) {
            setName('NA')
            setEquipNo('NA')
            setPm('NA')
            return false
        }
        const { equips } = dicts
        const equipInfo = equips[assetNo]
        if (equipInfo) {
            setName(equipInfo.equip_name)
            setEquipNo(equipInfo.equip_no)
            setPm(equipInfo.equip_mgmt_id)
            return false
        } 
        // 根据ID请求
    }    

    const onSelect =(record)=>{
        
        onChange(record ? record.asset_id : '')
        setVisible(false)
    }

    useEffect(()=>{
        onComputed()
    }, [assetNo, dicts.equips])

    return (
        <>
            <Divider orientation='left' orientationMargin='10%'>
                <Tag color='blue'>{t('scene.editor.equip')}</Tag>
            </Divider>
            <Form.Item label={t('setting.equip.c-e-name')}>
                <h3 className='m-no-b w-per-100 p-r' style={{minHeight: 25}}>
                    {name}
                    <div className='f-s-c p-a-xr-yt m-t-5 '>
                        <ClearOutlined className='ease-300 c-p c-hover-app' onClick={()=>onSelect()} />
                        <SettingOutlined className="ease-300 c-p c-hover-app"  onClick={()=>setVisible(true)} />
                    </div>
                </h3>
            </Form.Item>
            <Form.Item label={t('setting.equip.c-e-no')}>
                <h3 className='m-no-b'>{equipNo}</h3>
            </Form.Item>
            <Form.Item label={t('setting.equip.c-pm')}>
                <h3 className='m-no-b'>{pm}</h3>
            </Form.Item>
            <Form.Item label={t('setting.equip.c-code')}>
                <h3 className='m-no-b'>{assetNo || 'NA'}</h3>
            </Form.Item>
            <Modal
                title={t('scene.editor.e-title')}
                open={visible}
                width={'90%'}
                footer={null}
                onCancel={()=>setVisible(false)}
            >
                <Equip 
                    modal={true}
                    onSelect={onSelect}
                />
            </Modal>
        </>
    )
})
