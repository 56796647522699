import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { loginAccount, logoutAccount } from '@/axios/axios.account'
import { USER_NAME, USER_PASSWORD, USER_TOKEN, USER_REMEMBER } from '@/utils/storage'
import { ACCOUNT_USERNAME, ACCOUNT_USERID, ACCOUNT_TOKEN, ACCOUNT_ROLE } from '@/store/account'
import { refreshToken } from '@/axios/axios.account'
import { ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_USER } from '@/utils/constant'
import { message } from 'antd'
import { USER_SUPER_ADMIN, USER_ADMIN } from '@/utils/constant'

export function useLogin(isLoining = false) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [logining, setLogining] = useState(isLoining)

  const onLogin = ({ account, password, remember }) => {
    return new Promise((resolve, reject) => {
      setLogining(true)

      loginAccount({
        account,
        password,
      })
        .then((res) => {
          localStorage.setItem(USER_NAME, account)
          localStorage.setItem(USER_PASSWORD, password)
          localStorage.setItem(USER_REMEMBER, Number(remember))
          localStorage.setItem(USER_TOKEN, res.token)

          onCache(res)
          resolve()
        })
        .catch(() => {
          reject()
        })
        .finally(() => {
          setLogining(false)
        })
    })
  }

  const onLogout =()=>{
    const cls = message.loading(t('login.outing'))
    logoutAccount()
      .then(res=>{
        localStorage.removeItem(USER_TOKEN)
        window.location.reload()
      })
      .finally(()=>{
        cls()
      })
  }

  const onRefresh =()=>{
    setLogining(true)

    refreshToken()
      .then(res=>{
        localStorage.setItem(USER_TOKEN, res.token)
        onCache(res)
      })
      .finally(()=>{
        setLogining(false)
      })
  }

  const onCache = (res) => {
    dispatch({
      type: ACCOUNT_USERNAME,
      data: res.user_name
    })
    dispatch({
      type: ACCOUNT_TOKEN,
      data: res.token
    })
    dispatch({
      type: ACCOUNT_USERID,
      data: res.user_id,
    })
    console.log(res.role, Number(res.role) === USER_SUPER_ADMIN)
    dispatch({
      type: ACCOUNT_ROLE,
      data: Number(res.role) === USER_ADMIN ? 
        ROLE_ADMIN :
        Number(res.role) === USER_SUPER_ADMIN ? 
        ROLE_SUPER_ADMIN : ROLE_USER
    })

    setLogining(false)
  }

  return {
    logining,
    onLogin,
    onLogout,
    onCache,
    onRefresh,
  }
}