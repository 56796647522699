import { useState } from 'react'
// import { useSelector } from 'react-redux'
import { Row, Col, Divider, Spin, Input, Modal, Button, Table, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { useElement } from '@/hooks/element'
import { SearchOutlined, PlusOutlined } from '@ant-design/icons'

import { delCategory, addCategory } from '@/axios/axios.dict'
import DictFactor from '@/components/dicts/factor'

const layout = {
  labelCol: {
    span: 7,
  },
  wrapperCol: {
    span: 17,
  },
}

const validateMessages = {
  // eslint-disable-next-line no-template-curly-in-string
  required: '${label} is required!',
  types: {
    // eslint-disable-next-line no-template-curly-in-string
    email: '${label} is not a valid email!',
    // eslint-disable-next-line no-template-curly-in-string
    number: '${label} is not a valid number!',
  },
  number: {
    // eslint-disable-next-line no-template-curly-in-string
    range: '${label} must be between ${min} and ${max}',
  },
}

export default function Factor() {
  // const account = useSelector(state => state.account)
  const { t } = useTranslation()
  const { categorys, onGetAllCates, loadingCate } = useElement()
  const [type, setType] = useState('')
  const [saving, setSaving] = useState(false)
  const [form] = Form.useForm()


  const columnsCates = [
    { title: t('scene.element.ele-name'), dataIndex: 'name' },
    { title: t('common.action'), render(record){ 
      return <section className='f-s-c'>
        <Button type='link' danger onClick={()=>onDeleteCate(record)}>{t('common.delete')}</Button>
      </section>
    }}
  ]

  const onModal =(record, isEdit)=>{
    window.requestIdleCallback(()=>{
      form.setFieldsValue(isEdit ? record : { 
        plant: '',
        name: '',
        code: '',
        abb: ''
      })
    })
    setType(isEdit ? 'edit' : 'add')
  }

  const onClose =()=>{
    setType('')
  }

  const onSave =async (arg)=>{
    setSaving(true)
    try {
      await addCategory(arg)
      setSaving(false)
      onClose()
      onGetAllCates()
    } catch (error) {
      setSaving(false) 
    }
    
  }

  const onDeleteCate =(record)=>{
    Modal.confirm({
      title: t('common.notice'),
      content: t('common.notice-del'),
      onOk: ()=>{
        return new Promise(async (resolve, reject)=>{
          try {
            await delCategory(record.id)
            onGetAllCates()
            resolve()
          } catch (error) {
            reject()
          }
        })
      }
    })
  }

  
  return (
    <article className='o-h h-per-100 w-per-100'>
      <Row className='h-per-100' gutter={25}>
        <DictFactor 
          span={19}
          categorys={categorys}
        />
        <Col span={5} className='o-h h-per-100 f-column'>
          <Divider orientation="left">{t('scene.element.ele-cate')}</Divider>
          <header className='f-s-c p-b-10'>
            <Button type='primary' ghost icon={<SearchOutlined />} className='m-r-20' onClick={onGetAllCates}>{t("common.search")}</Button>
            <Button type='primary'  icon={<PlusOutlined />} onClick={onModal}>{t("common.add")}</Button>
          </header>
          <section className='f-1 o-a'>
            <Table
              size='small'
              rowKey='id'
              pagination={false}
              loading={loadingCate}
              columns={columnsCates}
              dataSource={categorys}
            ></Table>
          </section>
        </Col>
      </Row>
      <Modal
        title={t("scene.element.title")}
        width={590}
        open={!!type}
        footer={null}
        onCancel={onClose}
      >
        <Spin spinning={saving}>
          <Form {...layout} name="nest-messages" className='p-t-20' form={form} onFinish={onSave} validateMessages={validateMessages}>
            <Form.Item
              name={['name']}
              label={t("scene.element.cate-name")}
              rules={[
                { required: true },
              ]}
            >
              <Input disabled={type==='edit'} />
            </Form.Item>
            
            <Form.Item
              wrapperCol={{
                ...layout.wrapperCol,
                offset: 7,
              }}
            >
              <Button type="primary" htmlType="submit" loading={saving}>
                {t("common.save")}
              </Button>
              <Button danger ghost htmlType="reset" className='m-lr-15'>
                {t("common.reset")}
              </Button>
              <Button type='primary' danger onClick={onClose}>
                {t("common.cancel")}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </article>
  )
}