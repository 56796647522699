export const formData = (data) => {
  let arr = []
  for (let name in data) {
    arr.push(encodeURIComponent(name) + '=' + encodeURIComponent(data[name]))
  } arr.push(('v=' + Math.random()).replace('.', ''))
  return arr.join('&')
};

export const toBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

export const getUuid = () => {
  var s = [];
  var hexDigits = "0123456789abcdef";
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "-";

  var uuid = s.join("");
  return uuid;
};

export const getUuidByNum = (len, radix) => {
    var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('')
    let uuid = []
    let i
    radix = radix || chars.length
    if (len) {
      for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix]
    } else {
      let r
      uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-'
      uuid[14] = '4'
      for (i = 0; i < 36; i++) {
        if (!uuid[i]) {
          r = 0 | Math.random() * 16
          uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r]
        }
      }
    }
    return uuid.join('')
}

export const copyText=(text)=> {
  var textarea = document.createElement("textarea");
  var currentFocus = document.activeElement;
  document.body.appendChild(textarea);
  textarea.value = text;
  textarea.focus();
  if (textarea.setSelectionRange)
    textarea.setSelectionRange(0, textarea.value.length);
  else
    textarea.select();
  try {
    var flag = document.execCommand("copy");
  } catch (eo) {
    var flag = false;
  }
  document.body.removeChild(textarea);
  currentFocus.focus();
  return flag;
}

export const getVersion =o=>{
  return `V${o.version_major}.${o.version_minor}`
}

export const formatterNumber = v=>{
  if (!String(v).includes('.')) return v
  const num = Number(v) || 0
  return num.toFixed(2)
}
export function toFixed(num, fix=2) {
  if (String(num).includes('.')) {
      return Number(num).toFixed(fix)
  } else {
      return num
  }
}
