import { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useTags } from '@/hooks/editor/tags'
import { CHANGE_PARENT_LAYER, CANVAS_MODE } from '@/reducers/editor'
import { message } from 'antd'
import { useGeo } from './geo'
import { TAG_TEXT_ATTR } from '@/utils/storage'
import { MODE_BROWSE, MODE_DRAW } from '../../utils/constant'

export function useDraw(dispatch, history, parent, mode, scale, selectedEntity) {
  const { t } = useTranslation()
  const { isBrotherCross, isPointInBrother, isPointInPolygon, isContained } = useGeo(history, parent)
  const [isDrawing, setIsDrawing] = useState(false)
  const { tags, onGetNewCell, onGetNewEquip } = useTags(parent)
  const [text, setText] = useState('')
  const [startLoc, setStartLoc] = useState([0,0])
  const [endLoc, setEndLoc] = useState([0,0])
  const [path, setPath] = useState('')
  const prevTags = useRef({})

  const onClick = (e)=>{
    if (mode !== MODE_DRAW) return
    if (parent.level >= 5) {
      onAddPoint(e)
      return false
    }
    if (isDrawing) {
      onEnd(e)  
    } else {
      onStart(e)
    }
  }

  const onAddPoint =e=>{
    const loc = onGetLoc(e)
    if (parent.level && parent.id && !isPointInPolygon(loc, parent)) {
      message.error(t("scene.editor.draw-t-no-equip"))
      return false
    }
    const newPoint = onGetNewEquip(loc)
    history.replace(newPoint, false, 'add-point')
    setIsDrawing(false)
  }

  const onStart =e=>{
    const loc = onGetLoc(e)

    if (parent.level && parent.id && !isPointInPolygon(loc, parent)) {
      message.error(t("scene.editor.draw-t-no-poly"))
      return false
    }
    if (isPointInBrother(loc, parent)) {
      message.error(t('scene.editor.draw-t-no-brother'))
      return false
    }
    setStartLoc(loc)
    setEndLoc(loc)
    setIsDrawing(true)
  }

  const onEnd =e=>{
    if (!isDrawing) return false
   
    let newCell = onGetNewCell(startLoc, endLoc)

    if (Number(newCell.level) === 1) {
      newCell.id = 'root'
      setIsDrawing(false)
      history.replace(newCell, true, 'init-graph')
      dispatch({ type: CHANGE_PARENT_LAYER, data: newCell })
      dispatch({ type: CANVAS_MODE, data: MODE_BROWSE })
      dispatch({ type: CHANGE_PARENT_LAYER, data: newCell })
      message.success(t('scene.editor.init-sus'))
    } else {
      if (!isContained(parent, newCell)) {
        message.error(t("scene.editor.draw-t-no-parent"))
        return false
      }
      
      if (isBrotherCross(newCell, parent)) {
        message.error(t('scene.editor.draw-t-no-cross'))
        return false
      }
      let overlaTags
      try {
        overlaTags = JSON.parse(sessionStorage.getItem(TAG_TEXT_ATTR))
      } catch (error) {
        overlaTags = {}
      }
      console.log(overlaTags)
      newCell = {
        ...newCell,
        ...overlaTags,
        // ...prevTags.current
      }

      history.replace(newCell, true, 'add-layer')
      setIsDrawing(false)
    }
  }

  const onMouseMove =e=>{
    if (mode !== MODE_DRAW) return
    if (!isDrawing) return
    const loc = onGetLoc(e)

    setEndLoc(loc)
  }

  const onGetPath =()=>{
    // const [sx, sy] = startLoc
    // const [ex, ey] = endLoc
    const sx = (startLoc[0] - Number(parent.x) || 0) * scale
    const sy = (startLoc[1] - Number(parent.y) || 0) * scale
    const ex = (endLoc[0] - Number(parent.x) || 0) * scale
    const ey = (endLoc[1] - Number(parent.y) || 0) * scale
    setPath(`M ${sx} ${sy} L ${ex} ${sy} L ${ex} ${ey} L ${sx} ${ey} L ${sx} ${sy} Z`)
  }

  const onGetLoc =e=>{
    const event = e.nativeEvent
    const loc = [event.offsetX, event.offsetY]
    return onLevelLoc(loc)
  }

  const onLevelLoc=loc=>{
    const pX = Number(parent.x)
    const pY = Number(parent.y)

    return [(loc[0] / scale) + pX, (loc[1] / scale) + pY]
  }

  useEffect(()=>{
    setText(isDrawing ? t('scene.editor.draw-start') : t('scene.editor.draw-move'))
  }, [isDrawing])

  useEffect(()=>{
    onGetPath()
  }, [startLoc, endLoc])

  useEffect(()=>{
    setStartLoc([0,0])
    setEndLoc([0,0])
    setIsDrawing(false)
  }, [mode])

  useEffect(()=>{
    if (selectedEntity && selectedEntity.id) {
      prevTags.current = {
        text_fill: selectedEntity.text_fill,
        text_size: selectedEntity.text_size,
        text_x: selectedEntity.text_x, 
        text_y: selectedEntity.text_y,
      }
    }
  }, [selectedEntity])

  return {
    isDrawing,
    text,
    path,
    onClick,
    onMouseMove,
  }
}