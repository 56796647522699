import axios from './axios';

/**
   * 登录
*/
export const loginAccount = data =>{
  return axios({
    method: 'post',
    url: '/login',
    data
  })
};

export const logoutAccount = params =>{
  return axios({
    method: 'get',
    url: '/logout',
    params
  })
};

export const refreshToken = params =>{
  return axios({
    method: 'get',
    url: '/refresh',
    params
  })
};

