import { TREE_DOM } from '@/utils/storage'
import { useParams } from 'react-router-dom'
import { useXML } from './xml'
import { useEffect, useState } from 'react'

export function useStorage(history, parent) {
    const params = useParams()
    const { onCreateLayoutXML } = useXML(history, parent)
    const [cache, setCache] = useState('')

    const clear = () => {
        for (let i in window.localStorage) {
            if (i.startsWith(TREE_DOM)) {
                localStorage.removeItem(i)
            }
        }
    }

    const getKey =()=>{
        const { id, plant } = params
        return `${TREE_DOM}_${plant}_${id}`
    }

    const setLayout = () => {
        try {
            const dom = onCreateLayoutXML()
            const xml = document.createElement('xml')
            xml.appendChild(dom)
            const content = xml.innerHTML
            localStorage.setItem(getKey(), content)
        } catch (error) {
            removeLayout()
        }
    }

    const removeLayout = () => {
        localStorage.removeItem(getKey())
    }

    const getLayout =()=>{
        return localStorage.getItem(getKey())
    }

    useEffect(()=>{
        setCache(!!getLayout())
    })

    return {
        cache,
        clear,
        getLayout,
        setLayout,
        removeLayout,
    }
}
