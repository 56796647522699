import SceneList from '@/views/scene/list'
import SceneDictsType from '@/views/scene/dicts/type'
import SceneDictsFactor from '@/views/scene/dicts/factor'
import SceneEditor from '@/views/scene/editor/index'
import SceneNew from '@/views/scene/new'

import SettingPlant from '@/views/setting/plant'
import SettingStructure from '@/views/setting/structure'
import SettingUser from '@/views/setting/user'
import SettingStation from '@/views/setting/station'
import SettingCategory from '@/views/setting/category'
// import SettingElement from '@/views/setting/element'
// import SettingEquipment from '@/views/setting/equipment'


import SystemList from '@/views/system/list'
import SystemEquips from '@/views/system/equipment'

import TaskList from '@/views/task/list'

import { ROLE_ADMIN, ROLE_USER, ROLE_SUPER_ADMIN } from '@/utils/constant'

const routes = [
  {
    path: '/scene/list',
    element: <SceneList></SceneList>,
    parts: [ROLE_ADMIN, ROLE_USER, ROLE_SUPER_ADMIN],
    showMenu: true,
    sort: 1,
    parent: '/scene'
  },
  {
    path: '/scene/dicts/type',
    element: <SceneDictsType></SceneDictsType>,
    parts: [ROLE_ADMIN, ROLE_USER, ROLE_SUPER_ADMIN],
  },
  {
    path: '/scene/dicts/factor',
    element: <SceneDictsFactor></SceneDictsFactor>,
    parts: [ROLE_ADMIN, ROLE_USER, ROLE_SUPER_ADMIN],
  },
  {
    path: '/scene/editor/new',
    element: <SceneNew></SceneNew>,
    parts: [ROLE_ADMIN, ROLE_USER, ROLE_SUPER_ADMIN],
  },
  {
// 
// path: '/scene/editor/:plant/:type/:id/:version',
    path: '/scene/editor/:plant/:type/:id/:design/:version',
    element: <SceneEditor></SceneEditor>,
    parts: [ROLE_ADMIN, ROLE_USER, ROLE_SUPER_ADMIN],
  },

  {
    path: '/setting/plant',
    element: <SettingPlant></SettingPlant>,
    parts: [ROLE_ADMIN, ROLE_USER, ROLE_SUPER_ADMIN],
  },
  {
    path: '/setting/structure',
    element: <SettingStructure></SettingStructure>,
    parts: [ROLE_ADMIN, ROLE_USER, ROLE_SUPER_ADMIN],
  },
  // {
  //   path: '/setting/equipment',
  //   element: <SettingEquipment></SettingEquipment>,
  //   parts: [ROLE_ADMIN, ROLE_USER],
  // },
  {
    path: '/setting/user',
    element: <SettingUser></SettingUser>,
    parts: [ROLE_ADMIN, ROLE_USER, ROLE_SUPER_ADMIN],
    sort: 3,
    showMenu: true,
    parent: '/setting'
  },
  {
    path: '/setting/ws',
    element: <SettingStation></SettingStation>,
    parts: [ROLE_ADMIN, ROLE_USER, ROLE_SUPER_ADMIN],
  },
  {
    path: '/setting/category',
    element: <SettingCategory></SettingCategory>,
    parts: [ROLE_ADMIN, ROLE_USER, ROLE_SUPER_ADMIN],
  },
  // {
  //   path: '/setting/element',
  //   element: <SettingElement></SettingElement>,
  //   parts: [ROLE_ADMIN, ROLE_USER],
  // },
  

  {
    path: '/system/equips',
    element: <SystemEquips></SystemEquips>,
    parts: [ROLE_ADMIN, ROLE_USER, ROLE_SUPER_ADMIN],
    sort: 4,
    showMenu: true,
    parent: '/system'
  },
  {
    path: '/task/list',
    element: <TaskList></TaskList>,
    parts: [ROLE_ADMIN, ROLE_USER, ROLE_SUPER_ADMIN],
    sort: 2,
    showMenu: true,
    parent: '/task'
  },
  
]

export default routes