import { memo, useState } from "react"
import { Switch } from "antd"
import { toggleVisible } from '@/axios/axios.scene'

export default memo(function({
    onReload,
    scene,
}) {
    const [loading, setLoading] = useState(false)
    const onToggleVisible =(v)=>{
        setLoading(true)
        toggleVisible(scene.id, v)
            .then(res=>{
                onReload()
            })
            .finally(()=>{
                setLoading(false)
            })
    }
    return (
        <Switch size="small" loading={loading} checked={scene.visitable} onChange={onToggleVisible} />
    )
})
