import axios from 'axios'
import { message } from 'antd'
import { USER_TOKEN } from '@/utils/storage'
import { formData as transformRequest } from '@/utils/util'

const http = axios.create({
  method: 'post',
  timeout: 100000,
  baseURL: process.env.REACT_APP_API,
})

const token = localStorage[USER_TOKEN]


http.interceptors.request.use(
  (config) => {
    config.headers.authorization = token
    if (config.url.includes('www=1')) {
      config.transformRequest = transformRequest
    } return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

http.interceptors.response.use(
  (res) => {
    const code = Number(res.data.code)
    if (res.config.responseType === 'blob') return res.data

    if (code === 0) {
      return Promise.resolve(res.data.data)
    } else if (code === 401) {
      localStorage.removeItem(USER_TOKEN)
      window.location.reload()
      return Promise.reject(res.data)
    } else {
      message.error(res.data.msg || 'Network Error')
      return Promise.reject(res.data)
    }
  },
  (err) => {
    message.error(err.message)
    if (err.response?.status === 401) {
      localStorage.token = ''
      localStorage.removeItem(USER_TOKEN)
      window.location.reload()
    }
    return Promise.reject(err)
  },
)

export const CancelToken = axios.CancelToken

export const commonParams = {
  clientSystem: 'web',
  locale: 'en_us'
}

export default http