const initState = {
    name: '',
    flag: '',
    loading: false,
    list: [],
    stamp: 0,
}

export const SCENE_NAME = Symbol()
export const SCENE_FLAG = Symbol()
export const SCENE_LOADING = Symbol()
export const SCENE_LIST = Symbol()
export const SCENE_STAMP = Symbol()

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initState, action) {
    switch (action.type) {
        case SCENE_NAME:
            return Object.assign({}, state, { name: action.data });
        case SCENE_FLAG:
            return Object.assign({}, state, { flag: action.data });
        case SCENE_LOADING:
            return Object.assign({}, state, { loading: action.data });
        case SCENE_LIST:
            return Object.assign({}, state, { list: action.data });
        case SCENE_STAMP:
            return Object.assign({}, state, { stamp: Date.now() });
        default:
            return state;
    }
}