import { useRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Form, Tooltip, Input, Tag, Spin } from 'antd'
import { PlusOutlined, ReloadOutlined } from '@ant-design/icons'
import { getScenes } from '@/axios/axios.scene'
// import { useLayState } from '@/hooks/state'
import { usePlant } from '@/hooks/plant'
import Visible from './visible'
import Actions from './actions'
import { ROLE_ADMIN } from '@/utils/constant'
import { SCENE_STAMP } from '../../../store/scene'
import Preview from './preview'

export default function Editor() {
  const { t } = useTranslation()
  // const userRef = useRef(false)
  const dispatch = useDispatch()

  const account = useSelector(state => state.account)
  const { list, loading } = useSelector(state=>state.scene)

  const { plantMap } = usePlant()
  // const [ scenes, setScenes ] = useState([])
  const [ name, setName ] = useState('')
  const [ flag, setFlag ] = useState(false)
  const [previewScene, setPrevSce] = useState(null)
  // const [ loading, setloading] = useState(false)
  

  // const onGetScenes =()=>{
  //   setloading(true)
  //   getScenes({
  //     name,
  //   })
  //   .then(res=>{
  //     setScenes(res.map(o=>{
  //       return {
  //         ...o,
  //         isSelf: o.creator === account.userId,
  //       }
  //     }))
  //   })
  //   .finally(()=>{
  //     setloading(false)
  //   })
  // }

  // const onReset = ()=>{
  //   setName(()=>'')
  //   setFlag(true)
  //   // onGetScenes()
  // }
  

  // useEffect(()=>{
  //   if (!userRef.current) {
  //     onGetScenes()
  //     userRef.current = true
  //   }
  // })

  // useEffect(()=>{
  //   if (flag) {
  //     onGetScenes()
  //     setFlag(false)
  //   }
  // }, [flag])

  const onReload = () => {
    dispatch({ type: SCENE_STAMP })
  }

  const onPreview = (scene) => {
    // previewScene.current = scene
    setPrevSce(scene)
  }
  
  return (
    <article className='d-f o-x-h'>
      {/* <header className='f-b-c p-b-10'>
        <Form layout='inline'>
          <Form.Item label={t('scene.layout.name')}>
            <Input.Search value={name} placeholder={t('scene.layout.name')} enterButton onChange={e=>setName(e.target.value)} onSearch={onGetScenes} />
          </Form.Item>
          <Button type='primary' danger icon={<ReloadOutlined />} className='m-l-20' onClick={onReset}>{t("common.reset")}</Button>

        </Form>
        <Link to={'/scene/editor/new'}>
          <Button type='primary' ghost icon={<PlusOutlined />} className='p-lr-25'>{t('scene.layout.add')}</Button>
        </Link>
      </header> */}
    
      <div className='f-1 o-y-a'>
        <Spin spinning={loading}>
          <ul className='d-f f-w-w f-1 p-lr-10' style={{minHeight: 300}}>
            {
              list.map(o=>(
                <li
                  key={o.id}
                  className='p-r f-column w-300 m-t-10 m-b-20 m-r-10 ease-400'
                  
                >
                  <header className='w-per-100 f-b-c p-b-5'>
                    <Actions onReload={onReload} scene={o} />
                    <section>
                      {
                        account.role === ROLE_ADMIN || o.isSelf ?
                        <Visible onReload={onReload} scene={o}></Visible> :
                        null
                      }
                    </section>
                  </header>
                  <main className={`p-r f-c w-per-100 h-200 b-eee b-r-4 ease-400 c-p ${ previewScene&&previewScene.id === o.id ? " shadow-app " : 'shadow-scene' }`} onClick={()=>onPreview(o)}>
                    <svg t="1686378482803" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2595" width="100" height="100">
                      <path d="M1023.1 443.2H778.8l-36.8-135-286.4 135h-65.3l-36.8-135-298.6 135H1v169.9h1022.1V443.2zM339.1 0H171.4v353.3l167.7-75.8V0z m376.1 0H547.7v363.5l167.5-86.1V0zM0 649.9V1024h1024V649.9H0z m198.1 233.5H114c-4.4 0-8-3.6-8-8v-154c0-4.4 3.6-8 8-8h84.2c4.4 0 8 3.6 8 8v153.9c-0.1 4.5-3.7 8.1-8.1 8.1z m200.5 0h-84.2c-4.4 0-8-3.6-8-8v-154c0-4.4 3.6-8 8-8h84.2c4.4 0 8 3.6 8 8v153.9c0 4.5-3.6 8.1-8 8.1z m201.3 0h-84.2c-4.4 0-8-3.6-8-8v-154c0-4.4 3.6-8 8-8H600c4.4 0 8 3.6 8 8l-0.1 153.9c0 4.5-3.6 8.1-8 8.1z" p-id="2596" fill="#1296db"></path>
                    </svg>
                    <div className='w-per-100 h-per-100 p-a-c f-column f-c p-t-20' style={{background: 'rgba(255,255,255,0.5)'}}>
                      {
                        o.visitable ?
                        <Link to={`/scene/editor/${o.plant}/read/${o.id}/${o.design_id}/latest`}>
                          <Button type='primary' ghost className='w-200 bg-color-fff'>{t("common.watch")}</Button>
                        </Link> :
                        null
                      }
                      {
                        // (Number(o.state) === 1||Number(o.state) === 3)&&o.isSelf&&o.visitable ?
                        (Number(o.state) === 1||Number(o.state) === 3)&&o.editable ?
                        <Link to={`/scene/editor/${o.plant}/edit/${o.id}/${o.design_id}/latest`}>
                          <Button type='primary' className='w-200 m-t-20'>{t("common.edit")}</Button>
                        </Link> :
                        null
                      }
                    </div>
                    <h3 className='p-a-xr-yt p-5 f-e-c'>
                      {
                        plantMap.has(o.plant) ?
                        <Tag color='blue'>{plantMap.get(o.plant).plant}</Tag> : 
                        null
                      }
                    </h3>
                    <h3 className='p-a-xr-yb w-per-100 f-s-c h-35 bg-color-f0f0f0 c-app p-lr-15'>
                      <Tooltip title={o.name}>
                        <span style={{color: '#607D8B'}} className='white-1'>                     
                      {o.name}
                        </span>
                      </Tooltip>
                    </h3>
                  </main>
                </li>
              ))
            }
          </ul>
        </Spin>
      </div>
      <Preview scene={previewScene} plantMap={plantMap} />
    </article>
  )
}