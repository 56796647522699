import { LinkOutlined } from "@ant-design/icons"
import { Modal, Table, Tooltip, Button, Upload } from "antd"
import { memo, useState } from "react"
import { useTranslation } from "react-i18next"
import { addAttach, getAttachs, deleteAttach} from '@/axios/axios.scene'
import { uploadFile } from '@/utils/file'

export default memo(function({
    onReload,
    scene,
}) {
    const { t } = useTranslation()

    const [visible, setVisible] = useState(false)
    const [adding, setAdding] = useState(false)
    const [loading, setLoading] = useState(false)
    const [list, setList] = useState([])

    const columns = [
        { title: t('common.name'), dataIndex: 'filename' },
        { title: 'action', render(text, record) {
            return  (
                <div className="f-s-c">
                    <Button type='link' target='__blank' href={`${window.location.origin}${window.location.pathname==='/'?'':window.location.pathname}${record.path}`}>{t('common.preview')}</Button>
                    <Button type='link' danger onClick={()=>onDelete(record)}>{t('common.delete')}</Button>
                </div>
            )
        } },
    ]

    const onDelete=record=>{
        setLoading(true)
        deleteAttach(scene.id, record.id)
            .then(()=>{
                onGetAttachs()
            })
            .catch(()=>{
                setLoading(false)
            })
    }

    const onGetAttachs=()=>{
        setLoading(true)
        getAttachs(scene.id)
            .then(res=>{
                setList(res)
            })
            .catch(()=>{
                setList([])
            })
            .finally(()=>{
                setLoading(false)
            })
    }

    const onClose =()=>{
        setVisible(false)
    }

    const onShowUsers =()=>{
        setVisible(true)
        onGetAttachs()
    }

    const onUpload =({file})=>{
        setAdding(true)
        uploadFile(file, `/layout/${scene.id}/attachmentfile`, {

        })
        .then(async res=>{

            await addAttach(scene.id, {
                plant_layout_id: scene.id,
                plant: scene.plant,
                filename: file.name,
                path: res,
            })
            .then(res=>{
                onGetAttachs()
            })
        })
        .finally(()=>{
            setAdding(false)
        })            
    }

    return (
        <>
            <Tooltip title={t('common.attach')}>
                <LinkOutlined  
                    className={`c-p c-hover-app c-666 ease-400  m-r-10 ${scene.attachment_count > 0 ? 'c-red' : ''}`}
                    onClick={onShowUsers}/>
            </Tooltip>
            <Modal
                title={t('scene.layout.attach-title')}
                open={visible}
                onCancel={onClose}
                onOk={onClose}
            >
                <header className="f-e-c p-b-15">
                    {
                        scene.isSelf ?
                        <Upload multiple={false} showUploadList={false} disabled={adding} customRequest={onUpload}>
                            <Button loading={adding} className='w-150'>{t('scene.layout.upload-file')}</Button>
                        </Upload> :
                        null
                    }
                </header>
                <Table
                    size='small'
                    loading={loading}
                    dataSource={list}
                    columns={columns}
                    rowKey={'id'}
                    scroll={{y: 500}}
                ></Table>
            </Modal>
        </>
    )
})
