import { useState, memo, useEffect } from 'react'
// import { useSelector } from 'react-redux'
import { Menu  } from 'antd'
import { useTranslation } from 'react-i18next'
import { RadarChartOutlined, ClusterOutlined, PartitionOutlined, ApiOutlined, BarcodeOutlined, TeamOutlined, ShopOutlined, ConsoleSqlOutlined, OrderedListOutlined } from '@ant-design/icons'
import { useNavigate, useLocation } from 'react-router-dom' 
import Scene from './scene'
import useLang from '@/hooks/lang'

export default memo(function() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { name } = useLang();

  // const account = useSelector(state => state.account)
  const [openKeys, setOpenKeys] = useState(['routes.scene-dicts'])
  const [selectedKeys, setSelectedKeys] = useState([])
  const [items, setItems]= useState([])
  const [rootSubmenuKeys, setRootSubmenuKeys] = useState([])
  const [isHideNav, setIsHideNav] = useState(false)

  const onGetItem =(key, icon, children, isFirst, isScene, className)=> {
    const labelKey = `${key}${isFirst?"-index":""}`
    return {
      key,
      icon,
      children,
      // disabled: children?.length > 0,
      className:  className || `nav-`,
      label: isScene ? <Scene label={ t(labelKey) }/> : t(labelKey),
    }
  }

  const onSelect =({key})=> {
    setSelectedKeys([key])
    navigate(key.replace('routes.', '-').replaceAll('-', '/'))
  }

  // const onOpenChange=(keys)=> {
  //   const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1)
  //   if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
  //     setOpenKeys(keys)
  //   } else {
  //     setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
  //   }
  // }

  const onInitOpenKeys = () => {
    const keys = []
    const deepMenus = (arr) => {
      arr.forEach(o => {
        if (o.children?.length > 0) {
          keys.push(o.key)
          deepMenus(o.children)
        }
      })
    }
    deepMenus(items)
    setOpenKeys(keys)
  }

  const onGenerateItems =()=>{
    const pathname = location.pathname
    let newItems = [], newRootSubKeys = []
    if (pathname.startsWith('/scene')) {
      newItems = [
        onGetItem('routes.scene-list', <RadarChartOutlined />, null, false, true, 'nav-scene disabled'),
        onGetItem('routes.scene-dicts', <ClusterOutlined />, [
          onGetItem('routes.scene-dicts-type'),
          onGetItem('routes.scene-dicts-factor'),
        ], false, false, 'disabled'),
      ]
      newRootSubKeys = ['routes.scene-list', 'routes.scene-dicts']
    } else if (pathname.startsWith('/setting')) {
      newItems = [
        onGetItem('routes.setting-user', <TeamOutlined />, null, true),
        onGetItem('routes.setting-structure', <PartitionOutlined />),
        onGetItem('routes.setting-plant', <ShopOutlined />),
        onGetItem('routes.setting-ws', <ConsoleSqlOutlined />),
        onGetItem('routes.setting-category', <OrderedListOutlined />),
        // onGetItem('routes.setting-equipment',  <ApiOutlined />),
      ]
      newRootSubKeys= []
    } else if (pathname.startsWith('/system')) {
      newItems = [
        onGetItem('routes.system-equips', <ApiOutlined />, null, true),
      ]
      newRootSubKeys= []
    }
    setItems(newItems)
    setRootSubmenuKeys(newRootSubKeys)
    setSelectedKeys(['routes.'+location.pathname.slice(1).replaceAll('/', '-')])
  }

  const onToggleHide =()=>{
    const routesFix = [ '/scene/editor' ]
    const pathname = location.pathname
    let flag = false
    for (let i=0; i<routesFix.length; i++) {
      const route = routesFix[i]
      if (pathname.startsWith(route)) {
        flag = true
        break
      }
    }
    setIsHideNav(flag)
  }

  useEffect(() => {
    onGenerateItems()
    onToggleHide()
  }, [location.pathname])

  useEffect(() => {
    onGenerateItems()
  }, [name])

  useEffect(() => {
    onInitOpenKeys()
  }, [items])

  return (
    (
      isHideNav || !items || items.length===0 ? null :
      <article className={'w-250 bg-color-f2f2f2  animated fadeInLeft'}>
        <Menu
          mode="inline"
          openKeys={openKeys}
          selectedKeys={selectedKeys}
          style={{ width: "100%", borderRight: 'none' }}
          items={items}
          // onOpenChange={onOpenChange}
          onSelect={onSelect}
        />
      </article>
    )
  )
})