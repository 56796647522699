import { useState, useEffect, useRef } from 'react'
import { getStationList } from '@/axios/axios.station'
import { DICT_STATIONS } from '@/store/dicts'
import { useDispatch, useSelector } from 'react-redux'

export function useStation(isDisFetch) {
  const dispatch = useDispatch()

  const dicts = useSelector(state=>state.dicts)

  const [stationMap, setMap] = useState(new Map())
  const [loadingStation, setLoadding] = useState(false)
  const flagRef = useRef('')

  const onGetAllstations=()=>{
    setLoadding(true)
    getStationList()
      .then(res=>{
        dispatch({ type: DICT_STATIONS, data: res })
        // setMap(new Map(res.map(o=>[o.code, o])))
      })
      .catch((err)=>{
        
      })
      .finally(()=>{
        setLoadding(false)
      })
  }


  useEffect(()=>{
    if (!isDisFetch && !flagRef.current) {
      flagRef.current = true
      onGetAllstations()
    }
  }, [])

  useEffect(()=>{
    setMap(new Map(dicts.stations.map(o=>[String(o.id), o])))
  }, [dicts.stations])

  return {
    stationMap,
    loadingStation,
    stations: dicts.stations,
    onGetAllstations,
  }
}
