import { useDispatch, useSelector } from "react-redux"
import { DICT_SCENE_CATES, DICT_SCENES, DICT_SCENE_CATE_CHILDREN } from "../store/dicts"
import { useEffect, useState } from "react"
import { getAllSceneTypes } from '@/axios/axios.dict'
import { getSceneCates } from "../axios/axios.scene"
import { getCategoryList } from '@/axios/axios.category';


export function useSceneDicts() {
  const dispatch = useDispatch()

  const dicts = useSelector(state=>state.dicts)
  const [isFetchingScenes, setIsFetchingScenes] = useState(false)
  const [isFetchingSceneCates, setIsFetchingSceneCates] = useState(false)
  const [isFetchingSceneCateChildren, setIsFetchingSceneCateChildren] = useState(false)

  const [sceneQueryCates, setsceneQueryCates] = useState(null)
  const [sceneQueryChildrenCates, setsceneQueryChildrenCates] = useState(null)

  const [sceneLevelMap, setSceneLevelMap] = useState(new Map())
  const [sceneNameMap, setSceneNameMap] = useState(new Map())

  const [sceneCateMap, setSceneCateMap] = useState(new Map())
  const [sceneCateNameMap, setSceneCateNameMap] = useState(new Map())
  
  const [sceneCateChildrenMap, setSceneCateChildrendMap] = useState(new Map())
  const [sceneCateChildrenNameMap, setSceneCateChildrendNameMap] = useState(new Map())

  const onGetAllSceneCates =(args)=>{
    setIsFetchingSceneCates(true)
    getSceneCates(args)
      .then(res=>{
        res.sort((a, b) => a.scen_level - b.scen_level)
        if (args) {
          setsceneQueryCates(res)
        } else {
          setsceneQueryCates(null)
          dispatch({ type: DICT_SCENE_CATES, data: res })
        }
      })
      .finally(()=>{
        setIsFetchingSceneCates(false)
      })
  }

  const onGetAllScenes =()=>{
    setIsFetchingScenes(true)
    getAllSceneTypes()
      .then(res=>{
        res.sort((a, b) => a.scen_level - b.scen_level)
        dispatch({ type: DICT_SCENES, data: res })
      })
      .finally(()=>{
        setIsFetchingScenes(false)
      })
  }

  const onGetAllSceneCateChildren=(args)=>{
    setIsFetchingSceneCateChildren(true)
    getCategoryList(args)
      .then(res=>{
        res.sort((a, b)=>a.scen_category_id-b.scen_category_id)
        if (args) {
          let list = res.map(o =>({
            ...o,
            sceneCateInfo: sceneCateMap.get(String(o.scen_category_id)),
          }))

          if (args.level) {
            list = list.filter(o => {
              return o.sceneCateInfo && String(o.sceneCateInfo.scen_level) === String(args.level)
            })
          }
          setsceneQueryChildrenCates(list)
        } else {
          setsceneQueryChildrenCates(null)
          dispatch({ type: DICT_SCENE_CATE_CHILDREN, data: res })
        }
      })
      .finally(()=>{
        setIsFetchingSceneCateChildren(false)
      })
  }

  useEffect(()=>{

    setSceneLevelMap(new Map(dicts.scenes.map(o=>[String(o.level), o.name])))
    setSceneNameMap(new Map(dicts.scenes.map(o=>[String(o.level), o.name])))
  }, [dicts.scenes])
  useEffect(()=>{
    setSceneCateMap(new Map(dicts.sceneCates.map(o=>[String(o.id), o])))
    setSceneCateNameMap(new Map(dicts.sceneCates.map(o=>[String(o.id), o.category])))
  }, [dicts.sceneCates])
  useEffect(()=>{
    setSceneCateChildrendMap(new Map(dicts.sceneCateChildren.map(o=>[String(o.id), o])))
    setSceneCateChildrendNameMap(new Map(dicts.sceneCateChildren.map(o=>[String(o.id), o.name])))
  }, [dicts.sceneCateChildren])

  return {
    scenes: dicts.scenes,
    sceneCates: dicts.sceneCates,
    sceneQueryCates,
    sceneQueryChildrenCates,
    sceneCateChildren: dicts.sceneCateChildren,
    sceneNameMap,
    sceneCateNameMap,
    sceneCateChildrenNameMap,

    onGetAllScenes,
    onGetAllSceneCates,
    onGetAllSceneCateChildren,

    isFetchingSceneCates, 
    setIsFetchingSceneCates,
    isFetchingSceneCateChildren,

    sceneCateMap,
    sceneLevelMap,
    sceneCateChildrenMap,
  }
}