import axios from './axios'

export const getCategoryList = (params) => {
    return axios({
        method: 'get',
        url: '/category',
        params,
    })
}
/* 
{
    "scen_category_id": 1,
    "name": "xxx"
}
*/
export const addCategory = (data) => {
    return axios({
        method: 'post',
        url: '/category',
        data,
    })
}

export const deleteCategory =(id)=>{
    return axios({
        method: 'delete',
        url: `/category/${id}`
    })
}

export const editCategory = (id, data) => {
    return axios({
        method: 'put',
        url: '/category/'+id,
        data,
    })
}