import { useRef, useState, useEffect } from 'react'
import { select, selectAll } from 'd3-selection'
import { cloneDeep } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useGeo } from '@/hooks/editor/geo'
import { message } from 'antd'
import { CANVAS_MODE } from '@/reducers/editor'
import { useParent } from './parent'
import { MODE_SELECT } from '../../utils/constant'

export function useMove(history, dispatch, isEquip, selectedLayer, parent, scale, readonly) {

  const { t } = useTranslation()
  const { getXYArea, getBoxLoc, isBrotherCross, isContained } = useGeo(history)
  const { isCurrentAableShape } = useParent(parent, selectedLayer)
  
  const entity = useRef({})
  const startLoc = useRef([0, 0])
  const endLoc = useRef([0, 0])
  const [isMoving, setIsMoving] = useState(false)
  const startStamp = useRef(0)

  const [area, setArea] = useState({
    minX: 0,
    minY: 0,
    maxX: Infinity,
    maxY: Infinity,
  })

  const onCalArea =()=>{
    const area = getXYArea(selectedLayer)
    setArea({
      ...area,
      maxX: area.maxEntityX,
      maxY: area.maxEntityY,
    })
  }

  const onMouseDown =(e)=>{
    onDestroy()
    try {
      if (!isCurrentAableShape) {
        console.error('跟元素无法拖拽')
        return
      }
      if (readonly) {
        console.error('只读模式')
        return
      }
      console.error('start')
      const id = e.target.dataset.id

      if (id !== selectedLayer.id || !id) return false

      startStamp.current = Date.now()
      e.stopPropagation()
      startLoc.current = [e.clientX, e.clientY]
      window.addEventListener('mousemove', onMouseMove)
      window.addEventListener('mouseup', onMouseUp)
    } catch (error) {
      console.error(error)   
    }
  }

  const onMouseMove =e=>{
    e.stopPropagation()
    setIsMoving(true)
    
    const newEntity = cloneDeep(entity.current)
    const { clientX, clientY } = e
    
    let { x, y, width, height } = isEquip ? history.equip(newEntity.id) : history.entity(newEntity.id)
    
    const disX = clientX - startLoc.current[0]
    const disY = clientY - startLoc.current[1]

    newEntity.x = Number(x) + Number(disX)
    newEntity.y = Number(y) + Number(disY)
    endLoc.current  = [clientX, clientY]
    // entity.current = newEntity

    // onForceRepaint(newEntity)
    selectAll(`g[data-id^=${newEntity.id}]`)
      .style('transform', `translate(${disX}px, ${disY}px)`)
  }

  const onMouseUp =e=>{
    e.stopPropagation()
    
    onDestroy()

    setTimeout(()=>{

      setIsMoving(false)
    }, 400)

    const [sx, sy] = startLoc.current
    const [ex, ey] = endLoc.current
    const disX = (ex - sx) / scale
    const disY = (ey - sy) / scale
    const id = entity.current.id
    window.requestIdleCallback(()=>{
        // onResetStyle(entity.current)
        isEquip ?
        history.equipSelect([id]) :
        history.select([id])
        dispatch({ type: CANVAS_MODE, data: MODE_SELECT })
    })
    window.requestAnimationFrame(()=>{
      onResetStyle(entity.current) 
    })
    if (Math.abs(ex - sx)<1 && Math.abs(ey - sy)<1) {
        return false
    }
    if (Date.now() - startStamp.current < 300) {
      console.error('时间太短，默认为没有移动')
      return false
    }
    const parent = history.entity(entity.current.pid)
    const newEntity = cloneDeep(isEquip ? history.equip(id) : history.entity(id))
    newEntity.x = Number(newEntity.x) + disX 
    newEntity.y = Number(newEntity.y) + disY

    if (parent && parent.id) {
        if (!isContained(parent, newEntity)) {
            message.error(t('scene.editor.draw-t-no-parent-contain'))
            onResetStyle(entity.current) 
            return 
        }
        if (isBrotherCross(newEntity, parent)) {
            message.error(t('scene.editor.draw-t-no-cross'))
            onResetStyle(entity.current) 
            return 
        }
    }
    
    history.replace(newEntity, !isEquip, 'move-layer')
    if (!isEquip) {
      const tree = history.tree(history.entity(id))
      // const children = history.children(entity)
      const mapEquips =(id)=>{
        const equips = history.equipChildren(id)
        console.log(equips, disX, disY)
        equips.forEach(o =>{
          const newEquip = cloneDeep(o)
          newEquip.x = Number(newEquip.x) + disX
          newEquip.y = Number(newEquip.y) + disY
          history.replace(newEquip, false)
        })
      }
      const mapList = arr=>{
        arr.forEach(o=>{
          const newEntity = cloneDeep(o)
          if (o.id !== id) {
            newEntity.x = Number(newEntity.x) + disX
            newEntity.y = Number(newEntity.y) + disY
          } 
          if (Number(o.level) === 5) {
            mapEquips(o.id)
          }
          history.replace(newEntity, true)
          mapList(newEntity.children||[])
        })
      }
      mapList(tree)

    }
    onResetStyle(entity.current)
  }

  const onResetStyle =(entity)=>{
    selectAll(`g[data-id^=${entity.id}]`)
      .style('transform', `translate(0px, 0px)`)
      .style('opacity', 0)
    setTimeout(()=>{
      selectAll(`g[data-id^=${entity.id}]`)
        .style('opacity', 1) 
    }, 0)
  }

  const onDestroy =()=>{
    window.removeEventListener('mousemove', onMouseMove)
    window.removeEventListener('mouseup', onMouseUp)
  }

  useEffect(() => {
    entity.current = cloneDeep(selectedLayer)
    onCalArea()
    // onResetStyle(selectedLayer)
    return () => {
      onDestroy()
    }
  }, [selectedLayer])

  return {
    isMoving,
    onMouseDown,
    onDestroy,

  }
}