import { cloneDeep } from 'lodash'
import { useTags } from './tags'

export function useXML(history, parent) {
  const { tags, wsLayoutTags, equipLayoutTags, equipTags, arrowTags } = useTags(parent)

  const onCreateLayoutXML =(data)=>{
    const tree = data || history.tree()
    const mapObj = obj=>{
      try {
        const dom = document.createElement('layout')
        
        for (let i in obj) {
          if (i !== 'children') {
            dom.setAttribute(i, obj[i])
          }
        }
        
        (obj.children || []).forEach(o=>{
          const cDom = mapObj(o)
          dom.appendChild(cDom)
        })
        return dom
      } catch (error) {
        return null
      }
    }
    if (!tree || tree.length === 0) return null
    const dom = mapObj(tree.length > 0 ? tree[0] : null)
    return dom
  }

  const onCreateEquipXML = data=>{
    const equips = data || history.equipments
    const dom = document.createElement('equipments')

    for (let i in equips) {
      const d = document.createElement('equipment')
      const equip = equips[i]
      for (let i in equip) {
        d.setAttribute(i, equip[i])
      }
      dom.appendChild(d)
    }
    return dom
  }

  const onCreateArrowXML = data => {
    const arrows = data || history.arrows
    const dom = document.createElement('arrows')
    for (let i in arrows) {
      const d = document.createElement('arrow')
      const arrow = arrows[i]
      for (let i in arrow) {
        d.setAttribute(i, arrow[i])
      }
      dom.appendChild(d)
    }
    return dom
  }

  const onGetEntity =dom=>{
    const level = String(dom.getAttribute('level'))
    const formatTags = cloneDeep(level === '6' ? wsLayoutTags : level === '7' ? equipLayoutTags : tags)
    for (let i in formatTags) {
      formatTags[i] = dom.getAttribute(i) || ''
    }

    formatTags.children = []
    
    return formatTags
  }

  const onGetEquip =dom=>{
    const formatTags = cloneDeep(equipTags)
    for (let i in formatTags) {
      formatTags[i] = dom.getAttribute(i) || ''
    }
    formatTags.level = '7'
    
    return formatTags
  }

  const onGetArrow =dom=>{
    const formatTags = cloneDeep(arrowTags)
    for (let i in formatTags) {
      formatTags[i] = dom.getAttribute(i) || ''
    }
    
    return formatTags
  }
  const onParseXML =(xml)=>{
    if (!xml) {
      return {}
    }
    const entities = {}
    const equipments = {}
    const arrows = {}

    const mapList =(dom)=>{
      if (!dom.tagName) return
      const tagName = dom.tagName.toLowerCase()
      if (['xml', 'design'].includes(tagName)) {
        dom.childNodes.forEach(node=>{
          mapList(node)
        })
      } else if (tagName === 'layout') {
        const entity = onGetEntity(dom)
        if (entity && entity.id) {
          entities[entity.id] = entity
          dom.childNodes.forEach(node=>{
            if (node.tagName.toLowerCase() === 'layout') {
              mapList(node)
            }
          })
        }
      } else if (tagName === 'equipments') {
        dom.childNodes.forEach(node=>{
          const equip = onGetEquip(node)
          
          equipments[equip.id] = equip
        })
      } else if (tagName === 'arrows') {
        dom.childNodes.forEach(node => {
          const arrow = onGetArrow(node)
          arrows[arrow.id] = arrow
        })
      }
    }
    mapList(xml)
    return {
      entities,
      equipments,
      arrows,
    }
  }

  const onObjToXml =jsonStr=>{
    const xml = document.createElement('xml')
    xml.innerHTML = jsonStr
    const { entities, equipments, arrows } = onParseXML(xml)
    return {
      entities,
      equipments,
      arrows,
    }
  }

  return {
    onObjToXml,
    onCreateLayoutXML,
    onCreateEquipXML,
    onCreateArrowXML,
  }
}