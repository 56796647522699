import { memo, useState, useEffect, useMemo, useRef } from 'react'
import debounce from 'lodash/debounce';
import { Select, Button, Modal, Spin, Tooltip, message, Divider, Tag } from 'antd'
import { TagFilled, UndoOutlined, SendOutlined, TagOutlined, DeleteOutlined, NodeExpandOutlined, PlusCircleOutlined, SaveOutlined, MenuUnfoldOutlined, MenuFoldOutlined, RedoOutlined, PlusOutlined, MinusCircleOutlined, CheckCircleOutlined, ApiOutlined, ApiFilled, AimOutlined, CopyOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { CHANGE_SHOW_STRUCTOR, CHANGE_GRID, CHANGE_SHOW_CHILDREN, CHANGE_SHOW_EQUIPS, CANVAS_MODE, CHANGE_SHOW_TAGS } from '@/reducers/editor'
import { useXML } from '@/hooks/editor/xml'
import { Keybinding } from '@/utils/editor/keybinding'

import { saveLayout, submitLayout } from '@/axios/axios.scene'
import { getUsers } from '@/axios/axios.user'
import { useParams } from 'react-router-dom'
import { useStorage } from '@/hooks/editor/storage'
import { select } from 'd3-selection'
import Params from './params'
import Marker from '@/components/marker/' 
import { useSceneDicts } from "@/hooks/scen_dicts"
import SelectedEntity from './select_entity'
import { CHANGE_COPYTYPE } from '../../../reducers/editor'
import ShowLevel from './show_level'
import Export from './export'
import Version from './version'
import { MODE_ARROW, MODE_BROWSE, MODE_COPY, MODE_DRAW, MODE_SELECT } from '../../../utils/constant'

const keybinding = Keybinding('header')

export default memo(function ({
  zoom,
  setZoom,
  filters,
  history,
  dispatch,
  mode,
  showLevels,
  isShowStructor,
  isShowTags,
  isShowEquips,
  isShowChildren,
  isShowGrid,
  isTop,
  isBtm,
  isLine,
  parent,
  selectedIds,
  readonly,
  selectedEntity,
  selectedEquipids,
  selectedArrowids,
  copyType,
  tree,
  currentLayout,
  reload,
}) {
  const { t } = useTranslation()
  const { onCreateLayoutXML, onCreateEquipXML, onCreateArrowXML } = useXML(history, parent)
  const { sceneCateNameMap } = useSceneDicts()

  const [saving, setSaving] = useState(false)
  const [open, setOpen] = useState(false)
  const [submiting, setSubmiting] = useState(false)
  const [isShowUser, setShowUser] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState([])
  const [reviewers, setReviewers] = useState([])
  const fetchRef = useRef(0)
  const params = useParams()
  const { clear, removeLayout } = useStorage(history, parent)

  const onSave = async (update_desc) => {
    return new Promise(async (resolve, reject) => {
      const dom = onCreateLayoutXML()
      const equips = onCreateEquipXML()
      const arrows = onCreateArrowXML()

      if (!dom || !equips || !arrows) {
        message.error(t('scene.editor.save-empty'))
        reject(false)
        return
      }
      try {

        setSaving(true)
        if (!dom) {
          removeLayout()
          reject(false)
        } else {
          const xml = document.createElement('xml')
          const design = document.createElement('design')
          xml.appendChild(design)
          design.appendChild(dom)
          design.appendChild(equips)
          design.appendChild(arrows)
          const layout = xml.innerHTML

          const { id, plant } = params
          await saveLayout(id, {
            update_scope: '',
            update_desc,
            layout
          })

          clear()
          history.reset()
          message.success(t('scene.editor.save-sus'))
          resolve(true)
        }
      } catch (err) {
        console.error(err)
        reject(false)
      } finally {
        setSaving(false)
      }
    })

  }

  const onCheckSubmit = ()=>{
    const entities = history.entities
    for (let i in entities) {
      const entity = entities[i]
      if (Number(entity.level) < 6) {
        if (!entity.scene_id) {
          message.error(t('scene.editor.release-err-no-sceneid'))
          return false
        }
        if (!sceneCateNameMap.has(entity.scene_id)) {
          message.error(entity.title + ':' + t('scene.editor.release-err-dis-sceneid'))
          return false
        }
      }
    }
    const equips = history.equipments
    for (let i in equips) {
      const equip = equips[i]
      if (!equip.scene_id) {
        message.error(t('scene.editor.release-err-no-equip-sceneid'))
        return false
      }
      
      if (!sceneCateNameMap.has(equip.scene_id)) {
        message.error(t('scene.editor.release-err-dis-equip-sceneid'))
        return false
      }
    }

    setShowUser(true)
    // () => setShowUser(true)
  }

  const onSubmit = () => {

    if (!reviewers || reviewers.length === 0) {
      message.error('请先选择审核员')
      return
    }
    setSubmiting(true)
    new Promise(async (resolve, reject) => {
      if (isBtm) {
        resolve()
      } else {
        const result = await onSave()
        if (result) {
          resolve()
        } else {
          reject()
        }
      }
    })
      .then(() => {
        setSaving(true)
        submitLayout(params.id, params.design, reviewers)
          .then(res => {
            message.success(t('scene.editor.release-sus'))
            setShowUser(false)
          })
          .finally(() => {
            setSaving(false)
            setSubmiting(false)
          })
      })
      .catch(() => {
        setSubmiting(false)
      })
  }

  const onToggleStructure =()=>{
      dispatch({ type: CHANGE_SHOW_STRUCTOR, data: !isShowStructor })
  }

  const onTolShowChildren = ()=>{
      if (isShowChildren) {
        history.select([])
        history.equipSelect([])
        dispatch({ type: CANVAS_MODE, data: MODE_BROWSE })
      }
      dispatch({ type: CHANGE_SHOW_CHILDREN, data: !isShowChildren })
  }

  const onTolShowGrid = ()=>{
    dispatch({ type: CHANGE_GRID, data: !isShowGrid })
}

  const onTogggleDraw = () => {
    if (parent && Number(parent.level) >= 7) return false
    if (parent.level >= 6) {
      message.error(t('scene.editor.mode-err-level'))
      return false
    }
    dispatch({ type: CANVAS_MODE, data: mode === MODE_DRAW ? MODE_BROWSE : MODE_DRAW })
    history.select([])
  }

  const onTogggleArrow = () => {
    if (mode === MODE_COPY) return
    dispatch({ type: CANVAS_MODE, data: mode === MODE_ARROW ? MODE_BROWSE : MODE_ARROW })
  }

  const onDelete = () => {
    if (mode !== MODE_SELECT) return false
    history.remove([...selectedIds, ...selectedEquipids, ...selectedArrowids])
    history.select([])
    dispatch({ type: CANVAS_MODE, data: MODE_BROWSE })
  }

  const onZoomOut = () => {
    const newZoom = Number(zoom) * 1.15
    setZoom(newZoom.toFixed(2))
  }

  const onZoomIn = () => {
    const newZoom = Number(zoom) / 1.15
    setZoom(newZoom.toFixed(2))
  }

  const onZoomInit = () => {
    setZoom(1)
  }

  

  const onDebounceSearch = useMemo(() => {
    const search = value => {
      if (!value.trim()) return
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)
      getUsers({ query: value })
        .then(res => {
          if (fetchId !== fetchRef.current) return
          setOptions(res.map(o => ({
            ...o,
            label: o.userName,
            value: o.userId
          })))
        })
        .finally(() => {
          setFetching(false)

        })
    }
    return debounce(search, 800)
  }, [])

  const onSubscribe =()=>{
    

    keybinding.on('Ctrl+A', ()=>{
      dispatch({ type: CHANGE_SHOW_TAGS, data: !isShowTags })
    })

    if (!(readonly || isShowStructor)) {
      keybinding.on('Ctrl+N', onTogggleDraw)
      keybinding.on('⌫', onDelete)

      keybinding.on('Ctrl+-', onZoomIn)
      keybinding.on('Ctrl++', onZoomOut)
      keybinding.on('Ctrl+=', onZoomOut)
      keybinding.on('Ctrl+0', onZoomInit)
    }

    if (!readonly) {
      keybinding.on('Ctrl+Z', ()=>{

        if (saving) return
  
        history.undo()
      })
  
      keybinding.on('Ctrl+Y', ()=>{
        if (saving) return
        history.redo()
      })
  
      keybinding.on('Ctrl+S', ()=>{
        if (saving || isBtm) return
        onSave()
      })
    }

    keybinding.on('Ctrl+Q', onToggleStructure)



    keybinding.on('⎋', ()=>{
      dispatch({ type: CANVAS_MODE, data:  MODE_BROWSE })
      history.select([])
    })

    select(document)
      .call(keybinding)
  }

  const onUnsubscribe =()=>{
    keybinding.clear()
    select(document)
      .call(keybinding.unbind)
  }

  

  useEffect(()=>{
    onSubscribe()
    return ()=>{
      onUnsubscribe()
    }
  }, [saving, isShowTags, mode, selectedEntity, isBtm, isShowStructor, readonly])

  return (
    <header className='w-per-100 f-b-c bg-color-fff shadow-bottom h-30 p-lr-20 b-eee b-no-lr b-no-t'>
      <div className='structor f-s-c'>
        <Tooltip title={(isShowStructor ? t("scene.editor.sq") : t("scene.editor.dk")) + '(Ctrl+Q)'}>
          <Button
            className='animated fadeInLeft m-r-10'
            type='link'
            icon={isShowStructor ? <MenuFoldOutlined className='aniamted fadeIn' /> : <MenuUnfoldOutlined className='aniamted fadeIn' />}
            onClick={onToggleStructure}
          ></Button>
        </Tooltip>
        {
          isShowStructor ? null :
            <Tooltip title={t('common.equip')}>
              <Button
                className='animated fadeInLeft m-r-10'
                icon={isShowEquips ? <ApiFilled /> : <ApiOutlined />}
                type='link'
                onClick={() => dispatch({ type: CHANGE_SHOW_EQUIPS, data: !isShowEquips })}
              >
              </Button>
            </Tooltip>
        }

       

        {
          isShowStructor ?
            <Params 
              dispatch={dispatch}
              filters={filters}
              history={history}
              tree={tree}
            /> : 
            <>
              <ShowLevel 
                showLevels={showLevels}
                dispatch={dispatch}
              />
              <Tooltip title={t('common.child')}>
                <Button type='link' className='p-lr-5' onClick={onTolShowChildren}>
                  <Marker 
                    size={20}
                    d={
                      isShowChildren ?
                      'M581.44768 79.872l298.1888 169.28768c20.00896 11.34592 27.36128 37.376 16.384 58.14272a41.984 41.984 0 0 1-16.384 16.9984L581.44768 493.568a140.12416 140.12416 0 0 1-138.89536 0l-298.1888-169.28768c-20.00896-11.34592-27.36128-37.376-16.384-58.14272a41.984 41.984 0 0 1 16.384-16.9984L442.55232 79.872a140.12416 140.12416 0 0 1 138.89536 0z m296.59136 381.8496l1.59744 0.94208c20.00896 11.8784 27.36128 39.07584 16.384 60.74368a43.19232 43.19232 0 0 1-16.384 17.75616l-298.1888 176.86528a134.92224 134.92224 0 0 1-138.89536 0l-298.1888-176.86528c-20.00896-11.8784-27.36128-39.05536-16.384-60.74368 3.76832-7.49568 9.46176-13.63968 16.384-17.75616l1.59744-0.94208a78.88896 78.88896 0 0 1 80.896 0l215.69536 127.93856a134.92224 134.92224 0 0 0 138.89536 0l215.69536-127.93856a78.88896 78.88896 0 0 1 80.896 0z m0 225.28l1.59744 0.94208c20.00896 11.8784 27.36128 39.07584 16.384 60.74368a43.19232 43.19232 0 0 1-16.384 17.75616l-298.1888 176.86528a134.92224 134.92224 0 0 1-138.89536 0l-298.1888-176.86528c-20.00896-11.8784-27.36128-39.05536-16.384-60.74368 3.76832-7.49568 9.46176-13.63968 16.384-17.75616l1.59744-0.94208a78.88896 78.88896 0 0 1 80.896 0l215.69536 127.93856a134.92224 134.92224 0 0 0 138.89536 0l215.69536-127.93856a78.88896 78.88896 0 0 1 80.896 0z':
                      'M896 380.586667v6.826666a20.906667 20.906667 0 0 1-9.386667 17.493334l-192 131.413333-329.386666-329.386667 99.413333-67.84a63.573333 63.573333 0 0 1 35.84-11.093333h23.04a63.573333 63.573333 0 0 1 35.84 11.093333l327.253333 224a20.906667 20.906667 0 0 1 9.386667 17.493334z m-99.413333 176.64l-48.213334 32.853333 98.133334 98.133333 40.106666-27.306666a20.906667 20.906667 0 0 0 9.386667-17.493334v-6.826666a20.906667 20.906667 0 0 0-9.386667-17.493334zM186.88 134.4a20.48 20.48 0 0 0-29.866667 0l-22.613333 22.613333a20.48 20.48 0 0 0 0 29.866667l105.813333 106.24-102.826666 69.973333a20.906667 20.906667 0 0 0-9.386667 17.493334v6.826666a20.906667 20.906667 0 0 0 9.386667 17.493334l327.253333 224a63.573333 63.573333 0 0 0 35.84 11.093333h23.04a63.573333 63.573333 0 0 0 35.84-11.093333l9.813333-6.826667 90.88 90.88L516.693333 810.666667h-9.386666L256 640l45.226667-31.146667-73.813334-51.626666-90.026666 61.866666a20.906667 20.906667 0 0 0-9.386667 17.493334v6.826666a20.906667 20.906667 0 0 0 9.386667 17.493334l327.253333 224a63.573333 63.573333 0 0 0 35.84 11.093333h23.04a63.573333 63.573333 0 0 0 35.84-11.093333l162.133333-110.506667 115.626667 115.2a20.48 20.48 0 0 0 29.866667 0l22.613333-22.613333a20.48 20.48 0 0 0 0-29.866667z'
                    }
                  />
                </Button>
              </Tooltip>
            </>
        }
        {
          isShowStructor ? null :
          <Tooltip title={t('common.grid')}>
            <Button type='link' className='p-lr-5' onClick={onTolShowGrid}>
              <Marker 
                size={20}
                d={
                  isShowGrid ?
                  "M996.2 679L340 22.8c-12.5-12.5-32.8-12.5-45.3 0L23.2 294.3c-12.5 12.5-12.5 32.8 0 45.3l656.2 656.2c12.5 12.5 32.8 12.5 45.3 0l271.5-271.5c12.5-12.5 12.5-32.8 0-45.3zM262.6 234.9l119.2-119.2c1.6-1.6 4.1-1.6 5.7 0l39.6 39.6c1.6 1.6 1.6 4.1 0 5.7L307.9 280.2c-1.6 1.6-4.1 1.6-5.7 0l-39.6-39.6c-1.5-1.6-1.5-4.1 0-5.7z m112.9 45l85.2-85.2c1.6-1.6 4.1-1.6 5.7 0l39.6 39.6c1.6 1.6 1.6 4.1 0 5.7l-85.2 85.2c-1.6 1.6-4.1 1.6-5.7 0l-39.6-39.6c-1.5-1.6-1.5-4.1 0-5.7z m79 79l85.2-85.2c1.6-1.6 4.1-1.6 5.7 0l39.6 39.6c1.6 1.6 1.6 4.1 0 5.7l-85.2 85.2c-1.6 1.6-4.1 1.6-5.7 0l-39.6-39.6c-1.6-1.6-1.6-4.1 0-5.7z m45.7 113.6l119.2-119.2c1.6-1.6 4.1-1.6 5.7 0l39.6 39.6c1.6 1.6 1.6 4.1 0 5.7L545.5 517.8c-1.6 1.6-4.1 1.6-5.7 0l-39.6-39.6c-1.5-1.6-1.5-4.1 0-5.7z m152.5 90.3l-39.6-39.6c-1.6-1.6-1.6-4.1 0-5.7l85.2-85.2c1.6-1.6 4.1-1.6 5.7 0l39.6 39.6c1.6 1.6 1.6 4.1 0 5.7l-85.2 85.2c-1.6 1.6-4.1 1.6-5.7 0z m39.4 33.7l85.2-85.2c1.6-1.6 4.1-1.6 5.7 0l39.6 39.6c1.6 1.6 1.6 4.1 0 5.7l-85.2 85.2c-1.6 1.6-4.1 1.6-5.7 0l-39.6-39.6c-1.6-1.6-1.6-4.1 0-5.7z m85.3 158.9l-39.6-39.6c-1.6-1.6-1.6-4.1 0-5.7L857 590.9c1.6-1.6 4.1-1.6 5.7 0l39.6 39.6c1.6 1.6 1.6 4.1 0 5.7L783.1 755.4c-1.6 1.5-4.1 1.5-5.7 0z":
                  "M294.8 22.8L23.2 294.3c-12.5 12.5-12.5 32.8 0 45.3l656.2 656.2c12.5 12.5 32.8 12.5 45.3 0l271.5-271.5c12.5-12.5 12.5-32.8 0-45.3L340 22.8c-12.5-12.5-32.8-12.5-45.2 0z m-198 288.5l215-215c3.1-3.1 8.2-3.1 11.3 0l33.9 33.9c3.1 3.1 3.1 8.2 0 11.3l-96.2 96.2 45.3 45.3 96.2-96.2c3.1-3.1 8.2-3.1 11.3 0l22.4 22.4c3.1 3.1 3.1 8.2 0 11.3l-62.2 62.2L419 328l62.2-62.2c3.1-3.1 8.2-3.1 11.3 0l22.4 22.4c3.1 3.1 3.1 8.2 0 11.3l-62.2 62.2L498 407l62.2-62.2c3.1-3.1 8.2-3.1 11.3 0l23.1 23.1c3.1 3.1 3.1 8.2 0 11.3l-96.2 96.2 45.3 45.3 96.2-96.2c3.1-3.1 8.2-3.1 11.3 0l22.4 22.4c3.1 3.1 3.1 8.2 0 11.3l-62.2 62.2 45.3 45.3 62.2-62.2c3.1-3.1 8.2-3.1 11.3 0l22.4 22.4c3.1 3.1 3.1 8.2 0 11.3l-62.2 62.2 45.3 45.3 62.2-62.2c3.1-3.1 8.2-3.1 11.3 0l23.1 23.1c3.1 3.1 3.1 8.2 0 11.3l-96.3 96 45.3 45.3 96.2-96.2c3.1-3.1 8.2-3.1 11.3 0l33.9 33.9c3.1 3.1 3.1 8.2 0 11.3l-215 215c-3.1 3.1-8.2 3.1-11.3 0L96.8 322.6a7.85 7.85 0 0 1 0-11.3z"
                }
              />
            </Button>
          </Tooltip>
        }
        {
          isShowStructor ?
          null :
          <Export dispatch={dispatch} />
        }
      </div>
      <div className='p-l-50'>
        {
          readonly || isShowStructor ?
            null :
            <>
              <Tooltip title={t("common.add")+'(Ctrl+N)'}>
                <Button
                  disabled={(parent && Number(parent.level) >= 7) || mode === MODE_COPY}
                  className='animated fadeInRight m-r-10'
                  type={mode === MODE_DRAW ? 'primary' : 'default'}
                  size='small'
                  icon={<PlusOutlined />}
                  onClick={onTogggleDraw}
                ></Button>
              </Tooltip>
              <Tooltip title={'Arrow'}>
                <Button
                  disabled={mode === MODE_COPY}
                  className='animated fadeInRight m-r-10'
                  type={mode === MODE_ARROW ? 'primary' : 'default'}
                  size='small'
                  icon={<ArrowRightOutlined />}
                  onClick={onTogggleArrow}
                ></Button>
              </Tooltip>
              <Tooltip title={t("common.delete") + '(⌫)'}>
                <Button
                  className='animated fadeIn'
                  type={'primary'}
                  danger
                  disabled={mode !== MODE_SELECT || mode === MODE_COPY}
                  size='small'
                  icon={<DeleteOutlined />}
                  onClick={onDelete}
                ></Button>
              </Tooltip>
              <Tooltip title={t('common.copy')}>
                <Button
                  className='m-l-10'
                  type='primary'
                  icon={<CopyOutlined />}
                  size='small'
                  disabled={mode !== MODE_SELECT || selectedArrowids.length > 0 || !selectedEntity.level || selectedEntity.level >= 6}
                  onClick={()=>{ dispatch({type: CHANGE_COPYTYPE, data: 'copy'}); setOpen(true) }}
                ></Button>
              </Tooltip>

              <Tooltip title={t('common.move')}>
                <Button
                  className='m-l-10'
                  type='primary'
                  icon={<AimOutlined />}
                  size='small'
                  disabled={mode !== MODE_SELECT || selectedArrowids.length > 0 || !selectedEntity.level || selectedEntity.level >= 6}
                  onClick={()=>{ dispatch({type: CHANGE_COPYTYPE, data: 'move'}); setOpen(true) }}

                ></Button>
              </Tooltip>

              <Tooltip title={t('common.replace')}>
                <Button
                  className='m-l-10'
                  type='primary'
                  icon={<NodeExpandOutlined />}
                  size='small'
                  disabled={mode !== MODE_SELECT || selectedArrowids.length > 0 || selectedEntity.level <= 1 || selectedEntity.level >= 6}
                  onClick={()=>{ dispatch({type: CHANGE_COPYTYPE, data: 'replace'}); setOpen(true) }}
                ></Button>
              </Tooltip>
              <Divider type='vertical' />
            </>
        }
        {
          isShowStructor ? null :
          <>
            <Tooltip title={t('common.out') + '(Ctrl + +)'}>
              <Button
                icon={<PlusCircleOutlined />}
                type='link'
                onClick={onZoomOut}
              >
              </Button>
            </Tooltip>
            <Tooltip title={t('common.in') + '(Ctrl + -)'}>
              <Button
                icon={<MinusCircleOutlined />}
                type='link'
                onClick={onZoomIn}
              >
              </Button>
            </Tooltip>
            <Tooltip title={t('common.100') + '(Ctrl+0)'}>
              <Button
                icon={<CheckCircleOutlined />}
                type='link'
                onClick={onZoomInit}
              >
              </Button>
            </Tooltip>
            <Tag>{parseInt(zoom * 100)}%</Tag>
          </>
        }
      </div>
      <div className='f-s-c'>
        {
          isShowStructor ? null :
          <Version readonly={readonly} currentLayout={currentLayout} reload={reload} onSave={onSave} />
        }
        {
          (readonly || isShowStructor) ? null :
            <>
              <Tooltip title={t("common.undo")+'(Ctrl+Z)'}>
                <Button
                  className='animated fadeIn'
                  type='link'
                  icon={<UndoOutlined />}
                  disabled={isBtm || saving}
                  onClick={() => history.undo()}
                ></Button>
              </Tooltip>
              <Tooltip title={t("common.redo")+'(Ctrl+Y)'}>
                <Button
                  className='animated fadeIn'
                  type='link'
                  icon={<RedoOutlined />}
                  disabled={isTop || saving}
                  onClick={() => history.redo()}
                ></Button>
              </Tooltip>
              <Tooltip title={t("common.save")+'(Ctrl+S)'}>
                <Button
                  className='animated fadeIn'
                  type='link'
                  icon={<SaveOutlined />}
                  disabled={isBtm || saving}
                  onClick={() => onSave()}
                ></Button>
              </Tooltip>
              <Tooltip title={t("common.submit")}>
                <Button
                  className='animated fadeInRight'
                  type='link'
                  icon={<SendOutlined />}
                  disabled={saving}
                  onClick={onCheckSubmit}
                // onClick={onSubmit}
                ></Button>
              </Tooltip>
            </>
        }
        {
          isShowStructor ? null :
          <Tooltip title={t('scene.editor.attr')+'(Ctrl+A)'}>
            <Button
              className='animated fadeInRight'
              type='link'
              icon={isShowTags ? <TagFilled /> : <TagOutlined />}

              onClick={() => dispatch({ type: CHANGE_SHOW_TAGS, data: !isShowTags })}
            ></Button>
          </Tooltip>
        }
      </div>
      <Modal
        title={t('scene.editor.release-user-title')}
        open={isShowUser}
        confirmLoading={submiting}
        onCancel={() => setShowUser(false)}
        onOk={onSubmit}
      >
        <Select
          showSearch
          mode="multiple"
          className='w-200 m-r-10'
          value={reviewers}
          filterOption={false}
          onChange={setReviewers}
          notFoundContent={fetching ? <Spin size="small" /> : null}
          onSearch={onDebounceSearch}
          options={options}
        ></Select>
      </Modal>
      <SelectedEntity 
        open={open}
        type={copyType}
        dispatch={dispatch}
        cancel={()=>{ setOpen(false) }}
        parent={parent}
        selectedEntity={selectedEntity}
        history={history}
      />
    </header>
  )
})
