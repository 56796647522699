import { Button } from "antd"
import { memo } from "react"
import { useTranslation } from "react-i18next"

export default memo(function More({
    more,
    page,
    end,
}) {
    const { t } = useTranslation()

    const onMore = ()=>{
        more(page + 1)
    }

    return (
        <div className='f-c'>
            <Button type='link' disabled={end} onClick={onMore}>
                {end ? t('common.ended') : t('common.more')}
            </Button>
        </div>
    )
})
