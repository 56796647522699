import { useState, memo } from 'react';
// import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Select, Button, Upload, Modal, Form, Input, Spin, Progress, Tooltip, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { usePlant } from '@/hooks/plant';
import { addElement } from '@/axios/axios.dict';
import { useElement } from '@/hooks/element';
import { toBase64 } from '@/utils/util';

const { Dragger } = Upload;

const layout = {
  labelCol: {
    span: 7,
  },
  wrapperCol: {
    span: 17,
  },
};

const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};

export default memo(function(props) {
  const { t } = useTranslation();
  // const account = useSelector(state => state.account);
  const { plants, onGetAllPlants } = usePlant();
  const { categorys, onGetAllCates } = useElement();
  
  const [type, setType] = useState('');
  const [saving, setSaving] = useState(false);
  const [form] = Form.useForm();
  const [file, setFile] = useState(null);

  const onModal =(record, isEdit)=>{
    onGetAllCates();
    onGetAllPlants();
    onReset();
    window.requestIdleCallback(()=>{
      form.setFieldsValue(isEdit ? record : { 
        plant: '',
        name: '',
        category: '',
        file: ''
      });
    });
    setType(isEdit ? 'edit' : 'add');
  };

  const onClose =()=>{
    setType('')
  };

  const onSave =async (arg)=>{
    setSaving(true);
    try {
      await addElement({
        plant: arg.plant,
        category: arg.category,
        name: arg.name,
        icon: (await toBase64(file)).split('base64,')[1],
        filename: file.name
      })
      setSaving(false);
      onClose();
      props.add();
    } catch (error) {
      setSaving(false) 
    };
  };

  const onUpload=({file})=>{
    if (file.size>10*1024*1024) {
      message.error(t('component.add-ele.nt-size'));
      return false;
    }
    setFile(file);
    form.setFieldsValue({file});
  };

  const onReset =()=>{
    form.setFieldsValue({ 
      plant: '',
      name: '',
      category: '',
      file: ''
    });
    setFile('');
  };
  
  return (
    <article>
      <Button type='primary' ghost onClick={onModal}>{t("common.upload")}</Button>
      
      <Modal
        title={''}
        width={590}
        open={!!type}
        footer={null}
        onCancel={onClose}
      >
        <Spin spinning={saving}>
          <Form {...layout} name="nest-messages" className='p-t-20' form={form} onFinish={onSave} validateMessages={validateMessages}>
            <Form.Item
              name={['plant']}
              label="Plant"
              rules={[
                { required: true },
              ]}
            >
              <Select>
                {
                  plants.map(o=>(
                    <Select.Option key={o.code} value={o.code}>
                      {o.plant}
                    </Select.Option>
                  ))
                }
              </Select>
            </Form.Item>

            <Form.Item
              name={['category']}
              label="Category"
              rules={[
                { required: true },
              ]}
            >
              <Select>
                {
                  categorys.map(o=>(
                    <Select.Option key={o.id} value={o.id}>
                      {o.name}
                    </Select.Option>
                  ))
                }
              </Select>
            </Form.Item>

            <Form.Item
              name={['name']}
              label="Name"
              rules={[
                { required: true },
              ]}
            >
              <Input></Input>
            </Form.Item>

            <Form.Item
              name={['file']}
              label="File"
              rules={[
                { required: true },
              ]}
            >
              <div>
                <Dragger accept={props.accept||'.png,.jpeg,.jpg'} multiple={false} showUploadList={false} disabled={file} customRequest={onUpload}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">{t('component.add-ele.drag-title')}</p>
                  <p className="ant-upload-hint">{t('component.add-ele.drag-content')}</p>
                </Dragger>
                {
                  !file ? null :
                  <section className='f-s-c'>
                    <Progress percent={100} className='f-1' strokeColor={{ '0%': '#108ee9', '100%': '#87d068' }} />
                    <Tooltip title={file.name}>
                      <h3 style={{maxWidth: 100}} className='white-1'>{file.name}</h3>
                    </Tooltip>
                  </section>
                }
              </div>
            </Form.Item>
            
            <Form.Item
              wrapperCol={{
                ...layout.wrapperCol,
                offset: 7,
              }}
            >
              <Button type="primary" htmlType="submit" loading={saving}>
                {t("common.save")}
              </Button>
              <Button danger ghost htmlType="reset" className='m-lr-15' onClick={onReset}>
                {t("common.reset")}
              </Button>
              <Button type='primary' danger onClick={onClose}>
                {t("common.cancel")}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </article>
  )
});
