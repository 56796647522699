import { memo, useState, useEffect } from "react"
import { toFixed } from '@/utils/util'

export default memo(function Grid({
    width,
    height,
    parent,
    scale,
}) {
    const [rows, setRows] = useState([])
    const [cols, setCols] = useState([])
    const [x, setX] = useState(-1)
    const [y, setY] = useState(-1)
    const fontSize = 8
    
    const onFixedNum =(num)=>{
        if (num > 1) {
            return 2
        }
        const arr = String(num).split('.')
        const sNum = arr[1]
        return sNum.split('').findIndex(s=>s>0) + 2
    }

    const onCalGrid =()=>{

        let newCols = []
        let offset = 40
        let maxNum
        maxNum = onFixedNum( offset / scale)
        while (newCols.length === 0 || (newCols[newCols.length - 1].size <= width)) {
            if (newCols.length === 0) {
                offset *= maxNum / 2
            }
            let size = newCols.length === 0 ? offset : newCols[newCols.length - 1].size + offset
            let tempGrid = Math.ceil(size / scale)
            size = tempGrid * scale
            const newCol = {
                id: Math.random(),
                size,
                // grid: toFixed(size / scale, maxNum),
                grid: toFixed(size / scale, 0),
            }
            newCol.dx = -0.25 * fontSize * String(newCol.grid).length
            newCols.push(newCol)
        }
        setCols(newCols)

        let newRows = []
        // offset = 50
        // maxNum = 2

        while (newRows.length === 0 || (newRows[newRows.length - 1].size <= height)) {
            let size = newRows.length === 0 ? offset : newRows[newRows.length - 1].size + offset
            let tempGrid = Math.ceil(size / scale)
            size = tempGrid * scale
            const newRow = {
                id: Math.random(),
                size,
                // grid: toFixed(size / scale, maxNum),
                grid: toFixed(size / scale, 0),
            }
            // newRow.dy = -0.25 * fontSize * String(newRow.grid).length
            newRow.dy = 0
            newRows.push(newRow)
        }
        setRows(newRows)
    }

    const onMousemove =e=>{
        const { offsetX, offsetY } = e

        const top = document.querySelector('#layout-editor').scrollTop
        const left = document.querySelector('#layout-editor').scrollLeft

        setX((left + offsetX))
        setY((top + offsetY))
    }

    useEffect(()=>{
        onCalGrid()
    }, [width, height, scale, parent])

    useEffect(() =>{
        document.querySelector('#layout-editor') && document.querySelector('#layout-editor').addEventListener('mousemove', onMousemove)
        return () => {
            document.querySelector('#layout-editor') && document.querySelector('#layout-editor').removeEventListener('mousemove', onMousemove)
        }
    })

    return (
        <>
            <g className="grid-section">
                <line x1={0} y1={0} x2={width} y2={0} stroke={'#666'} strokeWidth={2} />
                <g>
                    {
                        cols.map(col=>(
                            <g key={col.id}>
                                <line x1={col.size} y1={0} x2={col.size} y2={5} stroke={'#999'} strokeWidth={2}></line>
                                <text x={col.size} y={12} fill={'#333'} fontSize={fontSize} dx={col.dx}>{col.grid}</text>
                            </g>
                        ))
                    }
                </g>
            </g>
            <g className="grid-section">
                <line x1={0} y1={0} x2={0} y2={height} stroke={'#666'} strokeWidth={2} />
                <g>
                    {
                        rows.map(row=>(
                            <g key={row.id}>
                                <line x1={0} y1={row.size} x2={5} y2={row.size} stroke={'#999'} strokeWidth={2}></line>
                                <text x={7} y={row.size} fill={'#333'} dy={fontSize*0.4} fontSize={fontSize}>{row.grid}</text>
                            </g>
                        ))
                    }
                </g>
            </g>
            <g className="grid-section">
                <line x1={0} y1={y} x2={x-7} y2={y} stroke={'#ff6300'} strokeWidth={1} strokeDasharray="6 6" style={{ cursor: 'none' }} />
                <line x1={x} y1={0} x2={x} y2={y-7} stroke={'#ff6300'} strokeWidth={1} strokeDasharray="6 6" style={{ cursor: 'none' }} />
            </g>
        </>
    )
})