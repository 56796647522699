import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './views/app';
import 'babel-polyfill';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';
import '@wbiokr/css-class'
import './style/APP.scss';
import { HashRouter } from 'react-router-dom';
import '@/hooks/il8n';
// bruce test123
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <HashRouter>
        <App />
      </HashRouter>
    </Provider>
  // </React.StrictMode>
);

reportWebVitals();