import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Input, Checkbox, Button, message } from 'antd'
import { USER_NAME, USER_PASSWORD, USER_REMEMBER } from '@/utils/storage'
import { useLogin } from '@/hooks/login'
import { getCookie } from "@/utils/cookie"

// const authUrl = process.env.REACT_APP_DOMAIN + "?url=" + encodeURIComponent(process.env.REACT_APP_HRS_ENDPOINT)
const authUrl = process.env.REACT_APP_HRS_ENDPOINT + "?url=" + encodeURIComponent(process.env.REACT_APP_DOMAIN)

function Login() {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { onLogin, logining } = useLogin()
  const [authed, setAuthed] = useState(false)
  const [outed, setOuted] = useState(false)

  const onLoginApp = (values) => {
    onLogin({
      ...values,
    })
      .then(() => {
        message.success('Login successful');
        setTimeout(() => {
          window.location.reload()
        }, 500)
      })
  };


  const onReset = () => {
    form.resetFields();
  };

  useEffect(() => {
    if (localStorage.getItem(USER_NAME) && localStorage.getItem(USER_PASSWORD) && localStorage.getItem(USER_REMEMBER) > 0) {
      form.setFieldsValue({
        account: localStorage.getItem(USER_NAME),
        password: localStorage.getItem(USER_PASSWORD),
        remember: localStorage.getItem(USER_REMEMBER),
      });
    }// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (authed) {
      onLoginApp({})
    }
  }, [authed])

  useEffect(() => {
    setOuted(window.location.href.includes('out=1'))
    setAuthed(!!getCookie('MTL'))
  }, [])

  return (
    <article className='w-per-100 h-per-100 bg-color-fff f-c p-b-100'>
      <div className='w-515 bg-color-fff shadow-app b-r-6 p-30 p-tb-40'>
        <header className='f-b-c'>
          <h3 className='f-s-18 f-w-500'>{t('login.wel')}</h3>
        </header>
        <main className='m-t-40'>
          <h4 className='m-b-20 f-s-16 f-w-600'>{t('login.title')}</h4>
          {
            outed && !authed ? 
            <Form
              name="basic"
              form={form}
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 17 }}
              initialValues={{ remember: true }}
              onFinish={onLoginApp}
              autoComplete="off"
            >
              <Form.Item
                label={t('login.un')}
                name="account"
                rules={[{ required: true, message: t('login.unmsg') }]}
              >
                <Input autoComplete="off" placeholder={t('login.unplace')} />
              </Form.Item>

              <Form.Item
                label={t('login.pw')}
                name="password"
                rules={[{ required: true, message: t('login.pwmsg') }]}
              >
                <Input.Password autoComplete="new-password" placeholder={t('login.pwplace')} />
              </Form.Item>

              <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 5, span: 17 }}>
                <Checkbox>{t('login.rm')}</Checkbox>
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 5, span: 17 }}>
                <div className='f-s-c'>
                  <Button loading={logining} type="primary" htmlType="submit" className='w-180 m-r-20'>
                    {t('common.submit')}
                  </Button>
                  <Button danger className='w-180' onClick={onReset}>
                    {t('common.reset')}
                  </Button>
                </div>
              </Form.Item>
            </Form> :
            <div type="success" className="p-b-50 p-t-20 t-c">
              <a href={authUrl} className="c-yellow f-w-600 f-s-18">{ t('login.auth') }</a >
            </div>
          }
        </main>
      </div>
    </article>
  )
};

export default Login;
