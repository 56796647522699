import { useState, useEffect, useRef } from 'react';
import { getCategorys } from '@/axios/axios.dict';

export function useElement() {
  const [categorys, setCategorys] = useState([]);
  const [loadingCate, setLoadingCate] = useState(false);
  const catRef = useRef('');

  const onGetAllCates=()=>{
    setLoadingCate(true);
    getCategorys()
    .then(res=>{
        setCategorys(res)
      })
      .catch((err)=>{
        setCategorys([])
      })
      .finally(()=>{
        setLoadingCate(false)
      })
  };


  useEffect(()=>{
    if (!catRef.current) {
      catRef.current = true;
      onGetAllCates()
    }
  }, []);

  return {
    categorys,
    loadingCate,
    onGetAllCates,
  }
}
