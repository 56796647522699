import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Table, Button, Modal, Form, Input, Spin } from 'antd'
import { editSceneType } from '@/axios/axios.dict'

const layout = {
  labelCol: {
    span: 7,
  },
  wrapperCol: {
    span: 17,
  },
}

const validateMessages = {
  // eslint-disable-next-line no-template-curly-in-string
  required: '${label} is required!',
  types: {
    // eslint-disable-next-line no-template-curly-in-string
    email: '${label} is not a valid email!',
    // eslint-disable-next-line no-template-curly-in-string
    number: '${label} is not a valid number!',
  },
  number: {
    // eslint-disable-next-line no-template-curly-in-string
    range: '${label} must be between ${min} and ${max}',
  },
}

export default function Structor() {
  const { t } = useTranslation()
  const dicts = useSelector(state => state.dicts)
  const [type, setType] = useState('')
  const [saving, setSaving] = useState(false)
  const [form] = Form.useForm()

  const columns = [
    { title: t('scene.type.col-level'), dataIndex: 'level' },
    { title: t('scene.type.col-key'), dataIndex: 'name' },
  ]

  // const onModal =(record, isEdit)=>{
  //   window.requestIdleCallback(()=>{
  //     form.setFieldsValue(isEdit ? record : { 
  //       level: '',
  //       category: '',
  //       name: '',
  //     })
  //   })
  //   setType(isEdit ? 'edit' : 'add')
  // }

  const onClose =()=>{
    setType('')
  }

  const onSave =async (arg)=>{
    setSaving(true)
    try {
      await editSceneType({
        id: arg.id,
        category: arg.category
      })
      // const code = arg.code
      // const isEdit = type === 'edit'
      // if (isEdit) delete arg.code
      // const res = isEdit ? await editPlant(code, arg) : await addPlant(arg)
      setSaving(false)
      onClose()
      // onGetAllTypes()
    } catch (error) {
      setSaving(false) 
    }
    
  }
  
  return (
    <article>
      <header className='f-s-c p-b-10'>
        {/* <Button type='primary' ghost icon={<SearchOutlined />} className='m-r-20' onClick={onGetAllTypes}>{t("common.search")}</Button> */}
        {/* <Button type='primary'  icon={<PlusOutlined />} onClick={onModal}>{t("common.add")}</Button> */}
      </header>
      <Table
        rowKey='id'
        // loading={loadingSceneType}
        dataSource={dicts.scenes}
        pagination={false}
        columns={columns}
        style={{width: 500}}
      ></Table>
      <Modal
        title={''}
        width={590}
        open={!!type}
        footer={null}
        onCancel={onClose}
      >
        <Spin spinning={saving}>
          <Form {...layout} name="nest-messages" className='p-t-20' form={form} onFinish={onSave} validateMessages={validateMessages}>
          <Form.Item
              name={['id']}
              label={t('scene.type.col-level')}
              hidden
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              name={['level']}
              label={t('scene.type.col-level')}
              disabled
            >
              <Input disabled />
            </Form.Item>

            <Form.Item
              name={['name']}
              label={t('scene.type.col-key')}
              rules={[
                { required: true },
              ]}
            >
              <Input disabled />
            </Form.Item>

            <Form.Item
              name={['category']}
              label={t('scene.type.col-desc')}
              rules={[
                { required: true },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                ...layout.wrapperCol,
                offset: 7,
              }}
            >
              <Button type="primary" htmlType="submit" loading={saving} className='m-r-20'>
                {t("common.save")}
              </Button>
              {/* <Button danger ghost htmlType="reset" className='m-lr-15'>
                {t("common.reset")}
              </Button> */}
              <Button type='primary' danger onClick={onClose}>
                {t("common.cancel")}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </article>
  )
}