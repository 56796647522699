import { memo, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Button, Table, Tooltip } from 'antd'
import { useSceneDicts } from "@/hooks/scen_dicts"
import { SwapOutlined } from "@ant-design/icons"
import { CHANGE_PARENT_LAYER, CANVAS_MODE, CHANGE_SHOW_STRUCTOR } from '@/reducers/editor'
import { formatterNumber } from '@/utils/util'
import { useSelector } from "react-redux"
import { useStation } from '@/hooks/station'
import { useEquipment } from '@/hooks/equipment'
import { MODE_BROWSE } from "../../../utils/constant"

const Tree = ({
    show,
    filters,
    entities,
    parent,
    history,
    dispatch,
    changeTree,
}) => {
    const common = useSelector(state => state.common)
    const dicts = useSelector(state=>state.dicts)
    const { stationMap } = useStation(true)
    const { onEquipName } = useEquipment(true, history)
    
    const { t } = useTranslation()
    const [tree, setTree] = useState([])
    const { sceneLevelMap, sceneCateChildrenNameMap, sceneCateNameMap } = useSceneDicts()
    const [keys, setKeys] = useState([])
    const [names, setNames] = useState([])
    const columns = [
        {
            title: t('scene.editor.menu'),
            dataIndex: 'level',
            key: 'level',
            // width: 80,
            width: 140,

        },
        {
            title: t('scene.editor.scene-menu'),
            dataIndex: 'level',
            key: 'level',
            render(text, record) {
                return sceneLevelMap.get(String(record.level))
            }
        },
        {
            title: t('scene.editor.scene-cate'),
            dataIndex: 'scene_id',
            key: 'scene_id',
            // width: 100,
            render(text, record) {
                if(Number(record.level) === 7) {
                    const equip = history.equip(record.equip)
                    if (!equip) return
                    return sceneCateNameMap.get(equip.scene_id)
                } else if (Number(record.level) === 6) {
                    return t('common.ws')       
                }
                return sceneCateNameMap.get(text)
            }
        },
        {
            title: t('scene.editor.layer-cate'),
            dataIndex: 'cate_id',
            key: 'cate_id',
            width: 100,
            render(text, record) {
                if (Number(record.level) === 6) {
                    return stationMap.has(String(record.ws)) ? stationMap.get(String(record.ws)).type : ''
                }
                return sceneCateChildrenNameMap.get(text)
            }
        },
        {
            title: t('scene.editor.title'),
            dataIndex: 'name',
            key: 'name',
            // width: 120,
            render(oldText, record) {
                let text = oldText
                
                if (Number(record.level) === 7) {
                    // const equip = history.equip(record.equip)
                    // if (!equip) return
                    // const { equips } = dicts
                    // const equipInfo = equips[equip.asset_no]
                    // if (equipInfo) {
                    //     text = equipInfo.equip_name
                    // }
                    text = onEquipName(record)
                } else if (Number(record.level) === 6) {
                    text = record.desc
                }
                if (text === null || text === undefined || text === '') return ''
                return (
                    <span>
                        {
                            String(text).split('').map((str, index)=>(
                                <span key={ `${str}-${index}` } className={names.includes(str.toLowerCase()) ? 'c-red' : ''}>{str}</span>
                            ))
                        }
                        <span></span>
                    </span>
                )
            }
        },
        {
            title: t('scene.editor.code'),
            dataIndex: 'code',
            key: 'code',
            width: 90,
        },
        {
            title: t('setting.equip.c-code'),
            key: 'c-code',
            width: 90,
            render(text, record) {
                if (Number(record.level) !== 7) return ''
                const equip = history.equip(record.equip)
                const { equips } = dicts
                if (!equip) {
                    console.warn(`存在垃圾数据：equip-${record.equip}`, record)
                }

                const equipInfo = equips[equip.asset_no]
                if (!equipInfo) return ''
                return equipInfo.asset_id
            }
        },
        {
            title: t('setting.equip.c-e-no'),
            key: 'c-e-no',
            width: 100,
            render(text, record) {
                if (Number(record.level) !== 7) return ''
                const equip = history.equip(record.equip)
                const { equips } = dicts
                const equipInfo = equips[equip.asset_no]
                if (!equipInfo) return ''
                return equipInfo.equip_no
            }
        },
        {
            title: t('setting.equip.c-pm'),
            key: 'c-pm',
            width: 90,
            render(text, record) {
                if (Number(record.level) !== 7) return ''
                const equip = history.equip(record.equip)
                const { equips } = dicts
                const equipInfo = equips[equip.asset_no]
                if (!equipInfo) return ''
                return equipInfo.equip_mgmt_id
            }
        },
        
        {
            title: 'LPC id',
            dataIndex: 'key',
            key: 'key',
            // width: 80,
            render(text, record) {
                if (record.level <= 1) return 'root'
                return text
            },
        },
        
        {
            title: t('scene.editor.id-type'),
            dataIndex: 'idType',
            key: 'idType',
            width: 70,
            render(text, record) {
                return ['6', '7'].includes(String(record.level)) ? t('scene.editor.id-type-ys') : t('scene.editor.id-type-tc')
            }
        },
        {
            title: t('scene.editor.height'),
            dataIndex: 'width',
            key: 'width',
            width: 80,
            render(text, record) {
                return ['6', '7'].includes(String(record.level)) ? 'NA' : formatterNumber(text)
            },
        },
        {
            title: t('scene.editor.width'),
            dataIndex: 'height',
            key: 'height',
            width: 80,
            render(text, record) {
                return ['6', '7'].includes(String(record.level)) ? 'NA' : formatterNumber(text)
            },
        },
        {
            title: t('scene.editor.area'),
            dataIndex: 'area',
            key: 'area',
            width: 80,
            render(text, record) {
                if (Number(record.level) >= 6) return ''
                const { width, height } = record
                const area = Number(width) * Number(height)
                return area ? formatterNumber(area) : area
            },
        },
        {
            title: t('common.action'),
            dataIndex: 'action',
            key: 'action',
            width: 50,
            fixed: 'right',
            render(text, record) {
                return (
                    record.level > 5 ? null :
                    <Tooltip title={t('scene.editor.select-layer')}>
                        <Button size='small' type='primary' icon={<SwapOutlined/>} onClick={()=>onSelectLayer(record)}>
                        </Button>
                    </Tooltip>
                )    
            },
        },
        // {
        //     title: t('scene.editor.layer-log'),
        //     dataIndex: 'log',
        //     key: 'log',
        //     width: 160,
        //     render(text, record) {
        //         return '开发中'    
        //     },
        // },
    ]

    const onMixName =(name, names)=>{
        const nameArr = String(name).toLowerCase().split('')

        // const keyArr = String(keyword).toLowerCase().split('')
        for (let i=0; i<names.length; i++) {
            if (!nameArr.includes(names[i])) {
                return false
            }
        }
        return true
    }

    const onHasSceneCate =(o, id, minLevel)=>{
        if (minLevel && o.level > minLevel) return false
        if (String(o.scene_id) === String(id)) return true
        const children = o.children || []
        for (let i =0;i <children.length; i++) {
            const cate = children[i]
            if (onHasSceneCate(cate, id, minLevel)) return true
        }
        return false
    }

    const onHasName =(o, nameArr, minLevel) =>{
        if (minLevel && o.level > minLevel) return false
        // if (String(o.name).toLowerCase().includes(name.toLowerCase())) return true
        if (onMixName(o.name, nameArr)) return true
        const children = o.children || []
        for (let i=0; i<children.length; i++) {
            const cate = children[i]
            if (onHasName(cate, nameArr, minLevel)) return true
        }
        return false
    }

    const onGetTree = ()=>{
        const newTree = JSON.parse(JSON.stringify(history.tree(history.root, true)))
        const nameArr = String(filters.name).toLowerCase().split('')
        setNames(nameArr)
        const { minLevel, cate, name } = filters
        const filterTree = arr=> {
            for (let i=0; i<arr.length; i++) {
                const o = arr[i]
                if (minLevel && Number(o.level) > Number(minLevel)) {
                    arr.splice(i, 1)
                    i --
                    continue
                }
                if (cate && !onHasSceneCate(o, cate, minLevel)) {
                    arr.splice(i, 1)
                    i --
                    continue
                }
                if (name && !onHasName(o, nameArr, minLevel)) {
                    arr.splice(i, 1)
                    i --
                    continue
                }
                filterTree(o.children || [])
            }
        }

        filterTree(newTree)

        setTree(newTree)
        changeTree && changeTree(newTree)
        const arr = []
        const mapTree = o=>{
            arr.push(o.id)
            o.rowKey = o.level < 6 ? o.id : Math.random()
            if (o.level < 6) {
                o.children.sort((a, b)=>a.scene_id - b.scene_id)
            }
            (o.children || []).forEach(c=>{
                mapTree(c)
            })
        }
        if (newTree && newTree[0]) {
            mapTree(newTree[0])
        }
        setKeys(arr)
    }

    const onExpandedRowsChange =(keys)=>{
        setKeys(keys)
    }

    const onSelectLayer =(layer)=>{
        const entity = history.entity(layer.id)
        dispatch({ type: CHANGE_PARENT_LAYER, data: entity })
        dispatch({ type: CANVAS_MODE, data: MODE_BROWSE })
        history.select([])
        dispatch({ type: CHANGE_SHOW_STRUCTOR, data: false})
    }

    useEffect(()=>{
        if (show) {
            onGetTree()
        }
    }, [show, filters, entities])

    if (!show) return null

    return (
        <aside 
            className={`bg-color-fff p-a-xl-yt w-per-100 h-per-100 o-a z-10 animated ${show ? 'fadeInUp' : 'fadeOutDown'}`}>
            <Table
                rowKey='id'
                size="small"
                footer={null}
                pagination={false}
                columns={columns}
                dataSource={tree}
                rowClassName={record=>record.id===parent.id ?"active" :""}
                scroll={{ y: common.height - 220, x: 1400 }}
                expandable={{
                    expandedRowKeys: keys,
                    onExpandedRowsChange: onExpandedRowsChange,
                }}
            ></Table>
        </aside>
    )
}

export default memo(Tree)