import axios from './axios'

export const getStationList = () => {
    return axios({
        method: 'get',
        url: '/station',
    })
}
/* 
"type": "芯子装配", // 工位类型
    "no": "xxx", // 工位编号 
    "comment": "" // 备注
*/
export const addStation = (data) => {
    return axios({
        method: 'post',
        url: '/station',
        data,
    })
}

export const deleteStation =(id)=>{
    return axios({
        method: 'delete',
        url: `/station/${id}`
    })
}


export const editStation = (id,data) => {
    return axios({
        method: 'put',
        url: '/station/' + id,
        data,
    })
}