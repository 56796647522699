import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ROLE_ADMIN, ROLE_USER, ROLE_SUPER_ADMIN, USER_SUPER_ADMIN, USER_ADMIN, USER_NOEMAL } from '@/utils/constant';

export function useRole() {
  const account = useSelector(state => state.account);
  const { t } = useTranslation();
  
  const isSuperAdmin = account.role === ROLE_SUPER_ADMIN
  const isAdmin = account.role === ROLE_ADMIN;
  const roles = [
    { label: t('app.super-admin-name'), value: USER_SUPER_ADMIN, disabled: true },
    { label: t('app.admin-name'), value: USER_ADMIN },
    { label: t('app.client-name'), value: USER_NOEMAL },
  ];

  return {
    // isSuperAdmin,
    // isAdmin,
    roleName: isSuperAdmin ? t('app.roleSuperAdmin') : isAdmin ? t('app.roleAdmin') : account.role === ROLE_USER ? t('app.roleClient') : '',
    roles: roles.filter(o => !o.disabled),
    roleMap: new Map(roles.map(o=>[o.value, o.label]))
  }
};