import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useTags } from '@/hooks/editor/tags'
import { useGeo } from './geo'
import { MODE_ARROW } from '../../utils/constant'

export function useArrow(dispatch, history, parent, mode, scale, selectedEntity) {
  const { t } = useTranslation()
  const [isArrowing, setIsArrowing] = useState(false)
  const { onGetNewArrow } = useTags(parent)
  const [arrowText, setText] = useState('')
  const [startLoc, setStartLoc] = useState([0,0])
  const [endLoc, setEndLoc] = useState([0,0])
  const [arrowPath, setArrowPath] = useState('')

  const onArrowClick = (e)=>{
    if (mode !== MODE_ARROW) return
    if (isArrowing) {
      onEnd(e)  
    } else {
      onStart(e)
    }
  }

  const onStart =e=>{
    const loc = onGetLoc(e)
    setStartLoc(loc)
    setEndLoc(loc)
    setIsArrowing(true)
  }

  const onEnd =e=>{
    if (!isArrowing) return false
   
    let newArrow = onGetNewArrow(parent.id, startLoc, endLoc)
    
    history.replace(newArrow, true, 'add-arrow')
    setIsArrowing(false)
  }

  const onArrowMouseMove =e=>{
    if (mode !== MODE_ARROW) return
    if (!isArrowing) return
    const loc = onGetLoc(e)

    setEndLoc(loc)
  }

  const onGetPath =()=>{
    const pathText = onCatArrowPath(startLoc, endLoc, parent)
    setArrowPath(pathText)
  }

  const onCatArrowPath = (locS, locE) => {
    const distance = 10
    const borderDistance = distance * Math.cos(0.5)
    const sx = (locS[0] - Number(parent.x) || 0) * scale
    const sy = (locS[1] - Number(parent.y) || 0) * scale
    const ex = (locE[0] - Number(parent.x) || 0) * scale
    const ey = (locE[1] - Number(parent.y) || 0) * scale

    let upLoc = [0, 0]
    let downLoc = [0, 0]
    const angle = Math.atan2(Math.abs(ey - sy), Math.abs(ex - sx)) / (Math.PI / 180) 
    const tempAngle = Math.abs(angle - 45)
    if (tempAngle === 0) {
      upLoc = [ex > sx ? ex - borderDistance : ex + borderDistance, ey]
      downLoc = [ex, ey > sy ? ey - borderDistance : ey + borderDistance]
    } else {
      const rAngle = tempAngle * Math.PI / 180
      const tempX = distance * Math.cos(rAngle)
      const tempY = distance * Math.sin(rAngle)
      if (ey > sy && ex > sx) {
        upLoc = [ex - tempX, angle > 45 ? ey - tempY : ey + tempY]
        downLoc = [angle > 45 ? ex + tempY : ex - tempY, ey - tempX]
      } else if (ey > sy && ex < sx) {
        upLoc = [angle > 45 ? ex - tempY : ex + tempY , ey - tempX]
        downLoc = [ex + tempX, angle > 45 ? ey - tempY : ey + tempY]
      } else if (ey < sy && ex < sx) {
        upLoc = [ex + tempX, angle > 45 ? ey + tempY : ey - tempY]
        downLoc = [angle > 45 ? ex - tempY : ex + tempY, ey + tempX]
      } else {
        upLoc = [ex - tempX, angle > 45 ? ey + tempY : ey - tempY]
        downLoc = [angle > 45 ? ex + tempY : ex - tempY , ey + tempX]
      }
    }

    let pathText = ''
    pathText += `M ${sx} ${sy}`
    pathText += `L ${ex} ${ey}`
    pathText += `M ${upLoc[0]} ${upLoc[1]}`
    pathText += `L ${ex} ${ey}`
    pathText += `L ${downLoc[0]} ${downLoc[1]}`
    return pathText
  }

  const onGetLoc =e=>{
    const event = e.nativeEvent
    const loc = [event.offsetX, event.offsetY]
    return onLevelLoc(loc)
  }

  const onLevelLoc=loc=>{
    const pX = Number(parent.x)
    const pY = Number(parent.y)

    return [(loc[0] / scale) + pX, (loc[1] / scale) + pY]
  }

  useEffect(()=>{
    setText(isArrowing ? t('scene.editor.draw-start') : t('scene.editor.draw-move'))
  }, [isArrowing])

  useEffect(()=>{
    onGetPath()
  }, [startLoc, endLoc])

  useEffect(()=>{
    setStartLoc([0,0])
    setEndLoc([0,0])
    setIsArrowing(false)
  }, [mode])

  return {
    isArrowing,
    arrowText,
    arrowPath,
    setIsArrowing,
    onArrowMouseMove,
    onArrowClick,
    onCatArrowPath,
  }
}