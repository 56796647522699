import axios from './axios'
/* 
    type	int	to_review: 等待我审核的task列表 
    submitted: 我提交的任务列表
    page	int	
    size	int	
*/
export const getTaskList =(data)=>{
    return axios({
        url: `/reviewtask`,
        method: 'get',
        params: data
    })
}

/* 
    comment
*/
export const approveTask =(taskId, comment)=>{
    return axios({
        url: `/reviewtask/${taskId}/approve`,
        method: 'put',
        data: {
            comment
        }
    })
}

export const declineTask =(taskId, comment)=>{
    return axios({
        url: `/reviewtask/${taskId}/decline`,
        method: 'put',
        data: {
            comment
        }
    })
}

export const deleteTask =(taskId)=>{
    return axios({
        url: `/reviewtask/${taskId}`,
        method: 'delete'
    })
}
