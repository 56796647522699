import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { COMMON_LANG } from '../store/common';
import i18n from 'i18next'
import { useEffect } from 'react';

export default function useLang() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const common = useSelector((state) => state.common)

  const onChangeLang = (lang) => {
    dispatch({
      type: COMMON_LANG,
      data: lang,
    })
    i18n.changeLanguage(lang)
  }

  useEffect(() => {
    onChangeLang('en')
  }, [])

  return {
    lang: common.lang,
    isZh: common.lang === 'zh',
    name: t("app."+common.lang),
    nameZh: t("app.zh"),
    nameEn: t("app.en"),
    onChangeLang,
  }
}