import axios from '@/axios/axios';

export const downBlob = (name, blob) => {
    const dom = document.createElement('a');
    dom.style.display = 'none';

    dom.download = name;
    dom.href = URL.createObjectURL(blob);

    document.body.appendChild(dom);
    dom.click();

    window.URL.revokeObjectURL(blob)
    document.body.removeChild(dom);
};

export const uploadFile = (file, action, params = {}) => {

    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = e => {
            const base64URI = e.target.result;

            const bytes = window.atob(base64URI.split(',')[1]);
            const buffer = new ArrayBuffer(bytes.length);
            const unitArray = new Uint8Array(buffer);
            for (let i = 0; i < bytes.length; i++) {
                unitArray[i] = bytes.charCodeAt(i)
            }

            const blob = new Blob([buffer], { type: `${file.type}` });
            const fd = new FormData();
            Object.keys(params).forEach(k => {
                fd.append(k, params[k])
            });
            fd.append('file', blob, file.name);
            axios({
                method: 'post',
                url: `${action}`,
                transformRequest: [d => d],
                data: fd
            })
                .then(res => {
                    resolve(res)
                })
                .catch((err) => {
                    reject(err)
                })
        };
    })
};

export const getEditroTms = url => {
    if (!url) return '';
  
    const urls = url.split('_v');
    const urlLast = urls[1];
    const urlLastArr = urlLast.split('.');

    const initUrl = `${urls[0]}_v0.${urlLastArr[1]}`;

    return initUrl;
};

export const zipImg = (file, maxKB = 512, quality = 0.9) => {
    return new Promise((resolve, reject) => {

        if (file.size > 1024 * maxKB) {
            console.log(`=======压缩前大小：${file.size}======`)
            const img = new Image();
            const reader = new FileReader()// 读取文件资源

            reader.readAsDataURL(file);
            reader.onerror = err => {
                reject(err)
            };
            reader.onload = (e) => {
                img.src = e.target.result;
                img.onerror = err => {
                    reject(err)
                };
                img.onload = d => {
                    const width = img.width;
                    const height = img.height;
                    const maxWidth = 1000;
                    const maxHeight = window.innerHeight;

                    let tW;
                    let tH;

                    tW = maxWidth;
                    tH = height * tW / width;


                    // 创建画布
                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');

                    // 设置宽高度为等同于要压缩图片的尺寸
                    canvas.width = tW;
                    canvas.height = tH;
                    context.clearRect(0, 0, tH, tW);
                    context.drawImage(img, 0, 0, tW, tH);

                    canvas.toBlob((blob) => {
                        let file = new File([blob], Date.now() + '.jpg', { type: blob.type });
                        console.log(`======压缩后大小：${file.size}=======`);
                        resolve(file);
                    }, 'image/jpeg', quality);
                }
            }
        } else {
            resolve(file);
        }
    })
};