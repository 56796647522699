import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Button, Modal, Form, Input, Spin } from 'antd';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { usePlant } from '@/hooks/plant';
import { delPlant, editPlant, addPlant } from '@/axios/axios.dict';

const layout = {
  labelCol: {
    span: 7,
  },
  wrapperCol: {
    span: 17,
  },
};

const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};

export default function() {
  const { t } = useTranslation();
  const { plants, onGetAllPlants, loadingPlant } = usePlant();
  const [type, setType] = useState('');
  const [saving, setSaving] = useState(false);
  const [form] = Form.useForm();

  const columns = [
    { title: 'plant', dataIndex: 'plant' },
    { title: 'name', dataIndex: 'name' },
    { title: 'code', dataIndex: 'code' },
    { title: 'abb', dataIndex: 'abb' },
    { title: t('common.action'), key: 'action', render(record) {
      return <section className='f-s-c'>
        <Button type='link' onClick={()=>onModal(record, true)}>{t('common.edit')}</Button>
        <Button type='link' danger onClick={()=>onDelete(record)}>{t('common.delete')}</Button>
      </section>
    }}
  ];

  const onDelete =(record)=>{
    Modal.confirm({
      title: t('common.notice'),
      content: t('common.notice-del'),
      onOk: ()=>{
        return new Promise(async (resolve, reject)=>{
          try {
            await delPlant(record.code);
            onGetAllPlants();
            resolve();
          } catch (error) {
            reject()
          }
        })
      }
    })
  };

  const onModal =(record, isEdit)=>{
    window.requestIdleCallback(()=>{
      form.setFieldsValue(isEdit ? record : { 
        plant: '',
        name: '',
        code: '',
        abb: ''
      });
    });
    setType(isEdit ? 'edit' : 'add');
  };

  const onClose =()=>{
    setType('')
  };

  const onSave =async (arg)=>{
    setSaving(true);
    try {
      const code = arg.code;
      const isEdit = type === 'edit';
      if (isEdit) delete arg.code;
      isEdit ? await editPlant(code, arg) : await addPlant(arg);
      setSaving(false);
      onClose();
      onGetAllPlants();
    } catch (error) {
      setSaving(false) 
    };
    
  };
  
  return (
    <article>
      <header className='f-s-c p-b-10'>
        <Button type='primary' ghost icon={<SearchOutlined />} className='m-r-20' onClick={onGetAllPlants}>{t("common.search")}</Button>
        <Button type='primary'  icon={<PlusOutlined />} onClick={onModal}>{t("common.add")}</Button>
      </header>
      <Table
        rowKey='code'
        loading={loadingPlant}
        dataSource={plants}
        columns={columns}
      ></Table>
      <Modal
        title={t("setting.plant.title")}
        width={590}
        open={!!type}
        footer={null}
        onCancel={onClose}
      >
        <Spin spinning={saving}>
          <Form {...layout} name="nest-messages" className='p-t-20' form={form} onFinish={onSave} validateMessages={validateMessages}>
            <Form.Item
              name={['code']}
              label="Plant code"
              rules={[
                { required: true },
              ]}
            >
              <Input disabled={type==='edit'} />
            </Form.Item>

            <Form.Item
              name={['plant']}
              label="Plant"
              rules={[
                { required: true },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name={['name']}
              label="Plant name"
              rules={[
                { required: true },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name={['abb']}
              label="Abb."
              rules={[
                { required: true },
              ]}
            >
              <Input />
            </Form.Item>
            
            <Form.Item
              wrapperCol={{
                ...layout.wrapperCol,
                offset: 7,
              }}
            >
              <Button type="primary" htmlType="submit" loading={saving}>
                {t("common.save")}
              </Button>
              <Button danger ghost htmlType="reset" className='m-lr-15'>
                {t("common.reset")}
              </Button>
              <Button type='primary' danger onClick={onClose}>
                {t("common.cancel")}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </article>
  )
}