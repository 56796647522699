export const getCookie = name => {
    let arr = undefined;
    const reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");

    if (arr = document.cookie.match(reg))

        return unescape(arr[2]);
    else
        return null;
}

export const delCookie = name => {
    var exp = new Date();
    exp.setTime(exp.getTime() - 1);
    var cval = getCookie(name);
    if (cval != null) {
        const domain = '.' + window.location.hostname.split('.').slice(-2).join('.')
        document.cookie = name + "=" + cval + ";domain=" + domain + ";expires=" + exp.toGMTString();
    }
}