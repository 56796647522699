import { getUuidByNum } from "@/utils/util"
import { message } from 'antd'
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

const formatTags = {
  id: '',
  key: '',
  pid: '',
  name: '',
  code: '', // 编号
  level: '', // 目录
  scene_id: '', // 选择的场景目录id
  cate_id: '', // 类别
  x: 0,
  y: 0,
  width: 0,
  height: 0,

  fill: '#fff', // 背景色

  text_x: 2,
  text_y: 16,
  text_size: 14,
  text_fill: '#f00',
  text_rotate: 0,
  
  children: [],
}

const equipTags = {
  id: '',
  key: '',
  pid: '', // 父级Line
  level: 7, // 目录-7
  scene_id: '', // 场景目录ID
  cate_id: '', // 类别

  x: 0,
  y: 0,

  asset_no: '', //资产编号
  // ws_ids: '', // 工位ID，多个逗号隔开
  // ws_desc: "", // 工位描述
  factor_id: '', // 元素ID
}

const arrowTags = {
  id: '',
  key: '',
  datatype: 'arrow',
  pid: '',
  x: 0,
  y: 0,
  ex: 0,
  ey: 0,
  fill: '#000',
}

const layoutWSTags = {
  id: '',
  key: '',
  pid: '',
  level: 6,
  ws: '',
  desc: '',
  children: [],
}

const layoutEquipTags = {
  id: '',
  key: '',
  pid: '',
  equip: '',
  level: 7,
  children: []
}

export function useTags(parent) {
  const dicts = useSelector(state => state.dicts)
  const { t } = useTranslation();

  const onGetNewCell = (startLoc, endLoc, parentId)=>{
    const [sx, sy] = startLoc
    const [ex, ey] = endLoc

    const minX = Math.min(sx, ex)
    const minY = Math.min(sy, ey)

    const pid = parentId || parent.id

    const newCell = {
      ...formatTags,
      id: pid ? pid +'-' + getUuidByNum(4) : getUuidByNum(4),
      pid: pid || '',
      x: minX,
      y: minY,
      width: Math.abs(ex - sx),
      height: Math.abs(ey - sy),
      children: [],
      fill: 'rgba(0,0,0,0.15)',
    }
    newCell.level = (Number(parent.level)||0) + 1
    const obj = dicts.scenes.find(o=>String(o.level)===String(newCell.level))
    if (!obj) {
      message.error(t('scene.editorscale.init-err-qs'))
      throw new Error(t('scene.editor.init-err-qs'))
    }
    newCell.key = newCell.id === 'root' ? 'root' : getUuidByNum(10)
    // newCell.type = obj.name
    return newCell
  }

  const onGetNewEquip = (loc, parentId)=>{
    const [x, y] = loc
    const pid = parentId || parent.id
    const newCell = {
      ...equipTags,
      id: pid +'-' + getUuidByNum(4),
      key: getUuidByNum(10),
      pid: pid,
      x,
      y,
    }
    // newCell.level = (Number(parent.level)||0) + 1
    const obj = dicts.scenes.find(o=>String(o.level)===String(newCell.level))
    if (!obj) {
      message.error(t('scene.editorscale.init-err-qs'))
      throw new Error(t('scene.editor.init-err-qs'))
    }

    return newCell
  }

  const onGetNewLayoutWS = (pid, ws)=>{
    return {
      ...layoutWSTags,
      id: `${pid}-${getUuidByNum(4)}`,
      key: getUuidByNum(10),
      pid,
      ws,
    }
  }

  const onGetNewLayoutEquip = (pid, equip)=>{
    return {
      ...layoutEquipTags,
      id: `${pid}-${getUuidByNum(4)}`,
      key: getUuidByNum(10),
      pid,
      equip,
    }
  }

  const onGetNewArrow = (pid, startLoc, endLoc) => {
    const [x, y] = startLoc
    const [ex, ey] = endLoc
    return {
      ...arrowTags,
      id: `${pid}-${getUuidByNum(4)}`,
      pid,
      key: getUuidByNum(10), 
      x,
      y,
      ex,
      ey,
    }
  }
  
  return {
    tags: JSON.parse(JSON.stringify(formatTags)),
    wsLayoutTags: JSON.parse(JSON.stringify(layoutWSTags)),
    equipLayoutTags: JSON.parse(JSON.stringify(layoutEquipTags)),

    equipTags: JSON.parse(JSON.stringify(equipTags)),
    arrowTags: JSON.parse(JSON.stringify(arrowTags)),

    onGetNewCell,
    onGetNewEquip,
    onGetNewLayoutWS,
    onGetNewLayoutEquip,
    onGetNewArrow,
  }
}
